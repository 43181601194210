import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';
import { Attachment } from './attachment.model';
import { AttachmentsService } from './attachments.service';

@Component({
  selector: 'app-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.scss']
})
export class AttachmentsComponent implements OnInit {
  @Input() customerId!: number;
  selectedFile?: File;
  @ViewChild('fileUpload') fileUpload!: FileUpload;

  loading = true;

  attachmentForm = new FormGroup({
    name: new FormControl('', Validators.required)
  })

  constructor(public attachmentsService: AttachmentsService, private messageService: MessageService, private confirmationService: ConfirmationService) { }

  async ngOnInit(): Promise<void> {
    await this.fetchAttachments();
  }

  async delete(id: number, event: Event) {
    this.confirmationService.confirm({
      target: event.target ? event.target : undefined,
      message: 'Bist du sicher, dass du diese Datei löschen möchtest?',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'JA',
      rejectLabel: 'NEIN',
      accept: async () => {
        try {
          await this.attachmentsService.deleteAttachment(id);
          await this.fetchAttachments();
          this.messageService.add({ severity: 'success', summary: 'Erfolg', detail: 'Datei wurde gelöscht.' });
        } catch (e) {
          console.error(e);
          this.messageService.add({ severity: 'error', summary: 'Fehler', detail: 'Datei konnte nicht gelöscht werden.' });
        }
      },
    });
  }

  async download(attachment: Attachment) {
    try {
      await this.attachmentsService.downloadAttachment(attachment);
      this.messageService.add({ severity: 'success', summary: 'Erfolg', detail: 'Datei wurde heruntergeladen.' });
    } catch (e) {
      console.error(e);
      this.messageService.add({ severity: 'error', summary: 'Fehler', detail: 'Datei konnte nicht heruntergeladen werden.' });
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onFileSelect(event: any) {
    this.selectedFile = event.files[0];
  }

  async save() {
    const { name } = this.attachmentForm.value;

    if (!name || !this.selectedFile) {
      this.attachmentForm.get('name')?.markAsDirty();
      return;
    }

    try {
      await this.attachmentsService.createAttachment(this.customerId, name, this.selectedFile);
      await this.fetchAttachments();
      this.messageService.add({ severity: 'success', summary: 'Erfolg', detail: 'Datei wurde hochgeladen.' });

      this.attachmentForm.reset();
      this.selectedFile = undefined;
      this.fileUpload.clear();
    } catch (e) {
      console.error(e);
      this.messageService.add({ severity: 'error', summary: 'Fehler', detail: 'Datei konnte nicht hochgeladen werden.' });
    }
  }

  async fetchAttachments() {
    this.loading = true;
    await this.attachmentsService.fetchAttachments(this.customerId);
    this.loading = false;
  }
}
