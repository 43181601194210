import {effect, inject, Injectable, signal, WritableSignal} from '@angular/core';
import {RoleEnum} from "../../login/role.enum";
import {StatsService} from "../../stats/stats.service";
import {SettingsService} from "../../administration/settings.service";

export interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  badge: number;
  roles: RoleEnum[];
}

const ALL_SIDEBAR_ITEMS = [
  { path: 'home', title: 'Home',  icon: 'fa fa-home', roles: [RoleEnum.READ, RoleEnum.Basic, RoleEnum.Member, RoleEnum.Admin], badge: 0},
  { path: 'pickup-admin', title: 'Abholungen', icon: 'fa fa-cash-register', roles: [RoleEnum.Admin, RoleEnum.Member], badge: 0},
  { path: 'customers', title: 'Nutzer',  icon:'fa fa-user', roles: [RoleEnum.Member, RoleEnum.Admin], badge: 0 },
  { path: 'add-customer', title: 'Nutzer anlegen',  icon:'fa fa-plus', roles: [RoleEnum.Member, RoleEnum.Admin], badge: 0 },
  { path: 'messages', title: 'Nachrichten', icon: 'fa fa-message', roles: [RoleEnum.READ, RoleEnum.Basic, RoleEnum.Member, RoleEnum.Admin], badge: 0 },
  { path: 'schedule', title: 'Pläne', icon: 'fa fa-file-pdf', roles: [RoleEnum.READ, RoleEnum.Basic, RoleEnum.Member, RoleEnum.Admin], badge: 0 },
  { path: 'stats', title: 'Statistik',  icon:'fa fa-chart-pie', roles: [RoleEnum.Admin], badge: 0 },
  { path: 'absences', title: 'Fehlzeiten',  icon:'fa fa-person-circle-question', roles: [RoleEnum.Member, RoleEnum.Admin], badge: 0 },
  { path: 'certificates', title: 'Einkommensnachweise', icon: 'fa fa-certificate', roles: [RoleEnum.Member, RoleEnum.Admin], badge: 0 },
  { path: 'automation', title: 'Automatisierung', icon: 'fa fa-rotate-left', roles: [RoleEnum.Admin], badge: 0 },
  { path: 'expirations', title: 'Befristungen', icon: 'fa fa-clock', roles: [RoleEnum.Member, RoleEnum.Admin], badge: 0 },
  { path: 'consultation', title: 'Beratungstermine', icon: 'fa fa-calendar', roles: [RoleEnum.Member, RoleEnum.Admin], badge: 0 },
  { path: 'codes', title: 'Nutzernummern', icon: 'fa fa-list-ol', roles: [RoleEnum.Admin], badge: 0 },
  { path: 'administration', title: 'Administration',  icon:'fa fa-toolbox', roles: [RoleEnum.Admin], badge: 0 },
  { path: 'profile', title: 'Einstellungen', icon: 'fa fa-gears', roles: [RoleEnum.READ, RoleEnum.Basic, RoleEnum.Member, RoleEnum.Admin], badge: 0 }
]

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  sidebarItems: WritableSignal<RouteInfo[]> = signal(ALL_SIDEBAR_ITEMS);
  statsService = inject(StatsService);
  settingsService = inject(SettingsService);

  constructor() {
    effect(() => {
      this.sidebarItems.set(ALL_SIDEBAR_ITEMS.filter(i => this.settingsService.settings()?.expirationActivated || i.path !== 'expirations'));
    }, {allowSignalWrites: true});
  }

  updateSidebarStats() {
    this.statsService.getBadges().then(stats => {
      this.sidebarItems.update(items => {
        items.forEach(route => {
          switch(route.path) {
            case 'certificates':
              route.badge = stats.expiringCertificates;
              break;
            case 'expirations':
              route.badge = stats.expiringExpiration;
              break;
            case 'messages':
              route.badge = stats.unseenMessages;
              break;
          }
        });
        return items;
      });
    })
  }
}
