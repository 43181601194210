import { Injectable } from '@angular/core';
import {LoadingState} from "../../shared/loading-state.enum";
import {HttpClient} from "@angular/common/http";
import {firstValueFrom} from "rxjs";
import {ResponseModel, SingleResponseModel} from "../../shared/response.model";
import {environment} from "../../../environments/environment";
import {FamilyStatus} from "./family-status.model";
import {MessageService} from "primeng/api";

@Injectable({
  providedIn: 'root'
})
export class FamilyStatusService {
  loadingState = LoadingState.Loading;
  familyStatuses: FamilyStatus[] = [];
  constructor(
    private http: HttpClient,
    private messageService: MessageService
  ) {
    void this.fetchStatuses();
  }

  async fetchStatuses(): Promise<void> {
    try {
      const response = await firstValueFrom(this.http.get<ResponseModel<FamilyStatus>>(environment.apiUrl + "/family-statuses"));
      this.familyStatuses = response.data;
      this.loadingState = LoadingState.Data;
    } catch (e) {
      console.error(e);
      this.loadingState = LoadingState.Error;
      this.familyStatuses = [];
    }
  }


  async deleteFamilyStatus(id: number): Promise<FamilyStatus | void> {
    try {
      const response = await firstValueFrom(this.http.delete<SingleResponseModel<FamilyStatus>>(environment.apiUrl + `/family-statuses/${id}`));
      this.messageService.add({
        severity: 'success',
        summary: 'Familienstand gelöscht',
        detail: `${response?.data.title} wurde gelöscht.`
      });
      return response?.data;
    } catch (e) {
      console.error(e);
      this.messageService.add({
        severity: 'error',
        summary: 'Fehler',
        detail: `Familienstand konnte nicht gelöscht werden.`
      });
    } finally {
      await this.fetchStatuses();
    }
  }

  async updateFamilyStatus(type: FamilyStatus): Promise<FamilyStatus | void> {
    try {
      const response = await firstValueFrom(this.http.put<SingleResponseModel<FamilyStatus>>(environment.apiUrl + `/family-statuses/${type.id}`, {data: type}));
      this.messageService.add({
        severity: 'success',
        summary: 'Familienstand aktualisiert',
        detail: `${response?.data.title} wurde aktualisiert.`
      });
      return response?.data;
    } catch (e) {
      console.error(e);
      this.messageService.add({
        severity: 'error',
        summary: 'Fehler',
        detail: `Familienstand konnte nicht geändert werden.`
      });
    } finally {
      await this.fetchStatuses();
    }

  }

  async createFamilyStatus(title: string): Promise<FamilyStatus | void> {
    try {
      const response = await firstValueFrom(this.http.post<SingleResponseModel<FamilyStatus>>(environment.apiUrl + `/family-statuses`, {data: {title}}));
      this.messageService.add({
        severity: 'success',
        summary: 'Familienstand erstellt',
        detail: `${response?.data.title} wurde erstellt.`
      });
      return response?.data;
    } catch (e) {
      console.error(e);
      this.messageService.add({
        severity: 'error',
        summary: 'Fehler',
        detail: `Familienstand konnte nicht erstellt werden.`
      });
    } finally {
      await this.fetchStatuses();
    }
  }
}
