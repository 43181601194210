<p-card>
  <div class="d-flex mb-3">
    <span class="font-bold me-5">Status: </span>
    @for (status of absenceStatuses; track status) {
    <div class="field-checkbox me-5">
      <p-checkbox [value]="status.status" [(ngModel)]="selectedStatuses" (onChange)="fetchAbsences()"
        [inputId]="status.status"></p-checkbox>
      <label [for]="status.status">{{ status.title }}</label>
    </div>
    }
  </div>
  <div class="d-flex align-items-center mb-3">
    <span class="font-bold me-5">Datum: </span>
    <p-selectButton [options]="dateOptions" [(ngModel)]="selectedDate" optionLabel="label" optionValue="value"
      (onChange)="fetchAbsences()"></p-selectButton>
  </div>

  <p-table #dt [value]="absenceService.allAbsences" sortField="customer.id" sortMode="single" dataKey="customer.id"
    rowGroupMode="subheader" groupRowsBy="customer.id" [loading]="absenceService.loadingState === LoadingState.Loading"
    [showCurrentPageReport]="false" [rows]="20" [paginator]="true">
    <ng-template pTemplate="header">
      <tr>
        <th style="width:20%">Datum</th>
        <th style="width:20%">Status</th>
        <th style="width:20%">Bemerkung</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="groupheader" let-absence let-rowIndex="rowIndex" let-expanded="expanded">
      <tr>
        <td colspan="3">
          <div class="d-flex align-items-center">
            <button type="button" pButton pRipple [pRowToggler]="absence"
              class="p-button-text p-button-rounded p-button-plain mr-2"
              [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
            <span class="font-bold">{{ absence.customer.first_name }} {{ absence.customer.last_name }}</span>
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="groupfooter" let-absence>
      <tr class="p-rowgroup-footer">
        <td colspan="2" class="text-right font-bold">Fehlzeiten:</td>
        <td class="font-bold">{{ calculateAbsencesTotal(absence.customer.id) }}</td>
      </tr>
    </ng-template>
    <ng-template pTemplate="rowexpansion" let-absence>
      <tr>
        <td>
          {{ absence.date | date : 'dd.MM.yyyy' }}
        </td>
        <td>
          <span class="status-badge status-{{absence.absence_status}}">{{ getStatusTitleString(absence.absence_status)
            }}</span>
        </td>
        <td>
          {{ absence.comment }}
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="3">Keine Fehlzeiten gefunden</td>
      </tr>
    </ng-template>
  </p-table>

</p-card>

<p-card header="Fehlzeit Hinzufügen" styleClass="mt-3">
  <form [formGroup]="addAbsenceForm" (ngSubmit)="createAbsence()">
    <div class="grid">
      <div class="field col-12">
        <span class="p-float-label">
          <p-autoComplete styleClass="w-100 d-flex" formControlName="customers" inputId="customer"
            inputStyleClass="w-100" [multiple]="true" (completeMethod)="searchCustomers($event)"
            [suggestions]="customerSuggestions" appendTo="body" [showClear]="true" emptyMessage="Keine Nutzer gefunden"
            [forceSelection]="true" field="last_name">
            <ng-template pTemplate="selectedItem" let-customer>
              <div>
                {{ customer.first_name }} {{ customer.last_name }}
                @if (customer.code || customer.code_rotation_1 || customer.code_rotation_2) {
                <span class="text-sm">|
                  @if (customer.code?.value) {
                  <span>{{ customer.code.location.abbreviation }} {{ customer.code.value }}</span>
                  }
                  @if (customer.code_rotation_1?.value) {
                  <span>{{ customer.code_rotation_1.location.abbreviation }} {{ customer.code_rotation_1.value }} -
                    1</span>
                  }
                  @if (customer.code_rotation_2?.value) {
                  <span>{{ customer.code_rotation_2.location.abbreviation }} {{ customer.code_rotation_2.value }} -
                    2</span>
                  }
                </span>
                }
              </div>
            </ng-template>
            <ng-template let-customer pTemplate="item">
              <div>
                {{ customer.first_name }} {{ customer.last_name }}
                @if (customer.code || customer.code_rotation_1 || customer.code_rotation_2) {
                <span class="text-muted small">|
                  @if (customer.code?.value) {
                  <span>{{ customer.code.location.abbreviation }} {{ customer.code.value }}</span>
                  }
                  @if (customer.code_rotation_1?.value) {
                  <span>{{ customer.code_rotation_1.location.abbreviation }} {{ customer.code_rotation_1.value }} -
                    1</span>
                  }
                  @if (customer.code_rotation_2?.value) {
                  <span>{{ customer.code_rotation_2.location.abbreviation }} {{ customer.code_rotation_2.value }} -
                    2</span>
                  }
                </span>
                }
              </div>
            </ng-template>
          </p-autoComplete>
          <label [for]="'customer'"></label>
        </span>
      </div>
      <div class="field col-12 md:col-6">
        <span class="p-float-label">
          <p-calendar inputId="date" styleClass="w-100" dateFormat="dd.mm.yy" formControlName="date"></p-calendar>
          <label [for]="'date'">Datum</label>
        </span>
      </div>

      <div class="field col-12 md:col-6">
        <span class="p-float-label">
          <p-dropdown styleClass="w-100" [options]="absenceStatuses" formControlName="absence_status"
            optionLabel="title" inputId="absence_status" optionValue="status" [autoDisplayFirst]="false"
            [showClear]="true"></p-dropdown>
          <label for="absence_status">Status</label>
        </span>
      </div>

      <div class="field col-12">
        <span class="p-float-label">
          <textarea pInputTextarea id="comment" formControlName="comment" class="w-100"></textarea>
          <label for="comment">Bemerkung</label>
        </span>
      </div>
    </div>
    <div class="w-100 d-flex justify-content-end mb-3">
      <button pRipple type="submit" pButton label="FEHLZEIT SPEICHERN" [disabled]="!addAbsenceForm.valid || addLoading"
        icon="pi pi-save" class="p-button"></button>
    </div>

  </form>
</p-card>

<p-card header="Verstöße"
  pTooltip="Nutzer, die in den letzten 8 Wochen: 2x unentschuldigt fehlten oder nach einer Abmahnung erneut unentschuldigt fehlten oder 6x entschuldigt in 3 Monaten"
  styleClass="mt-3">
  <div class="flex justify-content-end mb-3">
    <button [disabled]="xlsxLoading()" class="p-link text-primary mr-3" (click)="exportXLSX()">
      @if (xlsxLoading()) {
      <i class="pi pi-spinner"></i>
      } @else {
      <i class="pi pi-download"></i>
      }
      Excel-Export
    </button>
  </div>
  <p-table [value]="violations" [paginator]="true" [rows]="10" [showCurrentPageReport]="true"
    [loading]="loadingViolations" currentPageReportTemplate="Zeige {first} bis {last} von {totalRecords} Einträgen">
    <ng-template pTemplate="header">
      <tr>
        <th>Nutzer</th>
        <th>Fehlzeiten</th>
        <th></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-violation>
      <tr>
        <td>{{ violation.customer.first_name }} {{ violation.customer.last_name }} <span class="text-sm text-black-alpha-60 m-2"> | {{violation.customer | code }}</span> </td>
        <td>@for (absence of violation.absences; track absence.id) {
          <span class="status-badge status-{{absence.status}} me-1">{{ absence.date | date : 'dd.MM.yyyy' }}</span>
          }
        </td>
        <td>
          <p-button (onClick)="openCustomer(violation.customer.id)" icon="pi pi-external-link"
            styleClass="p-button-rounded p-button-outlined"></p-button>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="5">Keine Verstöße gefunden</td>
      </tr>
    </ng-template>
  </p-table>
</p-card>
