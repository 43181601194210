<p-card>
  <p-tabView>
    <p-tabPanel header="Mitarbeiter">
      <app-users></app-users>
    </p-tabPanel>
    <p-tabPanel header="Gebühren & Alter">
      <app-global-settings></app-global-settings>
    </p-tabPanel>
    <p-tabPanel header="Kontaktarten">
      <app-crud-table [data]="commentService.contactTypes"
                      (delete)="commentService.deleteContactType($event.id)"
                      (update)="commentService.updateContactType($event)"
                      (create)="commentService.createContactType($event)"
      ></app-crud-table>
    </p-tabPanel>
    <p-tabPanel header="Nationalitäten">
      <app-crud-table [data]="nationalityService.nationalities"
                      (delete)="nationalityService.deleteNationality($event.id)"
                      (update)="nationalityService.updateNationality($event)"
                      (create)="nationalityService.createNationality($event)"></app-crud-table>
    </p-tabPanel>
    <p-tabPanel header="Eigenschaften">
      <app-crud-table [data]="characteristicService.characteristics"
                      (delete)="characteristicService.deleteCharacteristic($event.id)"
                      (update)="characteristicService.updateCharacteristic($event)"
                      (create)="characteristicService.createCharacteristic($event)"></app-crud-table>
    </p-tabPanel>
    <p-tabPanel header="Familienstand">
      <app-crud-table [data]="familyStatusService.familyStatuses"
                      (delete)="familyStatusService.deleteFamilyStatus($event.id)"
                      (update)="familyStatusService.updateFamilyStatus($event)"
                      (create)="familyStatusService.createFamilyStatus($event)"></app-crud-table>
    </p-tabPanel>
    <p-tabPanel header="Standorte">
      <app-location></app-location>
    </p-tabPanel>
    <p-tabPanel header="Gründe Bezugsende">
      <app-crud-table [data]="statusChangeReasonService.reasons"
                      (delete)="statusChangeReasonService.deleteStatusChangeReason($event.id)"
                      (update)="statusChangeReasonService.updateStatusChangeReason($event)"
                      (create)="statusChangeReasonService.createStatusChangeReason($event)"></app-crud-table>
    </p-tabPanel>
    <p-tabPanel header="Einkommensarten">
      <app-crud-table [data]="incomeTypeService.types"
                      (delete)="incomeTypeService.deleteIncomeType($event.id)"
                      (update)="incomeTypeService.updateIncomeType($event)"
                      (create)="incomeTypeService.createIncomeType($event)"></app-crud-table>
    </p-tabPanel>
  </p-tabView>
</p-card>
