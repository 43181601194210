<p-card header="Nachrichten">
  <form (ngSubmit)="createMessages()" [formGroup]="alertMessageForm">
    <div class="flex align-items-start">
      <span class="field flex-grow-1">
        <span class="p-fluid p-float-label">
          <p-autoComplete inputId="recipients" formControlName="recipients" [suggestions]="users"
            (completeMethod)="filterUsers($event)"
            field="first_name" [multiple]="true">
            <ng-template let-user pTemplate="item">
              <div>{{ user.first_name }} {{ user.last_name }}</div>
            </ng-template>
            <ng-template let-user pTemplate="selectedItem">
              <div>{{ user.first_name }} {{ user.last_name }}</div>
            </ng-template>
          </p-autoComplete>
          <label class="negative-margin" [for]="'recipients'">Empfänger</label>
        </span>
      </span>
      <button type="button" (click)="addAll()" class="p-button ml-2">AN ALLE</button>
    </div>

    <span class="field mt-3 w-100 p-float-label">
      <textarea formControlName="message" class="w-100" id="message" pInputTextarea></textarea>
      <label for="message">Nachricht</label>
    </span>

    <div class="w-100 d-flex justify-content-end mt-4">
      <button type="submit" pButton label="SPEICHERN" icon="pi pi-save"
      [disabled]="!alertMessageForm.valid || !alertMessageForm.dirty"></button>
    </div>
  </form>

  <h2 class="mt-3 mb-3">Empfangene Nachrichten</h2>

  <p-table [value]="receivedMessages"
           [lazy]="true"
           (onLazyLoad)="loadReceivedMessages($event)"
           [paginator]="true"
           [rows]="5"
           [rowsPerPageOptions]="[5,10,50]"
           [showCurrentPageReport]="true"
           [totalRecords]="totalReceived"
           [alwaysShowPaginator]="false"
           [loading]="loadingReceived"
           dataKey="id"
           currentPageReportTemplate="Zeige {first} bis {last} von {totalRecords} Nachrichten"
  >
    <ng-template pTemplate="header">
      <tr>
        <th>Absender</th>
        <th>Nachricht</th>
        <th>Datum</th>
        <th>Gelesen</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-message>
      <tr>
        <td [class.font-bold]="!message.seen">
          <span class="status-badge mr-2">{{message.author.first_name}} {{message.author.last_name}}</span>
        </td>
        <td [class.font-bold]="!message.seen">{{message.message}}</td>
        <td [class.font-bold]="!message.seen">
          {{message.createdAt | date : 'dd.MM.yyyy HH:mm'}}
        </td>
        <td>
          @if(!message.seen) {
            <button pButton class="p-button-rounded p-button-outlined p-button-success" icon="pi pi-check"
                    (click)="markAsSeen(message.id)"></button>
          }
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="5">Keine Nachrichten gefunden</td>
      </tr>
    </ng-template>
  </p-table>

  <h2 class="mt-5 mb-3">Gesendete Nachrichten</h2>

  <p-table [value]="sentMessages"
           [lazy]="true"
           (onLazyLoad)="loadSentMessages($event)"
           [paginator]="true"
           [rows]="5"
           [showCurrentPageReport]="true"
           [totalRecords]="totalSent"
           [alwaysShowPaginator]="false"
           [loading]="loadingSent"
           dataKey="id"
           currentPageReportTemplate="Zeige {first} bis {last} von {totalRecords} Nachrichten"
  >
    <ng-template pTemplate="header">
      <tr>
        <th>Empfänger</th>
        <th>Nachricht</th>
        <th>Gesehen</th>
        <th>Datum</th>
        <th></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-message>
      <tr>
        <td>
          <span class="status-badge mr-2">{{message.recipient.first_name}} {{message.recipient.last_name}}</span>
        </td>
        <td>{{message.message}}</td>
        <td>
          @if(message.seen) {
            <i class="pi pi-check"></i>
          } @else {
            <i class="pi pi-times"></i>
          }
        </td>
        <td>{{message.createdAt | date : 'dd.MM.yyyy HH:mm'}}</td>
        <td>
          @if(!message.seen) {
            <button pButton class="p-button-rounded p-button-outlined p-button-danger" icon="pi pi-trash"
                    (click)="deleteMessage(message.id, $event)"></button>
          }
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="5">Keine Nachrichten gefunden</td>
      </tr>
    </ng-template>
  </p-table>
</p-card>

<p-confirmPopup></p-confirmPopup>
