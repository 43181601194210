<h1 class="mb-6">Gebühren</h1>

@if (settingsService.loading === LoadingState.Loading) {
  <p-progressBar mode="indeterminate" [style]="{'height': '6px'}"></p-progressBar>
}

@if (settingsService.loading === LoadingState.Data) {
  <form (ngSubmit)="save()" [formGroup]="globalSettingsForm">
    <h5>Alter, ab dem Leistungen zustehen</h5>
    <span class="field">
      <input type="number" class="w-100" pInputText formControlName="adultAge">
    </span>
    <h5 class="mt-4">Preis für eine Kiste</h5>
    <span class="mb-4 p-input-icon-left w-100">
      <i class="pi pi-euro"></i>
      <input id="single-box-price" class="w-100" type="number" pInputText formControlName="singleBoxPrice">
    </span>
    <h5 class="">Preis ab der zweiten Kiste</h5>
    <span class="p-input-icon-left w-100">
      <i class="pi pi-euro"></i>
      <input id="extra-box-price" class="w-100" type="number" pInputText formControlName="extraBoxPrice">
    </span>
    <h5 class="mt-4">Befristung aktiver Bezug (in Jahren)</h5>
    <div class="flex align-items-center">
      <p-inputSwitch styleClass="mr-2" formControlName="expirationActivated"/>
      <span class="field flex-grow-1 mb-0">
        <input type="number" class="w-100" pInputText formControlName="expirationDuration">
      </span>
    </div>
    <h5 class="mt-4">Blockierung nach aktivem Bezug (in Jahren)</h5>
    <div class="flex align-items-center">
      <p-inputSwitch styleClass="mr-2" formControlName="blockedActivated"/>
      <span class="field flex-grow-1 mb-0">
        <input type="number" class="w-100" pInputText formControlName="blockedDuration">
      </span>
    </div>
    <div class="w-100 d-flex justify-content-end mt-4">
      <button type="submit" pButton label="SPEICHERN" icon="pi pi-save"
              [disabled]="!globalSettingsForm.valid || !globalSettingsForm.dirty"></button>
    </div>
  </form>
}

@if (settingsService.loading === LoadingState.Error) {
  <div class="w-100 d-flex justify-content-center text-muted">Ein Fehler ist aufgetreten</div>
}


