import { Pipe, PipeTransform } from '@angular/core';
import {User} from "../../administration/users/user.model";

@Pipe({
  name: 'avatarLabel',
  standalone: true
})
export class AvatarLabelPipe implements PipeTransform {

  transform(value?: User, ...args: unknown[]): string {
    if (value?.first_name && value?.last_name) {
      return `${value.first_name.substring(0,1).toUpperCase()}${value.last_name.substring(0,1).toUpperCase()}`;
    } else if (value?.username) {
      return `${value.username.substring(0,1).toUpperCase()}`;
    } else {
      return '';
    }
  }

}
