import {Customer} from "../customer-overview/customer.model";
import {StatusModel} from "../customer-overview/add-edit-customer/status-form/status.model";

export interface Absence {
  id: number;
  date: string;
  comment: string;
  absence_status: AbsenceStatus | string;
  customer: Customer;
}

export enum AbsenceStatus {
  UNEXCUSED = 'unexcused',
  EXCUSED = 'excused',
  WARNED = 'warned',
}

export const absenceStatuses: StatusModel<AbsenceStatus>[] = [
  { status: AbsenceStatus.UNEXCUSED, title: "Unentschuldigt" },
  { status: AbsenceStatus.EXCUSED, title: "Entschuldigt" },
  { status: AbsenceStatus.WARNED, title: "Mahnschreiben" }
]

export interface AbsenceViolation {
  customer: Customer,
  absences: { date: string, status: string}[]
}

