import { Injectable } from '@angular/core';
import {LoadingState} from "../../shared/loading-state.enum";
import {HttpClient} from "@angular/common/http";
import {ResponseModel, SingleResponseModel} from "../../shared/response.model";
import {environment} from "../../../environments/environment";
import {Characteristic} from "./characteristic.model";
import {MessageService} from "primeng/api";
import {firstValueFrom} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class CharacteristicService {
  loadingState = LoadingState.Loading;
  characteristics: Characteristic[] = [];
  constructor(
    private http: HttpClient,
    private messageService: MessageService
  ) {
    void this.fetchCharacteristics();
  }

  async fetchCharacteristics(): Promise<void> {
    try {
      const response = await firstValueFrom(this.http.get<ResponseModel<Characteristic>>(environment.apiUrl + "/characteristics"));
      this.characteristics = response.data;
      this.loadingState = LoadingState.Data;
    } catch (e) {
      console.error(e);
      this.loadingState = LoadingState.Error;
      this.characteristics = [];
    }
  }

  async deleteCharacteristic(id: number): Promise<Characteristic | void> {
    try {
      const response = await firstValueFrom(this.http.delete<SingleResponseModel<Characteristic>>(environment.apiUrl + `/characteristics/${id}`));
      this.messageService.add({
        severity: 'success',
        summary: 'Eigenschaft gelöscht',
        detail: `${response?.data.title} wurde gelöscht.`
      });
      return response?.data;
    } catch (e) {
      console.error(e);
      this.messageService.add({
        severity: 'error',
        summary: 'Fehler',
        detail: `Eigenschaft konnte nicht gelöscht werden.`
      });
    } finally {
      await this.fetchCharacteristics();
    }
  }

  async updateCharacteristic(type: Characteristic): Promise<Characteristic | void> {
    try {
      const response = await firstValueFrom(this.http.put<SingleResponseModel<Characteristic>>(environment.apiUrl + `/characteristics/${type.id}`, {data: type}));
      this.messageService.add({
        severity: 'success',
        summary: 'Eigenschaft aktualisiert',
        detail: `${response?.data.title} wurde aktualisiert.`
      });
      return response?.data;
    } catch (e) {
      console.error(e);
      this.messageService.add({
        severity: 'error',
        summary: 'Fehler',
        detail: `Eigenschaft konnte nicht geändert werden.`
      });
    } finally {
      await this.fetchCharacteristics();
    }

  }

  async createCharacteristic(title: string): Promise<Characteristic | void> {
    try {
      const response = await firstValueFrom(this.http.post<SingleResponseModel<Characteristic>>(environment.apiUrl + `/characteristics`, {data: {title}}));
      this.messageService.add({
        severity: 'success',
        summary: 'Eigenschaft erstellt',
        detail: `${response?.data.title} wurde erstellt.`
      });
      return response?.data;
    } catch (e) {
      console.error(e);
      this.messageService.add({
        severity: 'error',
        summary: 'Fehler',
        detail: `Eigenschaft konnte nicht erstellt werden.`
      });
    } finally {
      await this.fetchCharacteristics();
    }
  }
}
