import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import {AuthService} from "./auth.service";

@Injectable({
  providedIn: 'root'
})
export class RoleGuard  {
  constructor(private authService: AuthService, private router: Router) {
  }

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    if (this.authService.user() === null) {
      await this.authService.fetchMe();
    }

    if (route.data["roles"].includes(this.authService.user()!.role.id)) {
      return true;
    }
    void this.router.navigate(["home"]);
    return false;
  }

}
