<div class="grid mt-4 d-flex align-items-baseline" [formGroup]="codeForm">
  <div class="field col-12 md:col-3">
    <span class="p-float-label">
      <p-dropdown inputId="location" styleClass="w-100" [options]="locationService.locations" optionValue="id"
        (ngModelChange)="lookupCode()" formControlName="location" optionLabel="city"
      [autoDisplayFirst]="false"></p-dropdown>
      <label [for]="'location'">Ausgabeort</label>
    </span>
  </div>

  <div class="field col-12 md:col-5">
    <span class="p-float-label">
      <input id="code" class="w-100" type="text" formControlName="codeValue" (ngModelChange)="lookupCode()"
        pInputText />
      <label for="code">Nutzernummer</label>
    </span>
  </div>

  <div class="field col-11 md:col-2">
    <span class="p-float-label">
      <p-dropdown inputId="rotation" styleClass="w-100" [options]="[0,1,2]" (ngModelChange)="lookupCode()"
      formControlName="rotation" [autoDisplayFirst]="true"></p-dropdown>
      <label [for]="'rotation'">Turnus</label>
    </span>
  </div>

  <div class="col-2 d-flex justify-content-center align-items-center">
    @if (!checkingCode && free) {
      <i class="pi pi-check mr-2"></i>
    }
    @if (!checkingCode && !free) {
      <i class="pi pi-times mr-2"></i>
    }
    @if (checkingCode) {
      <i class="pi pi-spinner loading-spinner mr-2"></i>
    }
    <p-button styleClass="p-button-outlined p-button-rounded ml-2" icon="pi pi-trash"
    (onClick)="removeCode()"></p-button>
  </div>

  @if (codeDirty) {
    <div class="col-12 d-flex justify-content-end">
      @if (!checkingCode && free) {
        <p>Nutzernummer ist zugewiesen</p>
      }
      @if (!checkingCode && !selectedCode) {
        <p>Nutzernummer existiert nicht</p>
      }
      @if (!checkingCode && selectedCode && !free) {
        <p>
          Nutzernummer ist aktuell vergeben.
          @if (upcoming) {
            <button class="p-button p-button-link p-0" (click)="forceCodeUsage($event)">
              Trotzdem verwenden?
            </button>
          }
        </p>
      }
    </div>
  }

  <div class="field col-12 md:col-6">
    <span class="p-float-label">
      <p-dropdown inputId="weekday" styleClass="w-100" [options]="weekdayService.weekdays" optionValue="id"
      formControlName="weekday" optionLabel="title" [autoDisplayFirst]="false"></p-dropdown>
      <label [for]="'weekday'">Wochentage</label>
    </span>
  </div>

  <div class="field col-12 md:col-6">
    <span class="p-float-label">
      <p-calendar styleClass="w-100" [timeOnly]="true" hourFormat="24" formControlName="time"
      inputId="distribution_time"></p-calendar>
      <label [for]="'distribution_time'">Ausgabezeit</label>
    </span>
  </div>
</div>
