<div class='container mt-3'>
  <p-confirmPopup></p-confirmPopup>
  <form [formGroup]="attachmentForm" (ngSubmit)="save()">
    <h5>Datei Hochladen</h5>
    <div class="p-fluid grid mt-3">

      <div class="field col-12">
        <span class="p-float-label">
          <input pInputText id="name" formControlName="name" class='w-100' />
          <label for="name">Beschreibung der Datei</label>
        </span>

        @if (attachmentForm.get('name')?.errors?.['required'] && attachmentForm.get('name')?.dirty) {
          <span class='p-error'
            >
            Beschreibung ist erforderlich
          </span>
        }
      </div>
    </div>

    <p-fileUpload #fileUpload
                  mode="basic"
                  chooseLabel="Datei Auswählen"
                  name="file"
                  maxFileSize="5000000"
                  invalidFileSizeMessageSummary="{0}: Ungültige Dateigröße"
                  invalidFileSizeMessageDetail="Maximale Dateigröße: {0}. Bitte Datei komprimieren"
                  (onSelect)="onFileSelect($event)"
                  (click)="fileUpload.clear()"
    ></p-fileUpload>

    <div class="w-100 d-flex justify-content-end mb-3">
      <button type="submit" pButton label="DATEI HOCHLADEN" icon="pi pi-save" class="p-button-rounded"></button>
    </div>
  </form>

  @if (attachmentsService.attachments.length > 0) {
    <p-table [value]="attachmentsService.attachments" [paginator]="attachmentsService.attachments.length > 10" [rows]="10"
    [showCurrentPageReport]="true" currentPageReportTemplate="Zeige {first} bis {last} von {totalRecords} Dateien"
    [loading]="loading">

    <ng-template pTemplate="header">
      <tr>
        <th>Beschreibung</th>
        <th>Dateiname</th>
        <th>Erstellt am</th>
        <th></th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-attachment>
      <tr>
        <td>{{ attachment.name }}</td>
        <td>{{ attachment.file?.name }}</td>
        <td>{{ attachment.createdAt | date : 'dd.MM.yyyy \'um\' HH:mm \'Uhr\'' }}</td>
        <td>
          <button pButton type="button" icon="pi pi-download" class="p-button-rounded p-button-success"
          (click)="download(attachment)"></button>
          <button pButton type="button" icon="pi pi-trash" class="p-button-rounded p-button-danger ml-2"
          (click)="delete(attachment.id, $event)"></button>
        </td>
      </tr>
    </ng-template>

  </p-table>
  }
</div>
