import {Component, inject, OnInit, signal, WritableSignal} from '@angular/core';
import {CardModule} from "primeng/card";
import {TableModule} from "primeng/table";
import {AutomationService} from "./automation.service";
import {ButtonModule} from "primeng/button";
import {RippleModule} from "primeng/ripple";
import {DatePipe} from "@angular/common";
import {Automation} from "./automation.model";
import {ProgressSpinnerModule} from "primeng/progressspinner";

@Component({
  selector: 'app-automation',
  standalone: true,
  imports: [
    CardModule,
    TableModule,
    ButtonModule,
    RippleModule,
    DatePipe,
    ProgressSpinnerModule
  ],
  templateUrl: './automation.component.html',
  styleUrl: './automation.component.scss'
})
export class AutomationComponent implements OnInit {
  loading = signal(false);
  automations: WritableSignal<Automation[]> = signal([]);
  automationService = inject(AutomationService);


  async ngOnInit(): Promise<void> {
    void this.fetchAutomations();
  }

  async execute(automation: Automation) {
    await this.automationService.execute(automation.key);
    this.fetchAutomations();
  }

  async fetchAutomations() {
    this.automations.set((await this.automationService.fetchAutomations()).data)
    if (this.automations().filter(a => a.running).length > 0) {
      setTimeout(async () => {
        this.fetchAutomations();
      }, 2000);
    }
  }
}
