import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {firstValueFrom} from "rxjs";
import {ResponseModel, SingleResponseModel} from "../shared/response.model";
import {environment} from "../../environments/environment";
import {Code} from "./code.model";
import * as qs from "qs";

@Injectable({
  providedIn: 'root'
})
export class CodeService {
  codes: Code[] = [];

  constructor(private http: HttpClient) {}

  async fetchCodes(params?: unknown): Promise<ResponseModel<Code>> {
    return firstValueFrom(this.http.get<ResponseModel<Code>>(environment.apiUrl + `/codes?${qs.stringify(params, {encodeValuesOnly: true})}`));
  }

  async getCode(locationId: number, value: number): Promise<Code | undefined> {
    try {
      const query = qs.stringify({
        filters: {
          $and: [
            {
              location: {
                id: {$eq: locationId}
              }
            },
            {
              value: {
                $eq: value
              }
            }
          ]
        },
      }, {encodeValuesOnly: true});
      const response = await firstValueFrom(this.http.get<ResponseModel<Code>>(environment.apiUrl + `/codes?populate=*&${query}`));
      return response.data[0];
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }

  async createCode(weekday: number, location: number, value: number, time: string | undefined, isFreeCode: boolean): Promise<Code | void> {
    const response = await firstValueFrom(this.http.post<SingleResponseModel<Code>>(environment.apiUrl + "/codes", {data: {weekday, value, location, time, isFreeCode}}));
    return response?.data;
  }

  async updateCode(id: number, value: number, time: string, weekday: number, isFreeCode: boolean): Promise<Code | void> {
    const response = await firstValueFrom(this.http.put<SingleResponseModel<Code>>(environment.apiUrl + `/codes/${id}`, {data: {weekday, value, time, isFreeCode}}));
    return response?.data;
  }

  async deleteCode(id: string): Promise<Code> {
    return (await firstValueFrom(this.http.delete<SingleResponseModel<Code>>(environment.apiUrl + `/codes/${id}`))).data;
  }
}
