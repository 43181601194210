import {Component, OnInit} from '@angular/core';
import {AuthService} from "./auth.service";
import {HttpErrorResponse} from "@angular/common/http";
import {RoleEnum} from "./role.enum";
import {Router} from "@angular/router";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loading = false;
  error: string | undefined;

  mail = '';
  password = '';

  constructor(
    private authService: AuthService,
    private router: Router,
  ) { }

  async ngOnInit(): Promise<void> {
    await this.authService.fetchMe();
    if (!this.authService.user()) {
      return;
    }
    if (this.authService.user()!.role.id === RoleEnum.Basic || this.authService.user()!.role.id === RoleEnum.READ) {
      void this.router.navigate(["pickup"]);
    } else {
      void this.router.navigate(["home"]);
    }
  }

  async login(): Promise<void> {
    this.loading = true;
    this.error = undefined;
    try {
      await this.authService.login(this.mail, this.password);
    } catch (err: unknown) {
      console.error(err);
      if (err instanceof HttpErrorResponse && err.statusText !== "Unknown Error") {
        this.error = this.getErrorMessage(err.error.error ? err.error.error : err);
      } else {
        this.error = "Unbekannter Server-Fehler";
      }
    } finally {
      this.loading = false;
    }
  }

  getErrorMessage(error: Error): string {
    switch(error.message) {
      case "Invalid identifier or password":
        return "Falsche Mail oder Passwort";
      default:
        return error.message;
    }
  }
}
