import { Pipe, PipeTransform } from '@angular/core';
import {differenceInYears} from "date-fns";

@Pipe({
  name: 'age',
  standalone: true
})
export class AgePipe implements PipeTransform {

  transform(value: Date): unknown {
    return differenceInYears(new Date(), value);
  }

}
