<div class="container mt-3">
  <form [formGroup]="statusForm">
    <div class="grid">
      <div class="field col-12">
        <span class="p-float-label">
          <p-dropdown inputId="status" styleClass="w-100" [options]="statuses" optionValue="status"
          formControlName="status" optionLabel="title" [autoDisplayFirst]="false"></p-dropdown>
          <label [for]="'status'">Status</label>
        </span>
        <span class="text-sm text-black-alpha-50 mt-1">Achtung: Der Status sollte hier nur in Ausnahmefällen gesetzt werden. Besser ist die geplante "Statusänderung" mit Datum weiter unten.</span>

        @if (statusForm.get('status')?.errors?.['required'] && statusForm.get('status')?.dirty) {
          <span class='p-error'>
            Status ist erforderlich.
          </span>
        }
      </div>
    </div>
    @if (statusForm.get('status')?.value === Status.ACTIVE) {
      <div class="mt-2">
        <h5>Nutzernummer</h5>
        <app-customer-no-inputs [codeForm]="$any(this.statusForm.controls['codeForm'])"></app-customer-no-inputs>
      </div>
    }

    <div class="mt-2">
      <h5>Besonderheit Abholung</h5>
      <div class="grid mt-4">
        <div class="field col-12">
          <span class="p-float-label">
            <textarea #collection_remark maxlength="200" id="collection_remark" class="w-100" type="text"
            formControlName="collection_remark" pInputTextarea></textarea>
            <label for="collection_remark">Bemerkung</label>
          </span>
          <div class="d-flex w-100 justify-content-end text-muted text-sm">
            {{ collection_remark.textLength }}/200
          </div>
        </div>
      </div>
    </div>

    <div class="mt-2">
      <h5>Besonderheit Ladendienst</h5>
      <div class="grid mt-4">
        <div class="field col-12">
          <span class="p-float-label">
            <p-calendar inputId="shop_remark_valid_until" styleClass="w-100" dateFormat="dd.mm.yy"
            formControlName="shop_remark_valid_until"></p-calendar>
            <label [for]="'shop_remark_valid_until'">Gültig bis</label>
          </span>
        </div>
        <div class="field col-12">
          <span class="p-float-label">
            <textarea #shop_remark maxLength="200" id="shop_remark" class="w-100" type="text"
            formControlName="shop_remark" pInputTextarea></textarea>
            <label for="shop_remark">Bemerkung</label>
          </span>
          <div class="d-flex w-100 justify-content-end text-muted text-sm">
            {{ shop_remark.textLength }}/200
          </div>
        </div>
        @if(statusForm.get('pickup_id')?.value) {
          <div class="field col-12">
            <span class="p-float-label">
              <input id="unpaid_balance" class="w-100" type="text" formControlName="unpaid_balance" pInputText/>
              <label for="unpaid_balance">Offene Restschuld (in €)</label>
            </span>
          </div>
          <input type="hidden" formControlName="pickup_id">
        }
      </div>
    </div>

    <div class="grid mt-2">
      <div class="field col-12">
        <span class="p-float-label">
          <p-calendar inputId="certificate_valid_until" styleClass="w-100" dateFormat="dd.mm.yy"
          formControlName="certificate_valid_until"></p-calendar>
          <label [for]="'certificate_valid_until'">Einkommensnachweis gültig bis</label>
        </span>
      </div>
    </div>

    @if (settingsService.settings()?.expirationActivated) {
      <div class="grid mt-2">
        <div class="field col-12">
          <span class="p-float-label">
            <p-calendar inputId="expiration_date" styleClass="w-100" dateFormat="dd.mm.yy"
            formControlName="expiration_date"></p-calendar>
            <label [for]="'expiration_date'">Befristungsdatum</label>
          </span>
        </div>
      </div>
    }

    <div class="mt-2">
      <h5>Beratungsgespräch</h5>
      <div class="grid mt-4">
        <div class="field col-12">
          <span class="p-float-label">
            <p-calendar inputId="consultation_date" styleClass="w-100" [showTime]="true" dateFormat="dd.mm.yy"
            formControlName="consultation_date"></p-calendar>
            <label [for]="'consultation_date'">Datum des Beratungsgesprächs</label>
          </span>
        </div>
      </div>
    </div>

    <div class="mt-2">
      <h5>Statusänderung</h5>
      <div class="grid mt-4">
        <div class="field col-12 md:col-6">
          <span class="p-float-label">
            <p-calendar inputId="status_change_date" styleClass="w-100" dateFormat="dd.mm.yy" [minDate]="tomorrow"
            formControlName="status_change_date"></p-calendar>
            <label [for]="'status_change_date'">Datum</label>
          </span>
        </div>
        <div class="field col-12 md:col-6">
          <span class="p-float-label">
            <p-dropdown inputId="status_change_target" styleClass="w-100" [options]="statuses" optionValue="status"
              formControlName="status_change_target" optionLabel="title"
            [autoDisplayFirst]="false"></p-dropdown>
            <label [for]="'status_change_target'">Ziel-Status</label>
          </span>
        </div>
        @if (this.statusForm.get('status_change_target')?.value === Status.DROPPED_OUT) {
          <div class="field col-12">
            <span class="p-float-label">
              <p-dropdown inputId="status_change_reason" styleClass="w-100" [options]="statusChangeReasonService.reasons"
                optionValue="id" formControlName="status_change_reason" optionLabel="title"
              [autoDisplayFirst]="false"></p-dropdown>
              <label [for]="'status_change_reason'">Grund</label>
            </span>
          </div>
        }
        <div class="field col-12">
          <span class="p-float-label">
            <textarea id="status_change_comment" class="w-100" type="text" formControlName="status_change_comment"
            pInputTextarea></textarea>
            <label for="status_change_comment">Kommentar</label>
          </span>
        </div>
        @if (this.statusForm.get('status_change_target')?.value === Status.ACTIVE) {
          <div class="field col-12">
            <h5>Künftige Nutzernummer</h5>
            <app-customer-no-inputs [codeForm]="$any(statusForm.controls['futureCodeForm'])"
            [upcoming]="true"></app-customer-no-inputs>
          </div>
        }
        <div class=" field col-12 flex justify-content-end">
          @if (statusForm.controls['status_change_date'].value || statusForm.controls['status_change_target'].value) {
            <button
              (click)="cancelStatusChange()" pButton class="p-button-outlined p-button-danger">WECHSEL STORNIEREN
            </button>
          }
        </div>
      </div>
    </div>
  </form>
</div>
