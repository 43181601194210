<div class="container mt-3">
  <p-confirmPopup></p-confirmPopup>
  <form [formGroup]="commentForm" (ngSubmit)="save()">
    <h5>Neue Notiz</h5>
    <div class="p-fluid grid mt-3">

      <div class="field col-12">
        <span class="p-float-label">
          <p-dropdown styleClass="w-100" [options]="commentService.contactTypes" formControlName="contact_type"
            optionLabel="title" inputId="contact_type" [autoDisplayFirst]="false" [showClear]="true"></p-dropdown>
          <label for="contact_type">Kontaktart</label>
        </span>
      </div>

      <div class="field col-12">
        <span class="p-float-label">
          <textarea pInputTextarea id="comment" formControlName="comment"></textarea>
          <label for="comment">Notiz</label>
        </span>
      </div>
    </div>
    <div class="w-100 d-flex justify-content-end mb-3">
      <button type="submit" pButton label="NOTIZ SPEICHERN" icon="pi pi-save" class="p-button-rounded"></button>
    </div>
  </form>

  <p-table [value]="commentService.comments" [paginator]="commentService.comments.length > 10" [rows]="10"
    [showCurrentPageReport]="true" currentPageReportTemplate="Zeige {first} bis {last} von {totalRecords} Notizen"
    [loading]="loading">
    <ng-template pTemplate="header">
      <tr>
        <th>Datum</th>
        <th>Kontaktart</th>
        <th>Bemerkung</th>
        <th>Autor</th>
        <th></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-comment>
      <tr>
        <td>{{comment.date | date : "dd.MM.yyyy"}}</td>
        <td>{{comment.contact_type?.title}}</td>
        <td>{{comment.comment}}</td>
        <td>{{comment.users_permissions_user?.username}}</td>
        <td>
          <button pButton pRipple type="button" icon="pi pi-times" (click)="delete(comment.id, $event)"
            class="p-button-rounded p-button-danger p-button-outlined"></button>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="5">Keine Notizen</td>
      </tr>
    </ng-template>
  </p-table>


</div>
