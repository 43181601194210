import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {UsersService} from "../administration/users/users.service";
import {AutoCompleteCompleteEvent} from "primeng/autocomplete";
import {User} from "../administration/users/user.model";
import {AlertMessagesService} from "./alert-messages.service";
import {ConfirmationService, MessageService} from "primeng/api";
import {AlertMessage} from "./alert-message.model";
import {TableLazyLoadEvent} from "primeng/table";
import {SidebarService} from "../shared/sidebar/sidebar.service";

@Component({
  selector: 'app-alert-messages',
  templateUrl: './alert-messages.component.html',
  styleUrls: ['./alert-messages.component.scss']
})
export class AlertMessagesComponent implements OnInit {
  receivedMessages: AlertMessage[] = [];
  sentMessages: AlertMessage[] = [];

  alertMessageForm = new FormGroup({
    recipients: new FormControl<User[]>([], [Validators.required]),
    message: new FormControl<string>('', [Validators.required]),
  });
  users: User[] = [];

  totalReceived = 0;
  loadingReceived = true;
  receivedTableEvent: TableLazyLoadEvent | undefined = undefined;

  totalSent = 0;
  loadingSent = true;
  sentTableEvent: TableLazyLoadEvent | undefined = undefined;

  constructor(
    public usersService: UsersService,
    public alertMessagesService: AlertMessagesService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService,
    public sidebarService: SidebarService,
  ) {
  }

  async ngOnInit(): Promise<void> {
    await this.usersService.fetchUsers();
    this.users = this.usersService.users;
  }

  async createMessages(): Promise<void> {
    try {
      const {recipients, message} = this.alertMessageForm.getRawValue()
      await this.alertMessagesService.createMultipleAlertMessages(recipients!, message!);
      await this.loadSentMessages(this.sentTableEvent!);
      await this.loadReceivedMessages(this.sentTableEvent!);
      this.messageService.add({
        severity: 'success',
        summary: 'Nachricht gesendet'
      });
    } catch (error) {
      console.error(error);
      this.messageService.add({
        severity: 'error',
        summary: 'Fehler',
        detail: `Nachricht konnte nicht gesendet werden.`
      });
    }
  }

  filterUsers(event: AutoCompleteCompleteEvent) {
    this.users = this.usersService.users.filter(user =>
      user.first_name?.trim().toLowerCase().includes(event.query.trim().toLowerCase()) ||
      user.last_name?.trim().toLowerCase().includes(event.query.trim().toLowerCase()) ||
      user.username.trim().toLowerCase().includes(event.query.trim().toLowerCase()));
  }

  async deleteMessage(id: number, event: Event) {
    this.confirmationService.confirm({
      target: event.target ? event.target : undefined,
      message: 'Möchtest du diese Nachricht wirklich löschen?',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: "JA",
      rejectLabel: "NEIN",
      accept: async () => {
        try {
          await this.alertMessagesService.deleteMessage(id);
          this.messageService.add({
            severity: 'success',
            summary: 'Nachricht gelöscht'
          });
          this.loadSentMessages(this.sentTableEvent!);
        } catch (e) {
          console.error(e);
          this.messageService.add({
            severity: 'error',
            summary: 'Fehler',
            detail: `Nachricht konnte nicht gelöscht werden.`
          });
        }
      }
    });
  }

  addAll() {
    this.alertMessageForm.get('recipients')?.setValue(this.usersService.users);
  }

  async loadReceivedMessages(event: TableLazyLoadEvent) {
    this.receivedTableEvent = event;
    this.loadingReceived = true;
    const response = await this.alertMessagesService.fetchMyReceivedMessages({
      pagination: event?.rows != null && event?.first != null ? {
        page: Math.floor(event.first / event.rows) + 1,
        pageSize: event.rows
      } : {},
      sort: ['seen:asc', 'createdAt:desc'],
    });
    this.receivedMessages = response?.data ?? [];
    this.totalReceived = response?.meta.pagination.total ?? 0;
    this.loadingReceived = false;
  }

  async loadSentMessages(event: TableLazyLoadEvent) {
    this.sentTableEvent = event;
    this.loadingSent = true;
    const response = await this.alertMessagesService.fetchMySentMessages({
      pagination: event?.rows != null && event?.first != null ? {
        page: Math.floor(event.first / event.rows) + 1,
        pageSize: event.rows
      } : {},
    });
    this.sentMessages = response?.data ?? [];
    this.totalSent = response?.meta.pagination.total ?? 0;
    this.loadingSent = false;
  }

  async markAsSeen(id: number) {
    await this.alertMessagesService.markAsSeen(id);
    void this.loadReceivedMessages(this.receivedTableEvent!);
    void this.sidebarService.updateSidebarStats();
  }
}
