<div class="grid">
  <div class="col-12">
    <p-card class="h-100 w-100" header="Allgemein">
      <form [formGroup]="profileForm" (ngSubmit)="saveGeneral()">
        <div class="p-fluid grid">

          <div class="field col-12 md:col-6">
            <span class="p-float-label">
              <input class="w-100" formControlName="first_name" id="first_name" type="text" pInputText/>
              <label for="first_name">Vorname</label>
            </span>
          </div>
          <div class="field col-12 md:col-6">
            <span class="p-float-label">
              <input id="last_name" formControlName="last_name" class="w-100" type="text" pInputText/>
              <label for="last_name">Nachname</label>
            </span>
          </div>

          <div class="field col-12 md:col-6">
            <span class="p-float-label">
              <input id="username" formControlName="username" class="w-100" type="text" pInputText/>
              <label for="username">Username</label>
            </span>
          </div>
          <div class="field col-12 md:col-6">
            <span class="p-float-label">
              <input id="email" formControlName="email" class="w-100" type="text" pInputText/>
              <label for="email">E-Mail</label>
            </span>
          </div>
        </div>
        <div class="d-flex justify-content-end">
          <button [disabled]="!profileForm.valid" pButton pRipple type="submit" label="SPEICHERN"
          class="p-button-outlined"></button>
        </div>

      </form>
    </p-card>
  </div>
  <div class="col-6">
    <p-card header="Avatar">
      <div class="">
        <div class="d-flex justify-content-center mb-3">
          <app-user-avatar [user]="auth.user() ?? undefined" [size]="'xlarge'"></app-user-avatar>
        </div>
        <div class="grid">
          <div class="col-6">
            <button (click)="file.click()" pButton pRipple type="button" label="DATEI AUSWÄHLEN"
            class="p-button-outlined w-100"></button>
            <input #file type="file" [hidden]="true" (change)="uploadAvatar($event)">
          </div>
          <div class="col-6">
            <button pButton pRipple type="button" label="AVATAR LÖSCHEN" (click)="deleteAvatar()"
            class="p-button-outlined p-button-danger w-100"></button>
          </div>
        </div>
      </div>
    </p-card>
  </div>
  <div class="col-6">
    <p-card header="Passwort ändern">
      <form [formGroup]="changePasswordForm" (ngSubmit)="changePW()">
        <div class="p-fluid grid">
          <div class="field col-12">
            <span class="p-float-label">
              <p-password id="current_password" [feedback]="false" formControlName="current_password" class="w-100"
              [toggleMask]="true"></p-password>
              <label for="current_password">Jetziges Passwort</label>
            </span>
          </div>
          <div class="field col-12">
            <span class="p-float-label">
              <p-password id="new_password" formControlName="new_password" class="w-100" [feedback]="false"
                [toggleMask]="true">
              </p-password>
              <label for="new_password">Neues Passwort</label>
            </span>

            @if (changePasswordForm.controls['new_password'].value) {
              <div>
                <ul class="pl-2 mt-2 list-style-none" style="line-height: 1.5">
                  @for (rule of newPasswordRequirements | keyvalue; track rule) {
                    <li class="mb-2 text-{{rule.value.value ? 'success' : 'danger'}}"
                      >
                      <i class="pi pi-{{rule.value.value ? 'check' : 'times'}}"></i>
                      {{ rule.value.text }}
                    </li>
                  }
                </ul>
              </div>
            }

          </div>
          <div class="field col-12">
            <span class="p-float-label">
              <p-password id="confirm_password" formControlName="confirm_password"
              class="w-100" [feedback]="false" [toggleMask]="true"></p-password>
              <label for="confirm_password">Passwort bestätigen</label>
            </span>

            @if (changePasswordForm.controls['new_password'].value && changePasswordForm.controls['new_password'].value !== changePasswordForm.controls['confirm_password'].value) {
              <div class='mt-2 ml-2'
                >
                <div class="text-danger">
                  <i class="pi pi-times"></i>
                  Passwörter stimmen nicht überein
                </div>
              </div>
            }
          </div>
        </div>
        <div class="d-flex justify-content-end">
          <button pButton pRipple type="submit" label="SPEICHERN" class="p-button-outlined"
          [disabled]="!changePasswordForm.valid"></button>
        </div>
      </form>
    </p-card>
  </div>
</div>
