import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {AbsenceService} from "../../../absences-overview/absence.service";
import {ConfirmationService, MessageService} from "primeng/api";
import {Absence, AbsenceStatus, absenceStatuses} from "../../../absences-overview/absence.model";

@Component({
  selector: 'app-absences',
  templateUrl: './absences.component.html',
  styleUrls: ['./absences.component.scss'],
  providers: [ConfirmationService]
})
export class AbsencesComponent implements OnInit {

  absenceForm = new FormGroup({
    date: new FormControl<Date>(new Date()),
    absence_status: new FormControl(AbsenceStatus.UNEXCUSED),
    comment: new FormControl('')
  });
  @Input() customerId!: number;
  loading = true;

  constructor(
    public absenceService: AbsenceService,
    public messageService: MessageService,
    public confirmationService: ConfirmationService,
  ) { }

  async ngOnInit(): Promise<void> {
    await this.absenceService.fetchAbsencesForCustomer(this.customerId);
    this.loading = false;
  }

  async save(): Promise<void> {
    const {date, absence_status, comment} = this.absenceForm.value;
    if (date && absence_status) {
      try {
        await this.absenceService.createAbsence(date, absence_status, comment ?? '', this.customerId);
        this.messageService.add({severity: 'success', summary: 'Fehlzeit gespeichert'});
        this.absenceForm.reset();
        this.loading = true;
        await this.absenceService.fetchAbsencesForCustomer(this.customerId);
        this.loading = false;
      } catch (e) {
        console.error(e);
        this.messageService.add({severity: 'error', summary: 'Fehler beim Speichern der Fehlzeit'});
      }
    }
  }

  async delete(id: string, event: Event): Promise<void> {
    this.confirmationService.confirm({
      target: event.target ? event.target : undefined,
      message: 'Möchtest du diese Fehlzeit wirklich löschen?',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: "JA",
      rejectLabel: "NEIN",
      accept: async () => {
        try {
          await this.absenceService.deleteAbsence(id);
          this.messageService.add({severity: 'success', summary: 'Fehlzeit gelöscht'});
          this.loading = true;
          await this.absenceService.fetchAbsencesForCustomer(this.customerId);
          this.loading = false;
        } catch (e) {
          console.error(e);
          this.messageService.add({severity: 'error', summary: 'Fehler beim Löschen der Fehlzeit'});
        }
      }
    });
  }

  async update(absence: Absence, event: {value?: string}): Promise<void> {
    try {
      if (event.value == null) {
        this.messageService.add({severity: 'error', summary: 'Bitte einen Status angeben'});
        return;
      }
      await this.absenceService.updateAbsence(absence.id, new Date(absence.date), event.value, absence.comment, this.customerId);
      this.messageService.add({severity: 'success', summary: 'Status aktualisiert'});
      await this.absenceService.fetchAbsencesForCustomer(this.customerId);
    } catch (e) {
      this.messageService.add({severity: 'error', summary: 'Fehler beim Aktualisieren'});
    }
  }

  protected readonly absenceStatuses = absenceStatuses;
}
