<p-confirmPopup></p-confirmPopup>
<p-card header="Filter">
  <div class="row">
    <div class="col">
      <h5>Ausgabeort</h5>
      @for (loc of locationService.locations; track loc.id) {
        <div class="field-checkbox">
          <p-checkbox [value]="loc.id" [(ngModel)]="selectedLocations"
          [inputId]="'loc-' + loc.id.toString()"></p-checkbox>
          <label [for]="'loc-' + loc.id">{{loc.city}}</label>
        </div>
      }
    </div>
    <div class="col">
      <h5>Wochentage</h5>
      @for (weekday of weekdayService.weekdays; track weekday.id) {
        <div class="field-checkbox">
          <p-checkbox [value]="weekday.id" [(ngModel)]="selectedWeekdays" [inputId]="'day-' + weekday.id"></p-checkbox>
          <label [for]="'day-' + weekday.id">{{weekday.title}}</label>
        </div>
      }
    </div>
    <div class="col">
      <h5>Allgemein</h5>

      <div class="field-checkbox mt-3">
        <p-checkbox [binary]="true" [(ngModel)]="availableOnly" inputId="free"></p-checkbox>
        <label for="free">Nur verfügbare Nummern</label>
      </div>

      <div class="field-checkbox mt-3">
        <p-checkbox [binary]="true" [(ngModel)]="showOnlyFreeCodes" inputId="isFreeCode"></p-checkbox>
        <label [for]="'isFreeCode'">Nur gratis Nummern</label>
      </div>
    </div>
  </div>

  <div class="d-flex justify-content-end">
    <button pButton pRipple icon="pi pi-filter" label="FILTERN"
    (click)="loadCodes(lastTableLazyLoadEvent)"></button>
  </div>
</p-card>
<div class="mt-3">
  <p-card class="mt-3">

    <p-table [value]="codes()"
      [lazy]="true"
      (onLazyLoad)="loadCodes($event)"
      [paginator]="true"
      [rows]="25"
      [rowsPerPageOptions]="[10, 25, 50, 100]"
      [totalRecords]="totalCodes()"
      [showCurrentPageReport]="true"
      [loading]="loading()"
      dataKey="id"
      currentPageReportTemplate="Zeige {first} bis {last} von {totalRecords} Nutzernummern"
      editMode="row">
      <ng-template pTemplate="caption">
        <div class="table-header d-flex justify-content-center">

          <span class="p-input-icon-left w-100">
            <i class="pi pi-search"></i>
            <input class="w-100" pInputText type="text"
              (input)="loadCodes(lastTableLazyLoadEvent, $any($event).target.value)"
              placeholder="Suche"/>
          </span>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="value">Nr.
            <p-sortIcon field="value"></p-sortIcon>
          </th>
          <th pSortableColumn="weekday.title">Wochentag
            <p-sortIcon field="weekday.title"></p-sortIcon>
          </th>
          <th pSortableColumn="time">Zeit
            <p-sortIcon field="time"></p-sortIcon>
          </th>
          <th pSortableColumn="isFreeCode">Gratis
            <p-sortIcon field="isFreeCode"></p-sortIcon>
          </th>
          <th pSortableColumn="customer.last_name">Wöchentlich
            <p-sortIcon field="customer.last_name"></p-sortIcon>
          </th>
          <th pSortableColumn="customer_rotation_1.last_name">Turnus 1
            <p-sortIcon field="customer_rotation_1.last_name"></p-sortIcon>
          </th>
          <th pSortableColumn="customer_rotation_2.last_name">Turnus 2
            <p-sortIcon field="customer_rotation_2.last_name"></p-sortIcon>
          </th>
          <th pSortableColumn="future_customer.last_name">Künftig wöchentlich
            <p-sortIcon field="future_customer.last_name"></p-sortIcon>
          </th>
          <th pSortableColumn="future_customer_rotation_1.last_name">Künftig Turnus 1
            <p-sortIcon field="future_customer_rotation_1.last_name"></p-sortIcon>
          </th>
          <th pSortableColumn="future_customer_rotation_2.last_name">Künftig Turnus 2
            <p-sortIcon field="future_customer_rotation_2.last_name"></p-sortIcon>
          </th>
          <th style="min-width: 140px;"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-code let-editing="editing" let-ri="rowIndex">
        <tr [pEditableRow]="code">
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                {{code.location.abbreviation}} <input pInputText type="number" [(ngModel)]="code.value" required>
              </ng-template>
              <ng-template pTemplate="output">
                {{code.location.abbreviation}} {{code.value}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-dropdown styleClass="w-100" [options]="weekdayService.weekdays" [(ngModel)]="code.weekday"
                optionLabel="title" [autoDisplayFirst]="false" appendTo="body"></p-dropdown>
              </ng-template>
              <ng-template pTemplate="output">
                {{code.weekday.title}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-calendar styleClass="w-100" [timeOnly]="true" hourFormat="24" [(ngModel)]="code.time"></p-calendar>
              </ng-template>
              <ng-template pTemplate="output">
                {{code.time}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-checkbox [binary]="true" [(ngModel)]="code.isFreeCode"></p-checkbox>
              </ng-template>
              <ng-template pTemplate="output">
                @if (code.isFreeCode) {
                  <i class="pi pi-check"></i>
                }
              </ng-template>
            </p-cellEditor>
          </td>
          <td>{{code.customer?.first_name}} {{code.customer?.last_name}}</td>
          <td>{{code.customer_rotation_1?.first_name}} {{code.customer_rotation_1?.last_name}}</td>
          <td>{{code.customer_rotation_2?.first_name}} {{code.customer_rotation_2?.last_name}}</td>
          <td>{{code.future_customer?.first_name}} {{code.future_customer?.last_name}}</td>
          <td>{{code.future_customer_rotation_1?.first_name}} {{code.future_customer_rotation_1?.last_name}}</td>
          <td>{{code.future_customer_rotation_2?.first_name}} {{code.future_customer_rotation_2?.last_name}}</td>
          <td class="d-flex justify-content-center">
            @if (!editing) {
              <button pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil"
              (click)="onRowEditInit(code)" class="p-button-rounded p-button-outlined mr-2"></button>
            }
            @if (!editing) {
              <button pButton pRipple type="button" icon="pi pi-trash"
                (click)="deleteCode(code.id, $event)"
              class="p-button-rounded p-button-danger p-button-outlined"></button>
            }
            @if (editing) {
              <button pButton pRipple type="button" pSaveEditableRow icon="pi pi-check"
                (click)="onRowEditSave(code)"
              class="p-button-rounded p-button-outlined p-button-success mr-2"></button>
            }
            @if (editing) {
              <button pButton pRipple type="button" pCancelEditableRow icon="pi pi-times"
                (click)="onRowEditCancel(code, ri)"
              class="p-button-rounded p-button-outlined p-button-danger"></button>
            }
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="5">Keine Nutzernummern gefunden</td>
        </tr>
      </ng-template>
    </p-table>


    <div class="d-flex justify-content-end mt-3">
      <button pButton icon="pi pi-plus" label="HINZUFÜGEN"
      (click)="generateCodesOpen = true; generateForm.reset()"></button>
    </div>
  </p-card>
</div>
<p-dialog header="Nutzernummern erstellen" [(visible)]="generateCodesOpen" [modal]="true">
  <div class="container mt-3">
    <h5>Einzelne Nummer erstellen</h5>
    <form [formGroup]="createForm" autocomplete="off" (ngSubmit)="saveSingle()">
      <div class="p-fluid grid mt-5">
        <div class="field col-12 md:col-6">
          <span class="p-float-label">
            <p-dropdown inputId="location-single" styleClass="w-100" [options]="locationService.locations" optionValue="id"
                        formControlName="location" optionLabel="city" [autoDisplayFirst]="false" tabindex="0"
                        appendTo="body"></p-dropdown>
            <label [for]="'location-single'">Standort</label>
          </span>
        </div>

        <div class="field col-12 md:col-6">
            <span class="p-float-label">
              <input id="value" formControlName="value" class="w-100" type="number" pInputText/>
              <label for="value">Nummer</label>
            </span>
        </div>

        <div class="field col-12 md:col-6">
          <span class="p-float-label">
            <p-dropdown inputId="weekday-single" styleClass="w-100" [options]="weekdayService.weekdays" optionValue="id"
                        formControlName="weekday" optionLabel="title" [autoDisplayFirst]="false"
                        appendTo="body"></p-dropdown>
            <label [for]="'weekday-single'">Wochentage</label>
          </span>
        </div>

        <div class="field col-12 md:col-6">
          <span class="p-float-label">
            <p-calendar inputId="time" formControlName="time" styleClass="w-100" [timeOnly]="true"
                        hourFormat="24"></p-calendar>
            <label [for]="'time'">Uhrzeit</label>
          </span>
        </div>

        <div class="field col-12">
          <p-checkbox formControlName="isFreeCode" [binary]="true" label="Kostenlose Nummer (z.B. für gemeinnützige Organisationen)"></p-checkbox>
        </div>

      </div>

      <div class="flex justify-content-end">
        <button pButton icon="pi pi-save" type="submit" label="SPEICHERN"></button>
      </div>
    </form>
    <hr>
    <h5>Nummernblock generieren</h5>
    <form [formGroup]="generateForm" autocomplete="off">
      <div class="p-fluid grid mt-5">
        <div class="field col-12 md:col-6">
          <span class="p-float-label">
            <p-calendar inputId="time_from" formControlName="time_from" styleClass="w-100" [timeOnly]="true"
            hourFormat="24"></p-calendar>
            <label for="time_from">Von</label>
          </span>
        </div>
        <div class="field col-12 md:col-6">
          <span class="p-float-label">
            <p-calendar inputId="time_until" formControlName="time_until" styleClass="w-100" [timeOnly]="true"
            hourFormat="24"></p-calendar>
            <label for="time_until">Bis</label>
          </span>
        </div>

        <div class="field col-12 md:col-6">
          <span class="p-float-label">
            <input id="interval" formControlName="interval" class="w-100" type="number" pInputText/>
            <label for="interval">Takt (in Minuten)</label>
          </span>
        </div>
        <div class="field col-12 md:col-6">
          <span class="p-float-label">
            <input id="amount" formControlName="amount" class="w-100" type="" pInputText/>
            <label for="amount">Nummern pro Zeit-Slot</label>
          </span>
        </div>
        <div class="field col-12 md:col-6">
          <span class="p-float-label">
            <p-dropdown inputId="weekday" styleClass="w-100" [options]="weekdayService.weekdays" optionValue="id"
              formControlName="weekday" optionLabel="title" [autoDisplayFirst]="false"
            appendTo="body"></p-dropdown>
            <label for="weekday">Wochentage</label>
          </span>
        </div>
        <div class="field col-12 md:col-6">
          <span class="p-float-label">
            <p-dropdown inputId="location" styleClass="w-100" [options]="locationService.locations" optionValue="id"
              formControlName="location" optionLabel="city" [autoDisplayFirst]="false"
            appendTo="body"></p-dropdown>
            <label for="location">Standort</label>
          </span>
        </div>
        <div class="field col-12">
          <span class="p-float-label">
            <input id="num_start" formControlName="num_start" class="w-100" type="" pInputText/>
            <label for="num_start">Start-Nummer</label>
          </span>
        </div>
        <div class="field col-12">
          <p-checkbox [binary]="true" formControlName="isFreeCode" label="Kostenlose Nummer (z.B. für gemeinnützige Organisationen)"></p-checkbox>
        </div>
      </div>
    </form>
  </div>

  <ng-template pTemplate="footer">
    <button pButton icon="pi pi-times" class="p-button-secondary p-button-outlined"
    (click)="generateForm.reset(); generateCodesOpen = false;" label="ABBRECHEN"></button>
    <button pButton icon="pi pi-save" (click)="save()" label="SPEICHERN"></button>
  </ng-template>

</p-dialog>
