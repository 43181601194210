<div class="container mt-3">
  <form [formGroup]="memberForm">
    <h5>Antragsteller</h5>
    <div class="p-fluid grid">
      <div class="field col-12 mt-3">
        <span class="p-float-label">
          <p-calendar inputId="applicant_birth_date" styleClass="w-100" dateFormat="dd.mm.yy"
          formControlName="applicant_birth_date"></p-calendar>
          <label [for]="'applicant_birth_date'">Geburtsdatum</label>
        </span>
        <input type="hidden" formControlName="member_id">
      </div>
    </div>

    <ng-container>
      @if (members.length > 0) {
        <h5>Familienmitglieder</h5>
      }
      <div class="p-fluid grid mt-3" formArrayName="members">
        @for (_ of members.controls; track _; let i = $index) {
          <ng-container [formGroupName]="i">
            <input type="hidden" formControlName="id">
            <div class="field col-12 lg:col-2">
              <span class="p-float-label">
                <p-dropdown inputId="member_{{i}}_gender" styleClass="w-100" [options]="genderService.genders"
                  optionValue="id" formControlName="gender" optionLabel="gender"
                [autoDisplayFirst]="false"></p-dropdown>
                <label for="member_{{i}}_gender">Geschlecht</label>
              </span>
            </div>
            <div class="field col-12 md:col-6">
              <p-selectButton [disabled]="members.controls[i].get('ageType')?.value === AgeType.DATE" [options]="ageTypeOptions" formControlName="ageType" optionLabel="label" optionValue="value"></p-selectButton>
            </div>
            <div class="field col-12 md:col-3 lg:col-2">
              <span class="p-float-label">
                <p-calendar #birth inputId="member_{{i}}_birth_date" styleClass="w-100" dateFormat="dd.mm.yy"
                  formControlName="birthDate" appendTo="body"></p-calendar>
                <label for="member_{{i}}_birth_date">Geburtsdatum</label>
              </span>
            </div>
            @if (members.controls[i].get('ageType')?.value === AgeType.DATE) {
              <div class="col-11 md:col-1">
                Alter:<br> {{birth.value | age }} Jahre
              </div>
            }
            <div class="col-1 md:col-1">
              <button (click)="removeMember(i)" pButton pRipple type="button" icon="pi pi-times"
              class="p-button-rounded p-button-danger p-button-outlined"></button>
            </div>
          </ng-container>
        }
      </div>
      <div class="d-flex justify-content-end">
        <button pButton label="MITGLIED HINZUFÜGEN" icon="pi pi-plus" (click)="addMember()"
        class="p-button-rounded"></button>
      </div>
    </ng-container>

    @if (settingsService.settings()) {
      <div class="grid mt-3">
      <div class="col-3">
        <div class="w-100 d-flex justify-content-center flex-column shadow-4 bg-gray-50 rounded py-3">
          <span class="text-center">Personen < {{settingsService.settings()!.adultAge}} Jahre</span>
          <div class="d-flex justify-content-center">
            <span class="text-center text-4xl ml-2 mr-2">{{childrenCount}}</span>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="w-100 d-flex justify-content-center flex-column shadow-4 bg-gray-50 rounded py-3">
          <span class="text-center">Personen > {{settingsService.settings()!.adultAge}} Jahre</span>
          <div class="d-flex justify-content-center">
            <span class="text-center text-4xl ml-2 mr-2">{{adultCount}}</span>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="w-100 d-flex justify-content-center flex-column shadow-4 bg-gray-50 rounded py-3">
          <span class="text-center">Kisten</span>
          <span class="text-center text-4xl">{{getBoxCount()}}</span>
        </div>
      </div>
      <div class="col-3">
        <div class="w-100 d-flex justify-content-center flex-column shadow-4 bg-gray-50 rounded py-3">
          <span class="text-center">Betrag</span>
          <span class="text-center text-4xl">{{getCalculatedPrice()}} €</span>
        </div>
      </div>
    </div>
    }
  </form>
</div>
