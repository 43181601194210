import {Injectable} from '@angular/core';
import {LoadingState} from "../shared/loading-state.enum";
import {HttpClient} from "@angular/common/http";
import {firstValueFrom} from "rxjs";
import {ResponseModel, SingleResponseModel} from "../shared/response.model";
import {environment} from "../../environments/environment";
import {Absence, AbsenceStatus, absenceStatuses, AbsenceViolation} from "./absence.model";
import * as qs from "qs";
import {format} from "date-fns";
import {StrapiQueryOptions} from "../shared/strapi-query-options.model";

@Injectable({
  providedIn: 'root'
})
export class AbsenceService {
  loadingState = LoadingState.Loading;
  absences: Absence[] = [];
  allAbsences: Absence[] = [];
  totalAbsences = 0;

  constructor(private http: HttpClient) {
  }

  async fetchAbsencesForCustomer(customerId: number): Promise<void> {
    const query = qs.stringify({
      filters: {
        customer: {
          id: {
            $eq: customerId
          }
        }
      },
      populate: {
        absence_status: true
      }
    }, {encodeValuesOnly: true});
    try {
      const response = await firstValueFrom(this.http.get<ResponseModel<Absence>>(environment.apiUrl + `/absences?${query}`));
      this.absences = response.data;
      this.loadingState = LoadingState.Data;
    } catch (e) {
      console.error(e);
      this.loadingState = LoadingState.Error;
      this.absences = [];
    }
  }

  async fetchAbsences(params: StrapiQueryOptions): Promise<void> {
    this.loadingState = LoadingState.Loading;
    const query = qs.stringify({
      filters: {
        absence_status: {
          $in: params.filter?.["statuses"],
        },
        $and: [
          {date: params.filter?.["minDate"] ? {$gte: params.filter?.["minDate"]} : {}},
          {date: params.filter?.["maxDate"] ? {$lte: params.filter?.["maxDate"]} : {}}
        ]
      },
      populate: {
        customer: {
          fields: ['first_name', 'last_name']
        }
      },
      sort: 'date',
      pagination: {
        page: 1,
        pageSize: 9999
      }
    }, {encodeValuesOnly: true});
    try {
      const response = await firstValueFrom(this.http.get<ResponseModel<Absence>>(environment.apiUrl + `/absences?${query}`));
      this.allAbsences = response.data;
      this.totalAbsences = response.meta.pagination.total;
      this.loadingState = LoadingState.Data;
    } catch (e) {
      console.error(e);
      this.allAbsences = [];
      this.loadingState = LoadingState.Error;
    }
  }

  async createAbsence(date: Date, absenceStatus: AbsenceStatus, comment: string, customerId: number): Promise<Absence> {
    const response = await firstValueFrom(this.http.post<SingleResponseModel<Absence>>(environment.apiUrl + "/absences", {
      data: {
        absence_status: absenceStatus,
        comment,
        customer: customerId,
        date: format(date, "yyyy-MM-dd"),
      }
    }));
    return response?.data;
  }

  async deleteAbsence(absenceId: string): Promise<Absence> {
    const response = await firstValueFrom(this.http.delete<SingleResponseModel<Absence>>(environment.apiUrl + `/absences/${absenceId}`));
    return response?.data;
  }

  async updateAbsence(id: number, date: Date, absenceStatus: string, comment: string, customerId: number): Promise<Absence> {
    const response = await firstValueFrom(this.http.put<SingleResponseModel<Absence>>(environment.apiUrl + `/absences/${id}`, {
      data: {
        absence_status: absenceStatus,
        comment,
        customer: customerId,
        date: format(date, "yyyy-MM-dd"),
      }
    }));
    return response?.data;
  }

  async fetchViolations(): Promise<ResponseModel<AbsenceViolation>> {
    return await firstValueFrom(this.http.get<ResponseModel<AbsenceViolation>>(environment.apiUrl + `/absence-violations`));
  }

  getHumanFriendlyAbsenceStatus(status: string) {
    for (const statusModel of absenceStatuses) {
      if (statusModel.status === status) {
        return statusModel.title;
      }
    }

    return '';
  }
}
