<p-card>
  <p-table [value]="automations()" dataKey="id" [rows]="25" [loading]="loading()">
    <ng-template pTemplate="header">
      <tr>
        <th>Titel</th>
        <th>Status</th>
        <th>Erfolg</th>
        <th>Zuletzt</th>
        <th></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-automation>
      <tr class="p-selectable-row">
        <td>{{automation.title}} <br><span class="text-sm text-black-alpha-50">{{automation.description}}</span> </td>
        <td>{{automation.status}}</td>
        <td>
          @if(automation.success) {
            <i class="pi pi-check text-success"></i>
          } @else {
            <i class="pi pi-times text-danger"></i>
          }
        </td>
        <td>{{automation.last_execute | date : 'dd.MM.yyyy HH:mm \'Uhr\''}}</td>
        <td>
          @if(automation.running) {
            <p-progressSpinner styleClass="spinner"></p-progressSpinner>
          } @else {
            <button pButton pRipple type="button" icon="pi pi-play" class="p-button-rounded p-button-outlined p-button-success" (click)="execute(automation)"></button>
          }
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="5">Keine Automatisierungen gefunden</td>
      </tr>
    </ng-template>
  </p-table>
</p-card>
