<p-table [value]="data" dataKey="id" editMode="row">
  <ng-template pTemplate="header">
    <tr>
      <th>ID</th>
      <th>Titel</th>
      <th></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-item let-editing="editing" let-ri="rowIndex">
    <tr [pEditableRow]="item">
      <td>
        {{item.id}}
      </td>
      <td>
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input pInputText type="text" [(ngModel)]="item.title" required>
          </ng-template>
          <ng-template pTemplate="output">
            {{item.title}}
          </ng-template>
        </p-cellEditor>
      </td>
      <td>
        <div class="flex align-items-center justify-content-center gap-2">
          @if (!editing) {
            <button pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil"
            (click)="onRowEditInit(item)" class="p-button-rounded p-button-outlined mr-2"></button>
          }
          @if (!editing) {
            <button pButton pRipple type="button" icon="pi pi-trash" (click)="deleteItem($event, item)"
            class="p-button-rounded p-button-danger p-button-outlined"></button>
          }
          @if (editing) {
            <button pButton pRipple type="button" pSaveEditableRow icon="pi pi-check"
            (click)="onRowEditSave(item)" class="p-button-rounded p-button-outlined p-button-success mr-2"></button>
          }
          @if (editing) {
            <button pButton pRipple type="button" pCancelEditableRow icon="pi pi-times"
            (click)="onRowEditCancel(item, ri)" class="p-button-rounded p-button-outlined p-button-danger"></button>
          }
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>

<div class="d-flex mt-5">
  <span class="p-float-label flex-grow-1 mr-2">
    <input #new id="float-input" class="w-100" type="text" pInputText>
    <label for="float-input">Titel</label>
  </span>
  <button pButton icon="pi pi-plus" label="HINZUFÜGEN" (click)="create.emit(new.value); new.value=''"></button>
</div>

<p-confirmPopup></p-confirmPopup>
