import {Injectable, signal, WritableSignal} from '@angular/core';
import {Settings} from "./settings.model";
import {HttpClient} from "@angular/common/http";
import {lastValueFrom} from "rxjs";
import {SingleResponseModel} from "../shared/response.model";
import {environment} from "../../environments/environment";
import {LoadingState} from "../shared/loading-state.enum";
import {MessageService} from "primeng/api";
import {Customer} from "../customer-overview/customer.model";
import {AgeType} from "../customer-overview/add-edit-customer/member-form/family-member.model";
import {isAfter, subYears} from "date-fns";

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  settings: WritableSignal<Settings | undefined> = signal(undefined);
  loading: LoadingState = LoadingState.Loading

  constructor(
    private http: HttpClient,
    private messageService: MessageService
  ) {
    void this.fetchSettings();
  }

  async fetchSettings(): Promise<void> {
    try {
      const response = await lastValueFrom(this.http.get<SingleResponseModel<Settings>>(`${environment.apiUrl}/setting`))
      this.settings.set(response.data);
      this.loading = LoadingState.Data;
    } catch (e) {
      console.error(e);
      this.loading = LoadingState.Error;
    }
  }

  async save(settings: Settings): Promise<void> {
    try {
      this.settings.set((await lastValueFrom(this.http.put<SingleResponseModel<Settings>>(`${environment.apiUrl}/setting`, { data: settings }))).data);
      this.loading = LoadingState.Data;
      this.messageService.add({
        severity: 'success',
        summary: 'Einstellungen gespeichert'
      });
    } catch (e) {
      console.error(e);
      this.messageService.add({
        severity: 'error',
        summary: 'Fehler beim Speichern der Einstellungen'
      });
    }
  }

  calculateBoxCount(customer: Customer) {
    const adultCountWithoutDate = customer.family_members.filter(member => member.ageType === AgeType.ADULT).length;
    const adultCount = adultCountWithoutDate + customer.family_members.filter(member => member.ageType === AgeType.DATE && member.birthDate && isAfter(subYears(new Date(), this.settings()!.adultAge), new Date(member.birthDate))).length;
    if (adultCount < 3) {
      return 1;
    } else if (adultCount < 5) {
      return 2;
    } else {
      return 3;
    }
  }

  calculatePrice(customer: Customer) {
    if ((customer.code ?? customer.code_rotation_1 ?? customer.code_rotation_2).isFreeCode) {
      return 0;
    }
    if (this.settings()) {
      return this.settings()!.singleBoxPrice + (this.calculateBoxCount(customer) - 1) * (this.settings()!.extraBoxPrice);
    } else {
      return 0;
    }
  }
}
