import { Absence } from "../absences-overview/absence.model";
import { Characteristic } from "../administration/characteristic/characteristic.model";
import { Comment } from "./add-edit-customer/comment/comment.model";
import { FamilyMember } from "./add-edit-customer/member-form/family-member.model";
import { FamilyStatus } from "../administration/family-status/family-status.model";
import { IncomeType } from "../administration/income-type/income-type.model";
import { Nationality } from "../administration/nationality/nationality.model";
import { Status } from "./add-edit-customer/status-form/status.model";
import { Code } from "../code/code.model";
import { StatusChangeReason } from "../administration/status-change-reason/status-change-reason.model";
import { CustomerEvent } from "./add-edit-customer/history/customer-event.model";
import {LocationModel} from "../administration/location/location.model";
import {Pickup} from "../pick-up/pickup.model";
import {Supervisor} from "./add-edit-customer/main-form/supervisor.model";

export interface Customer {
  id?: number;
  absences?: Absence[];
  characteristics: Characteristic[];
  city: string;
  collection_remark: string;
  comment: string;
  comments?: Comment[];
  customer_events: CustomerEvent[];
  createdAt?: Date;
  family_members: FamilyMember[];
  family_status: FamilyStatus;
  first_name: string;
  house_number: string;
  income_type_a: IncomeType;
  income_type_b: IncomeType;
  income_type_c: IncomeType;
  last_name: string;
  mail: string;
  mobile: string;
  nationality: Nationality;
  origin: string;
  phone: string;
  certificate_valid_until?: string | null;
  shop_remark: string;
  shop_remark_valid_until?: string | null;
  status: Status;
  street: string;
  address_addition: string;
  updates?: CustomerEvent[];
  zip: string;
  code: Code;
  code_rotation_1: Code;
  code_rotation_2: Code;
  future_code: Code;
  future_code_rotation_1: Code;
  future_code_rotation_2: Code;
  status_change_date?: string | null;
  status_change_reason: StatusChangeReason;
  status_change_target: Status;
  status_change_comment: string;
  consultation_date: string;
  future_location: LocationModel;
  expiration_date: string | null;
  pickups?: Pickup[] | null;
  supervisors?: Supervisor[] | null
}

export interface AddressCompletion {
  street: string;
  zip: string;
  city: string;
}

export const customerFieldNames: { [key: string]: { name: string, icon?: string, type: 'none' | 'status' | 'date' | 'customer_code' | 'family_members' | 'nationality' | 'family_status' | 'characteristics' | 'income_type' | 'future_location' | 'supervisors' } } = {
  'first_name': {
    name: 'Vorname',
    icon: '',
    type: 'none',
  },
  'last_name': {
    name: 'Nachname',
    icon: '',
    type: 'none',
  },
  'street': {
    name: 'Straße',
    icon: 'pi-map-marker',
    type: 'none',
  },
  'house_number': {
    name: 'Hausnummer',
    icon: 'pi-map-marker',
    type: 'none',
  },
  'zip': {
    name: 'PLZ',
    icon: 'pi-map-marker',
    type: 'none',
  },
  'city': {
    name: 'Stadt',
    icon: 'pi-map-marker',
    type: 'none',
  },
  'phone': {
    name: 'Telefon-Nr.',
    icon: 'pi-phone',
    type: 'none',
  },
  'mobile': {
    name: 'Mobil-Nr.',
    icon: 'pi-mobile',
    type: 'none',
  },
  'mail': {
    name: 'E-Mail',
    icon: 'pi-envelope',
    type: 'none',
  },
  'comment': {
    name: 'Kommentar',
    icon: 'pi-comment',
    type: 'none',
  },
  'origin': {
    name: 'Herkunft',
    icon: 'pi-globe',
    type: 'none',
  },
  'status_change_comment': {
    name: 'Statuswechsel-Kommentar',
    icon: '',
    type: 'none',
  },
  'consultation_date': {
    name: 'Beratungsdatum',
    icon: 'pi-calendar',
    type: 'date',
  },
  'collection_remark': {
    name: 'Bemerkung Abholung',
    icon: 'pi-comment',
    type: 'none',
  },
  'shop_remark': {
    name: 'Bemerkung Ladendienst',
    icon: 'pi-comment',
    type: 'none',
  },
  'shop_remark_valid_until': {
    name: 'Bemerkung Ladendienst gültig bis',
    icon: '',
    type: 'date',
  },
  'status': {
    name: 'Status',
    icon: 'pi-info-circle',
    type: 'status',
  },
  'status_change_date': {
    name: 'Statuswechsel-Datum',
    icon: '',
    type: 'date',
  },
  'status_change_target': {
    name: 'Statuswechsel-Ziel',
    icon: '',
    type: 'status',
  },
  'status_change_reason': {
    name: 'Statuswechsel-Grund',
    icon: '',
    type: 'none',
  },
  'code': {
    name: 'Kunden-Nr. (Wöchentlich)',
    icon: 'pi-user',
    type: 'customer_code',
  },
  'code_rotation_1': {
    name: 'Kunden-Nr. (Turnus 1)',
    icon: 'pi-user',
    type: 'customer_code',
  },
  'code_rotation_2': {
    name: 'Kunden-Nr. (Turnus 2)',
    icon: 'pi-user',
    type: 'customer_code',
  },
  'future_code': {
    name: 'Zukünftige Kunden-Nr. (Wöchentlich)',
    icon: 'pi-user',
    type: 'customer_code',
  },
  'future_location': {
    name: 'Künftiger Ausgabeort',
    icon: 'pi-map-marker',
    type: 'future_location',
  },
  'future_code_rotation_1': {
    name: 'Zukünftige Kunden-Nr. (Turnus 1)',
    icon: 'pi-user',
    type: 'customer_code',
  },
  'future_code_rotation_2': {
    name: 'Zukünftige Kunden-Nr. (Turnus 2)',
    icon: 'pi-user',
    type: 'customer_code',
  },
  'income_type_a': {
    name: 'Einkommensart A',
    icon: 'pi-euro',
    type: 'income_type',
  },
  'income_type_b': {
    name: 'Einkommensart B',
    icon: 'pi-euro',
    type: 'income_type',
  },
  'income_type_c': {
    name: 'Einkommensart C',
    icon: 'pi-euro',
    type: 'income_type',
  },
  'family_status': {
    name: 'Familienstand',
    icon: 'pi-users',
    type: 'family_status',
  },
  'nationality': {
    name: 'Nationalität',
    icon: 'pi-globe',
    type: 'nationality',
  },
  'characteristics': {
    name: 'Charakteristika',
    icon: 'pi-info-circle',
    type: 'characteristics',
  },
  'comments': {
    name: 'Kommentare',
    icon: '',
    type: 'none',
  },
  'family_members': {
    name: 'Familienmitglieder',
    icon: '',
    type: 'family_members',
  },
  'absences': {
    name: 'Abwesenheiten',
    icon: '',
    type: 'none',
  },
  'certificate_valid_until': {
    name: 'Einkommensnachweis',
    icon: '',
    type: 'date',
  },
  'supervisors': {
    name: 'Betreuer',
    icon: 'pi-users',
    type: 'supervisors',
  },
  'address_addition': {
    name: 'Adresszusatz',
    icon: 'pi-map-marker',
    type: 'none'
  },
  // will be ignored
  'ignore': {
    name: 'Ignoriert',
    icon: '',
    type: 'none',
  },
  // Synthetic customer events start here
  'status_change_started': {
    name: 'Statuswechsel eingerichtet',
    icon: 'pi-sync',
    type: 'none',
  },
  'expiration_date': {
    name: 'Befristungsdatum',
    icon: '',
    type: 'date',
  }
}
