import {Injectable, signal, WritableSignal} from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { firstValueFrom, lastValueFrom } from "rxjs";
import { LoginResponseDTO, User } from "../administration/users/user.model";
import { Router } from "@angular/router";
import * as qs from "qs";
import {RoleEnum} from "./role.enum";


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  jwt: WritableSignal<string | null> = signal(localStorage.getItem("jwt"));
  user: WritableSignal<User | null> = signal(null);

  constructor(private http: HttpClient, private router: Router) { }

  async login(identifier: string, password: string): Promise<void> {
    const res = await lastValueFrom(this.http.post<LoginResponseDTO>(environment.apiUrl + `/auth/local`, { identifier, password }));
    localStorage.setItem("jwt", res.jwt);
    this.jwt.set(res.jwt);
    await this.fetchMe();
    if (this.user()?.role.id === RoleEnum.Basic || this.user()?.role.id === RoleEnum.READ) {
      void this.router.navigate(["pickup"]);
    } else {
      void this.router.navigate(["home"]);
    }
  }

  async changePassword(current: string, newPassword: string, confirmPassword: string): Promise<void> {
    const query = qs.stringify({
      populate: 'role',
    }, { encodeValuesOnly: true });
    const res = await lastValueFrom(this.http.post<LoginResponseDTO>(environment.apiUrl + `/auth/change-password?${query}`, {
      currentPassword: current, password: newPassword, passwordConfirmation: confirmPassword
    }));

    localStorage.setItem("jwt", res.jwt);
    this.jwt.set(res.jwt);
    this.user.set(res.user);
  }

  logout(): void {
    this.jwt.set(null);
    this.user.set(null);
    localStorage.removeItem("jwt");
    this.router.navigate(["login"]);
  }

  async fetchMe(): Promise<void> {
    if (this.jwt()) {
      try {
        const query = qs.stringify({
          populate: 'role',
        }, { encodeValuesOnly: true });
        this.user.set(await firstValueFrom(this.http.get<User>(environment.apiUrl + `/users/me?${query}`)));
      } catch (e) {
        console.error(e);
        this.logout();
      }
    }
  }
}
