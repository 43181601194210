import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {lastValueFrom} from "rxjs";
import * as FileSaver from 'file-saver';
import {format} from "date-fns";



@Injectable({
  providedIn: 'root'
})
export class ScheduleService {

  constructor(
    public http: HttpClient
  ) { }

  async getPickup(date: Date, locationId: number) {
    const response = await lastValueFrom(this.http.get(environment.apiUrl + `/generate-schedule/pick-up/${locationId}?date=${format(date, "yyyy-MM-dd")}`, { responseType: 'blob' }))
    const blob = new Blob([response]);
    FileSaver.saveAs(blob, `plan-${format(date, 'yy-MM-dd')}-${locationId}.pdf`);
  }
}
