import {Component, Input, OnInit} from '@angular/core';
import {FormArray, FormControl, FormGroup} from "@angular/forms";
import {GenderService} from "../gender/gender.service";
import {Gender} from "../gender/gender.model";
import {isAfter, subYears} from "date-fns";
import {SettingsService} from "../../../administration/settings.service";
import {AgeType, FamilyMember} from "./family-member.model";


@Component({
  selector: 'app-member-form',
  templateUrl: './member-form.component.html',
  styleUrls: ['./member-form.component.scss']
})
export class MemberFormComponent implements OnInit {

  @Input() memberForm!: FormGroup;

  ageTypeOptions: { label: string, value: AgeType }[] = [
    {label: "Kind", value: AgeType.CHILD},
    {label: "Erwachsener", value: AgeType.ADULT},
    {label: "Geburtsdatum", value: AgeType.DATE}
  ];

  get members() {
    return this.memberForm.get('members') as FormArray;
  }

  get childrenCount() {
    const childCount = this.members.controls.filter((mem) => mem.get('ageType')?.value === AgeType.CHILD).length;
    const dates: Date[] = this.members.controls.filter((mem) => mem.get('ageType')?.value === AgeType.DATE).map((mem) => mem.get('birthDate')?.value);
    dates.push(this.memberForm.get('applicant_birth_date')?.value);
    return childCount + dates.filter((date) => isAfter(new Date(date), subYears(new Date(), this.settingsService.settings()!.adultAge))).length
  }

  get adultCount() {
    return this.members.length - this.childrenCount + 1;
  }

  constructor(
    public genderService: GenderService,
    public settingsService: SettingsService
  ) {}

  ngOnInit(): void {
    this.members.valueChanges.subscribe((formArrayValues: FamilyMember[]) => {
      formArrayValues.forEach((formGroupValue: FamilyMember, index: number) => {
        const ageType = formGroupValue.ageType;
        if (ageType === AgeType.DATE) {
          this.members.controls[index].get('birthDate')?.enable({emitEvent: false});
        } else {
          this.members.controls[index].get('birthDate')?.disable({emitEvent: false});
        }
      });
    });
  }

  getBoxCount() {
    if (this.adultCount < 3) {
      return 1;
    } else if (this.adultCount < 5) {
      return 2;
    } else {
      return 3;
    }
  }

  getCalculatedPrice() {
    return this.settingsService.settings()!.singleBoxPrice + (this.getBoxCount() - 1) * (this.settingsService.settings()!.extraBoxPrice);
  }

  addMember(): void {
    this.members.push(new FormGroup({
      id: new FormControl(),
      gender: new FormControl<Gender | undefined>(undefined),
      birthDate: new FormControl<Date | undefined>(undefined),
      ageType: new FormControl<AgeType>(AgeType.ADULT),
    }));
  }

  removeMember(index: number): void {
    this.members.removeAt(index);
  }

  protected readonly AgeType = AgeType;
}
