import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {ContactType} from "./comment.model";
import {CommentService} from "./comment.service";
import {ConfirmationService, MessageService} from "primeng/api";

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss'],
  providers: [ConfirmationService]
})
export class CommentComponent implements OnInit {

  commentForm = new FormGroup({
    contact_type: new FormControl<ContactType | null>(null),
    comment: new FormControl('')
  });
  @Input() customerId!: number;
  loading = true;

  constructor(
    public commentService: CommentService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
  ) {
  }

  async ngOnInit(): Promise<void> {
    await this.commentService.fetchComments(this.customerId);
    this.loading = false;
  }

  async save(): Promise<void> {
    const {contact_type, comment} = this.commentForm.value;
    if (contact_type && comment) {
      try {
        await this.commentService.createComment(contact_type, comment, this.customerId);
        this.messageService.add({severity: 'success', summary: 'Notiz gespeichert'});
        this.commentForm.reset();
        this.loading = true;
        await this.commentService.fetchComments(this.customerId);
        this.loading = false;
      } catch (e) {
        console.error(e);
        this.messageService.add({severity: 'error', summary: 'Fehler beim Speichern der Notiz'});
      }
    }
  }

  async delete(id: string, event: Event): Promise<void> {
    this.confirmationService.confirm({
      target: event.target ? event.target : undefined,
      message: 'Möchtest du diese Notiz wirklich löschen?',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: "JA",
      rejectLabel: "NEIN",
      accept: async () => {
        try {
          await this.commentService.deleteComment(id);
          this.messageService.add({severity: 'success', summary: 'Notiz gelöscht'});
          this.loading = true;
          await this.commentService.fetchComments(this.customerId);
          this.loading = false;
        } catch (e) {
          console.error(e);
          this.messageService.add({severity: 'error', summary: 'Fehler beim Löschen der Notiz'});
        }
      }
    });


  }
}
