<p-card>
  <p-tabView>
    <p-tabPanel header="Gesamt">
      <app-pickup-admin-location></app-pickup-admin-location>
    </p-tabPanel>

    @for(location of locationService.locations; track location.id) {
      <p-tabPanel [header]="location.city">
        <app-pickup-admin-location [locationId]="location.id"></app-pickup-admin-location>
      </p-tabPanel>
    }

  </p-tabView>
</p-card>
