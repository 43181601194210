<div class="container mt-3">
  <form [formGroup]="mainForm">
    <div class="grid">
      <div class="field col-12 md:col-4">
        <span class="p-float-label">
          <p-dropdown styleClass="w-100" [options]="genderService.genders" formControlName="title" optionLabel="title"
                      inputId="title" [autoDisplayFirst]="false" optionValue="id" [showClear]="true"></p-dropdown>
          <label for="title">Anrede</label>
        </span>

        @if (mainForm.get('title')?.invalid && mainForm.get('title')?.dirty) {
          <span class='p-error'>
            Anrede ist erforderlich.
          </span>
        }
      </div>
      <div class="md:col-8"></div>

      <div class="field col-12 md:col-6">
        <span class="p-float-label">
          <input class="w-100" id="first_name" type="text" formControlName="first_name" pInputText/>
          <label for="first_name">Vorname</label>
        </span>

        @if (mainForm.get('first_name')?.errors?.['required'] && mainForm.get('first_name')?.dirty) {
          <span class='p-error'
          >
            Vorname ist erforderlich.
          </span>
        }
      </div>
      <div class="field col-12 md:col-6">
        <span class="p-float-label">
          <input id="last_name" class="w-100" type="text" formControlName="last_name" pInputText/>
          <label for="last_name">Nachname</label>
        </span>

        @if (mainForm.get('last_name')?.errors?.['required'] && mainForm.get('last_name')?.dirty) {
          <span class='p-error'
          >
            Nachname ist erforderlich.
          </span>
        }

      </div>

      <div class="field col-12 md:col-8">
        <span class="p-float-label">
          <p-autoComplete [suggestions]="streetSuggestions" (completeMethod)="searchStreets($event)" [minLength]="1"
                          (onSelect)="onStreetSelected($event.value)" [dropdown]="true" [multiple]="false"
                          [forceSelection]="false" styleClass="w-100"
                          formControlName="street"></p-autoComplete>
          <label for="street">Straße</label>
        </span>

        @if (mainForm.get('street')?.errors?.['required'] && mainForm.get('street')?.dirty) {
          <span class='p-error'>
            Straße ist erforderlich.
          </span>
        }
      </div>
      <div class="field col-12 md:col-4">
        <span class="p-float-label">
          <input id="house_number" class="w-100" type="text" formControlName="house_number" pInputText/>
          <label for="house_number">Nr.</label>
        </span>

        @if (mainForm.get('house_number')?.errors?.['required'] && mainForm.get('house_number')?.dirty) {
          <span class='p-error'
          >
            Nr. ist erforderlich.
          </span>
        }

        @if (mainForm.get('house_number')?.errors?.['pattern'] && mainForm.get('house_number')?.dirty) {
          <span class='p-error'
          >
            Nr. ist falsch formatiert.
          </span>
        }
      </div>
      <div class="field col-12">
        <span class="p-float-label">
          <input id="address_addition" class="w-100" type="text" formControlName="address_addition" pInputText/>
          <label for="address_addition">Adresszusatz</label>
        </span>
      </div>

      <div class="field col-4">
        <span class="p-float-label">
          <input id="zip" class="w-100" type="text" formControlName="zip" pInputText/>
          <label for="zip">PLZ</label>
        </span>

        @if (mainForm.get('zip')?.errors?.['required'] && mainForm.get('zip')?.dirty) {
          <span class='p-error'>
            PLZ ist erforderlich.
          </span>
        }

        @if (mainForm.get('zip')?.errors?.['pattern'] && mainForm.get('zip')?.dirty) {
          <span class='p-error'>
            PLZ ist falsch formatiert.
          </span>
        }
      </div>

      <div class="field col-8">
        <span class="p-float-label">
          <input id="city" class="w-100" type="text" formControlName="city" pInputText/>
          <label for="city">Stadt</label>
        </span>

        @if (mainForm.get('city')?.errors?.['required'] && mainForm.get('city')?.dirty) {
          <span class='p-error'>
            Stadt ist erforderlich.
          </span>
        }
      </div>

      <div class="field col-12">
        <span class="p-float-label">
          <p-dropdown inputId="location" styleClass="w-100" [options]="locationService.locations" optionValue="id"
                      formControlName="future_location" optionLabel="city" [autoDisplayFirst]="false"></p-dropdown>
          <label [for]="'location'">Künftiger Ausgabeort</label>
        </span>
        @if (mainForm.get('future_location')?.invalid && mainForm.get('future_location')?.dirty) {
          <span class='p-error'>
            Künftiger Ausgabeort ist erforderlich.
          </span>
        }
      </div>

      <div class="field col-12">
        <span class="p-float-label">
          <input id="phone" class="w-100" type="text" formControlName="phone" pInputText/>
          <label for="phone">Telefon</label>
        </span>

        @if (mainForm.get('phone')?.errors?.['pattern'] && mainForm.get('phone')?.dirty) {
          <span class='p-error'>
            Telefonnr. ist falsch formatiert.
          </span>
        }
      </div>

      <div class="field col-12">
        <span class="p-float-label">
          <input id="mobile" class="w-100" type="text" formControlName="mobile" pInputText/>
          <label for="mobile">Handy</label>
        </span>

        @if (mainForm.get('mobile')?.errors?.['pattern'] && mainForm.get('mobile')?.dirty) {
          <span class='p-error'>
            Handy-Nr. ist falsch formatiert.
          </span>
        }
      </div>

      <div class="field col-12">
        <span class="p-float-label">
          <input id="mail" class="w-100" type="text" formControlName="mail" pInputText/>
          <label for="mail">Mail</label>
        </span>

        @if (mainForm.get('mail')?.errors?.['email'] && mainForm.get('mail')?.dirty) {
          <span class='p-error'>
            E-Mail ist ungültig.
          </span>
        }
      </div>

      <div class="field col-12">
        <span class="p-float-label">
          <textarea id="comment" class="w-100" type="text" formControlName="comment" pInputTextarea></textarea>
          <label for="comment">Bemerkung</label>
        </span>
      </div>


      <div class="col-12">
        <h2>Betreuer / Dolmetscher</h2>
      </div>
      <div class="col-12">
        <div class="flex justify-content-start">
          <button class="flex-grow-0" pButton (click)="addSupervisor()">HINZUFÜGEN</button>
        </div>
      </div>

      <div class="w-100">
        <div class="p-fluid grid mt-3" formArrayName="supervisors">
        @for (supervisor of supervisors.controls; track supervisor; let i = $index) {
          <ng-container [formGroupName]="i">
            <div class="field">
              <input type="hidden" formControlName="id">
            </div>
            <div class="field col-12 md:col-5">
              <span class="p-float-label">
                <input class="w-100" id="name" type="text" formControlName="name" pInputText/>
                <label for="name">Name</label>
              </span>
            </div>
            <div class="field col-12 md:col-5">
              <span class="p-float-label">
                <input id="contact" class="w-100" type="text" formControlName="contact" pInputText/>
                <label for="contact">Kontakt</label>
              </span>
            </div>
            <div class="col-12 md:col-2">
              <button (click)="removeSupervisor(i)" pButton pRipple type="button" icon="pi pi-times"
                      class="p-button-rounded p-button-danger p-button-outlined"></button>
            </div>
          </ng-container>
        }
      </div>
      </div>

    </div>

  </form>
</div>
