<div class="grid">

  <div class="col-4">
    <p-card header="Status" styleClass="h-100">
      <p-chart type="pie" [data]="statsService.statusData" [options]="{}"></p-chart>
    </p-card>
  </div>
  <div class="col-8">
    <p-card header="Standorte">
      <p-chart type="bar" [data]="statsService.locationData" [options]="{ plugins: {legend: { display: false }}}"></p-chart>
    </p-card>

  </div>
  <div class="col-4">
    <p-card header="Wochentage" styleClass="h-100">
      <p-chart type="bar" [data]="statsService.weekdayData" [options]="{ plugins: {legend: { display: false }}}"></p-chart>
    </p-card>
  </div>
  <div class="col-4">
    <p-card header="Nationalitäten" styleClass="h-100">
      <p-chart type="pie" [data]="statsService.nationalitiesData"></p-chart>
    </p-card>
  </div>
  <div class="col-4">
    <p-card pTooltip="Verteilung aller Abwesenheiten der letzten 12 Monate" header="Abwesenheiten" styleClass="h-100">
      <p-chart type="pie" [data]="statsService.absencesData" [options]="{}"></p-chart>
    </p-card>
  </div>
  <div class="col-12">
    <p-card header="Historie">
      <div class="d-flex justify-content-center">
        <p-selectButton [options]="dateOptions" [(ngModel)]="selectedDate" optionLabel="label" optionValue="value"
                      (onChange)="statsService.configureStatInterval(selectedDate)"></p-selectButton>
      </div>
    </p-card>
  </div>
  <div class="col-12">
    <p-card header="Einkommen und Kisten">
      <p-chart type="line" [data]="statsService.historicIncomeData" [options]="{scales: {y: {position: 'left'}, y1: {position: 'right'}}}"></p-chart>
    </p-card>
  </div>
  <div class="col-12">
    <p-card header="Status Verteilung">
      <p-chart type="bar" [data]="statsService.historicStatusDistribution" [options]="{scales: {y: {stacked: true}, x: {stacked: true}}}"></p-chart>
    </p-card>
  </div>
  <div class="col-12">
    <p-card header="Abwesenheiten">
      <p-chart type="line" [data]="statsService.historicAbsences" [options]="{ plugins: {legend: { display: false }}}"></p-chart>
    </p-card>
  </div>
  <div class="col-12">
    <p-card header="Erwachsene und Kinder im aktiven Bezug">
      <p-chart type="bar" [data]="statsService.historicPeopleProvided" [options]="{scales: {y: {stacked: true}, x: {stacked: true}}}"></p-chart>
    </p-card>
  </div>
</div>

