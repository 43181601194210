import { Injectable } from '@angular/core';
import {LoadingState} from "../../shared/loading-state.enum";
import {HttpClient} from "@angular/common/http";
import {firstValueFrom} from "rxjs";
import {ResponseModel, SingleResponseModel} from "../../shared/response.model";
import {environment} from "../../../environments/environment";
import {StatusChangeReason} from "./status-change-reason.model";
import {MessageService} from "primeng/api";

@Injectable({
  providedIn: 'root'
})
export class StatusChangeReasonService {
  loadingState = LoadingState.Loading;
  reasons: StatusChangeReason[] = [];
  constructor(
    private http: HttpClient,
    private messageService: MessageService
  ) {
    void this.fetchReasons();
  }

  async fetchReasons(): Promise<void> {
    try {
      const response = await firstValueFrom(this.http.get<ResponseModel<StatusChangeReason>>(environment.apiUrl + "/status-change-reasons"));
      this.reasons = response.data;
      this.loadingState = LoadingState.Data;
    } catch (e) {
      console.error(e);
      this.loadingState = LoadingState.Error;
      this.reasons = [];
    }
  }

  async deleteStatusChangeReason(id: number): Promise<StatusChangeReason | void> {
    try {
      const response = await firstValueFrom(this.http.delete<SingleResponseModel<StatusChangeReason>>(environment.apiUrl + `/status-change-reasons/${id}`));
      this.messageService.add({
        severity: 'success',
        summary: 'Grund gelöscht',
        detail: `${response?.data.title} wurde gelöscht.`
      });
      return response?.data;
    } catch (e) {
      console.error(e);
      this.messageService.add({
        severity: 'error',
        summary: 'Fehler',
        detail: `Grund konnte nicht gelöscht werden.`
      });
    } finally {
      await this.fetchReasons();
    }
  }

  async updateStatusChangeReason(type: StatusChangeReason): Promise<StatusChangeReason | void> {
    try {
      const response = await firstValueFrom(this.http.put<SingleResponseModel<StatusChangeReason>>(environment.apiUrl + `/status-change-reasons/${type.id}`, {data: type}));
      this.messageService.add({
        severity: 'success',
        summary: 'Grund aktualisiert',
        detail: `${response?.data.title} wurde aktualisiert.`
      });
      return response?.data;
    } catch (e) {
      console.error(e);
      this.messageService.add({
        severity: 'error',
        summary: 'Fehler',
        detail: `Grund konnte nicht geändert werden.`
      });
    } finally {
      await this.fetchReasons();
    }

  }

  async createStatusChangeReason(title: string): Promise<StatusChangeReason | void> {
    try {
      const response = await firstValueFrom(this.http.post<SingleResponseModel<StatusChangeReason>>(environment.apiUrl + `/status-change-reasons`, {data: {title}}));
      this.messageService.add({
        severity: 'success',
        summary: 'Grund erstellt',
        detail: `${response?.data.title} wurde erstellt.`
      });
      return response?.data;
    } catch (e) {
      console.error(e);
      this.messageService.add({
        severity: 'error',
        summary: 'Fehler',
        detail: `Grund konnte nicht erstellt werden.`
      });
    } finally {
      await this.fetchReasons();
    }
  }
}
