<p-card>
  <p-table [value]="customers()" [(selection)]="selectedCustomers" [lazy]="true" (onLazyLoad)="loadCustomers($event)"
    [paginator]="true" [rows]="25" [showCurrentPageReport]="true" [totalRecords]="totalCustomers()"
    [alwaysShowPaginator]="false" [loading]="loading()" dataKey="id" [rowsPerPageOptions]="[25, 50, 100]"
    currentPageReportTemplate="Zeige {first} bis {last} von {totalRecords} Nutzern">
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 4rem"><p-tableHeaderCheckbox /></th>
        <th pSortableColumn="first_name">Vorname <p-sortIcon field="first_name"></p-sortIcon></th>
        <th pSortableColumn="last_name">Nachname <p-sortIcon field="last_name"></p-sortIcon></th>
        <th>Status</th>
        <th>Ausgabeort</th>
        <th>Anmeldedatum</th>
        <th>Haushalt</th>
        <th>Kommentar</th>
        <th pSortableColumn="consultation_date">Termin <p-sortIcon field="consultation_date"></p-sortIcon></th>
        <th style="min-width: 140px;"></th>
      </tr>
      <tr>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
        <th>
          <p-columnFilter field="future_location" matchMode="in" [showMenu]="false">
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-multiSelect appendTo="body" [ngModel]="value" [options]="locationService.locations"
                (onChange)="filter($event.value)" [filter]="false" optionLabel="city"
                placeholder="Alle"></p-multiSelect>
            </ng-template>
          </p-columnFilter>
        </th>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-customer let-editing="editing">
      <tr>
        <td>
          <p-tableCheckbox [value]="customer" />
        </td>
        <td>{{customer.first_name}}</td>
        <td>{{customer.last_name}}</td>
        <td>
          <span class="status-badge status-{{customer.status}}">{{getStatusLabel(customer.status)}}</span>
        </td>
        <td>{{customer.future_location?.city}}</td>
        <td>{{getRegistrationDate(customer) | date : 'dd.MM.yyyy' }}</td>
        <td>{{customer.family_members | household | async}}</td>
        <td>{{customer.comment}}</td>
        <td pEditableColumn="customer.consultationDate" pEditableColumnField="consultationDate">
          <p-cellEditor>
            <ng-template pTemplate="input">
              <p-calendar (keydown.arrowLeft)="$event.stopPropagation()" (keydown.arrowRight)="$event.stopPropagation()"
                (keydown.enter)="updateCustomerDate(customer)" (onBlur)="updateCustomerDate(customer)" [showTime]="true"
                appendTo="body" [(ngModel)]="customer.consultationDate" />
            </ng-template>
            <ng-template pTemplate="output">
              {{customer.consultationDate | date : 'dd.MM.yyyy HH:mm'}}
            </ng-template>
          </p-cellEditor>
        </td>
        <td><button pButton class="p-button-rounded p-button-outlined" icon="pi pi-external-link"
            (click)="router.navigate(['customers', 'edit', customer.id])"></button> </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="9">Keine Nutzer gefunden</td>
      </tr>
    </ng-template>
  </p-table>
  <div class="d-flex justify-content-end align-items-center mt-3">
    <button [disabled]="xlsxLoading()" class="p-link text-primary mr-3 mb-3" (click)="exportXLSX()">
      @if (xlsxLoading()) {
      <i class="pi pi-spinner"></i>
      } @else {
      <i class="pi pi-download"></i>
      }
      Excel-Export
    </button>
  </div>
</p-card>
<p-card header="Termine" styleClass="mt-2">
  <full-calendar [options]="calendarOptions()"></full-calendar>
</p-card>