import {Component, DestroyRef, inject, Input, OnInit} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {LocationService} from "../../../administration/location/location.service";
import {StatusChangeReasonService} from "../../../administration/status-change-reason/status-change-reason.service";
import {Status, statuses} from "./status.model";
import {addDays, addYears} from "date-fns";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {SettingsService} from "../../../administration/settings.service";

@Component({
  selector: 'app-status-form',
  templateUrl: './status-form.component.html',
  styleUrls: ['./status-form.component.scss']
})
export class StatusFormComponent implements OnInit {

  @Input() statusForm!: FormGroup;
  tomorrow = addDays(new Date(), 1);
  protected readonly statuses = statuses;
  protected readonly Status = Status;
  destroyRef = inject(DestroyRef)

  constructor(
    public locationService: LocationService,
    public statusChangeReasonService: StatusChangeReasonService,
    public settingsService: SettingsService,
  ) { }

  ngOnInit() {
    this.statusForm.get('unpaid_balance')?.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.statusForm.get('unpaid_balance_date')?.setValue(new Date());
    });

    this.statusForm.get('status')?.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((status) => {
      if (status === Status.ACTIVE && this.settingsService.settings()?.expirationActivated) {
        this.statusForm.get('expiration_date')?.setValue(addYears(new Date(), this.settingsService.settings()!.expirationDuration));
      }
      if (status !== Status.ACTIVE) {
        this.statusForm.controls['codeForm'].reset();
      }
    });
  }

  cancelStatusChange() {
    this.statusForm.controls['status_change_date'].setValue(undefined);
    this.statusForm.controls['status_change_target'].setValue(undefined);
    this.statusForm.get('futureCodeForm')?.get('code')?.setValue(undefined);
    this.statusForm.get('futureCodeForm')?.get('rotation')?.setValue(undefined);
    this.statusForm.get('futureCodeForm')?.get('location')?.setValue(undefined);
    this.statusForm.get('futureCodeForm')?.get('weekday')?.setValue(undefined);
    this.statusForm.get('futureCodeForm')?.get('time')?.setValue(undefined);
    this.statusForm.get('futureCodeForm')?.get('codeValue')?.setValue(undefined);
    this.statusForm.controls['status_change_comment'].setValue(undefined);
    this.statusForm.controls['status_change_reason'].setValue(undefined);
  }
}
