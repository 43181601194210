import {Pipe, PipeTransform} from '@angular/core';
import {AgeType, FamilyMember} from "../../customer-overview/add-edit-customer/member-form/family-member.model";
import {isAfter, subYears} from "date-fns";
import {SettingsService} from "../../administration/settings.service";

@Pipe({
  name: 'household',
  standalone: true,
})
export class HouseholdPipe implements PipeTransform {

  constructor(
    private settingsService: SettingsService
  ) {
  }

  async transform(value: FamilyMember[], ...args: unknown[]): Promise<string> {
    if (!this.settingsService.settings()) {
      await this.settingsService.fetchSettings();
    }
    const childCountWithoutDate = value.filter(member => member.ageType === AgeType.CHILD).length;
    const adultCountWithoutDate = value.filter(member => member.ageType === AgeType.ADULT).length;

    const adultCount = adultCountWithoutDate + value.filter(member => member.birthDate && isAfter(subYears(new Date(), this.settingsService.settings()!.adultAge), new Date(member.birthDate))).length;
    const childCount = childCountWithoutDate + value.filter(member => member.birthDate && !isAfter(subYears(new Date(), this.settingsService.settings()!.adultAge), new Date(member.birthDate))).length;
    return `${adultCount} E ${childCount > 0 ? childCount + "K" : ""}`
  }

}
