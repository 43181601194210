import { Component } from '@angular/core';
import {PrimeNGConfig} from "primeng/api";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor(private config: PrimeNGConfig) {
    this.config.setTranslation({
      startsWith: 'Startet mit',
      contains: "Beinhaltet",
      notContains: "Beinhlatet nicht",
      endsWith: "Endet mit",
      equals: "Ist gleich",
      notEquals: "Ist nicht gleich",
      noFilter: "Kein Filter",
      lt: "Kleiner als",
      lte: "Kleiner als oder gleich",
      gt: "Größer als",
      gte: "Größer als oder gleich",
      is: "Ist",
      isNot: "Ist nicht",
      before: "Vor",
      after: "Nach",
      dateIs: "Datum ist",
      dateIsNot: "Datum ist nicht",
      dateBefore: "Datum ist bevor",
      dateAfter: "Datum ist nach",
      clear: "Leeren",
      apply: "Anwenden",
      matchAll: "Alle anwenden",
      matchAny: "Eins anwenden",
      addRule: "Regel hinzufügen",
      removeRule: "Regel entfernen",
      accept: "Akzeptieren",
      reject: "Ablehnen",
      choose: "Wählen",
      upload: "Hochladen",
      cancel: "Abbrechen",
      fileSizeTypes: ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      dayNames: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
      dayNamesShort: ['Son', 'Mon', 'Die', 'Mit', 'Don', 'Fre', 'Sam'],
      dayNamesMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
      monthNames: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
      monthNamesShort: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
      dateFormat: "dd.mm.yy",
      firstDayOfWeek: 1,
      today: "Heute",
      weekHeader: "Wo",
      weak: "Schwach",
      medium: "Mittel",
      strong: "Stark",
      passwordPrompt: "Gib ein Passwort ein",
      emptyMessage: "Keine verfügbaren Optionen",
      emptyFilterMessage: "Keine Ergebnisse gefunden",
      pending: "Lädt",
      chooseYear: "Wähle Jahr",
      chooseMonth: "Wähle Monat",
      chooseDate: "Wähle Datum",
      prevDecade: "Vorheriges Jahrzehnt",
      nextDecade: "Nächstes Jahrzehnt",
      prevYear: "Vorheriges Jahr",
      nextYear: "Nächstes Jahr",
      prevMonth: "Vorheriger Monat",
      nextMonth: "Nächster Monat",
      prevHour: "Vorherige Stunde",
      nextHour: "Nächste Stunde",
      prevMinute: "Vorherige Minute",
      nextMinute: "Nächste Minute",
      prevSecond: "Vorherige Sekunde",
      nextSecond: "Nächste Sekunde",
      am: "AM",
      pm: "PM",
      searchMessage: "{0} Ergebnisse verfügbar",
      selectionMessage: "{0} Ergebnisse ausgewählt",
      emptySelectionMessage: "Kein ausgewähltes Item",
      emptySearchMessage: "Kein Ergebnis gefunden",
    })
  }
}
