import {inject, Injectable, signal, WritableSignal} from '@angular/core';
import {Socket, connect} from "socket.io-client";
import {AuthService} from "../login/auth.service";
import {environment} from "../../environments/environment";
import {firstValueFrom} from "rxjs";
import {ResponseModel, SingleResponseModel} from "../shared/response.model";
import {Pickup} from "./pickup.model";
import {HttpClient} from "@angular/common/http";
import {Customer} from "../customer-overview/customer.model";
import * as qs from "qs";
import {format} from "date-fns";


@Injectable({
  providedIn: 'root'
})
export class PickUpService {
  public socket: WritableSignal<Socket> = signal(connect(environment.baseUrl, {
    extraHeaders: {
      Authorization: this.authService.jwt()!
    }
  }));
  private http = inject(HttpClient);

  constructor(
    private authService: AuthService,
  ) {}

  getPickups(params: unknown): Promise<ResponseModel<Pickup>> {
    return firstValueFrom(this.http.get<ResponseModel<Pickup>>(environment.apiUrl + `/pickups?${qs.stringify(params, {encodeValuesOnly: true})}`));
  }

  async getTodayCustomers(locationId: number): Promise<Customer[]> {
    return await firstValueFrom(this.http.get<Customer[]>(environment.apiUrl + `/pickups/today/${locationId}`));
  }

  async getTodayCompletedCustomers(locationId: number): Promise<Customer[]> {
    return await firstValueFrom(this.http.get<Customer[]>(environment.apiUrl + `/pickups/completed/${locationId}`));
  }

  async getCustomersAtCooling(locationId: number): Promise<Customer[]> {
    return firstValueFrom(this.http.get<Customer[]>(environment.apiUrl + `/pickups/cooling/${locationId}`));
  }

  async updatePickup(pickup: Partial<Pickup>): Promise<Pickup> {
    const response = await firstValueFrom(this.http.put<SingleResponseModel<Pickup>>(environment.apiUrl + `/pickups/${pickup.id}`, {data: pickup}));
    return response.data;
  }

  getPickupStats(date: Date, locationId: number | undefined): Promise<{count: number, income: number, newDebt: number, boxes: number}> {
    return firstValueFrom(this.http.get<{count: number, income: number, newDebt: number, boxes: number}>(environment.apiUrl + `/pickups/stats/stats?locationId=${locationId}&date=${format(date, 'yyyy-MM-dd')}`));
  }

}
