import { Component, Input } from '@angular/core';
import { User } from '../../administration/users/user.model';
import { environment } from "../../../environments/environment";

@Component({
  selector: 'app-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss']
})
export class UserAvatarComponent {
  environment = environment;

  @Input() user?: User;
  @Input() size: 'normal' | 'large' | 'xlarge' = 'large';

  hasAvatar(): boolean {
    if (!this.user) return false;
    return this.user.avatar_blob?.blob != null;
  }

  avatarUrl(): string {
    if (!this.user) return '';
    if (!this.user.avatar_blob?.blob) return '';

    return this.user.avatar_blob.blob;
  }
}
