import {Component, inject} from '@angular/core';
import {TagModule} from "primeng/tag";
import {CardModule} from "primeng/card";
import {TabViewModule} from "primeng/tabview";
import {PickupAdminLocationComponent} from "./pickup-admin-location/pickup-admin-location.component";
import {LocationService} from "../administration/location/location.service";

@Component({
  selector: 'app-pickup-admin',
  standalone: true,
  imports: [
    TagModule,
    CardModule,
    TabViewModule,
    PickupAdminLocationComponent
  ],
  templateUrl: './pickup-admin.component.html',
  styleUrl: './pickup-admin.component.scss'
})
export class PickupAdminComponent {
  locationService = inject(LocationService)
}
