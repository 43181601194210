import { Component, signal, WritableSignal } from '@angular/core';
import { CustomerService } from "../customer-overview/customer.service";
import { addMonths, format } from "date-fns";
import { Customer } from "../customer-overview/customer.model";
import { Router } from "@angular/router";
import { TableLazyLoadEvent } from "primeng/table";
import { Status } from "../customer-overview/add-edit-customer/status-form/status.model";
import { MessageService } from "primeng/api";

@Component({
  selector: 'app-certificate',
  templateUrl: './certificate.component.html',
  styleUrls: ['./certificate.component.scss']
})
export class CertificateComponent {

  lastTableLazyLoadEvent: TableLazyLoadEvent | undefined;
  minDate: Date | null = null;
  maxDate: Date = addMonths(new Date(), 1);
  customers: Customer[] = [];
  totalCustomers = 0;
  certificatesLoading = false;
  onlyNull = false;
  alsoFreeCodes = false;
  xlsxLoading: WritableSignal<boolean> = signal(false);

  constructor(
    public customerService: CustomerService,
    public router: Router,
    private messageService: MessageService,
  ) { }

  async loadCustomers(event?: TableLazyLoadEvent, search?: string) {
    this.lastTableLazyLoadEvent = event;
    this.certificatesLoading = true;
    const result = await this.customerService.fetchCustomers({
      filters: this.getFilterQuery(search),
      fields: ['first_name', 'last_name', 'certificate_valid_until'],
      populate: {
        code: {
          populate: 'location'
        },
        code_rotation_1: {
          populate: 'location'
        },
        code_rotation_2: {
          populate: 'location'
        }
      },
      pagination: event?.rows != null && event?.first != null ? {
        page: Math.floor(event.first / event.rows) + 1,
        pageSize: event.rows
      } : {},
      sort: event?.sortField && event?.sortOrder ? [event.sortField + (event.sortOrder < 0 ? ":desc" : ":asc")] : []
    });
    this.customers = result.data;
    this.totalCustomers = result.meta.pagination.total;
    this.certificatesLoading = false;
  }

  async exportXLSX() {
    try {
      this.xlsxLoading.set(true);
      await this.customerService.exportXLSX({
        filters: this.getFilterQuery(),
        sort: this.lastTableLazyLoadEvent?.sortField && this.lastTableLazyLoadEvent?.sortOrder ? [this.lastTableLazyLoadEvent.sortField + (this.lastTableLazyLoadEvent.sortOrder < 0 ? ":desc" : ":asc")] : []
      });
      this.messageService.add({
        severity: 'success',
        summary: 'Export',
        detail: `Daten wurden erfolgreich exportiert.`
      });
    } catch (err) {
      console.error(err);
      this.messageService.add({
        severity: 'error',
        summary: 'Fehler',
        detail: `Daten konnten nicht exportiert werden.`
      });
    } finally {
      this.xlsxLoading.set(false);
    }
  }

  getFilterQuery(search?: string): unknown {
    return {
      $and: [
        {
          $or: [
            {
              $and: !this.onlyNull ? [
                { certificate_valid_until: this.minDate ? { $gte: format(this.minDate, 'yyyy-MM-dd') } : {} },
                { certificate_valid_until: { $lte: format(this.maxDate, 'yyyy-MM-dd') } }
              ] : [],
            },
            { certificate_valid_until: { $null: true } }
          ],
        },
        {
          $or: search ? [
            { first_name: { $containsi: search } },
            { last_name: { $containsi: search } },
            { code: { value: { $containsi: search } } },
            { code_rotation_1: { value: { $containsi: search } } },
            { code_rotation_2: { value: { $containsi: search } } }
          ] : [],
        },
        {
          status: { $eq: Status.ACTIVE },
          $or: !this.alsoFreeCodes ? [
            { code: { isFreeCode: false } },
            { code_rotation_1: { isFreeCode: false } },
            { code_rotation_2: { isFreeCode: false } },
          ] : [],
        }
      ],
    }
  }
}
