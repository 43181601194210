import {Injectable} from '@angular/core';
import {LoadingState} from "../../shared/loading-state.enum";
import {HttpClient} from "@angular/common/http";
import {firstValueFrom} from "rxjs";
import {environment} from "../../../environments/environment";
import {Role, User} from "./user.model";
import * as qs from "qs";

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  loadingState = LoadingState.Loading;
  users: User[] = [];
  roles: Role[] = [];

  constructor(private http: HttpClient) {
    void this.fetchUsers();
    void this.fetchRoles();
  }

  async fetchUsers(): Promise<void> {
    try {
      const query = qs.stringify({
        populate: ['role', 'avatar']
      }, {encodeValuesOnly: true});
      this.users = await firstValueFrom(this.http.get<User[]>(environment.apiUrl + `/users?${query}`));
      this.loadingState = LoadingState.Data;
    } catch (e) {
      console.error(e);
      this.loadingState = LoadingState.Error;
      this.users = [];
    }
  }

  async fetchRoles(): Promise<void> {
    try {
      this.roles = (await firstValueFrom(this.http.get<{
        roles: Role[]
      }>(environment.apiUrl + "/users-permissions/roles")))?.roles;
    } catch (e) {
      console.error(e);
      this.roles = [];
    }
  }

  async createUser(user: {
    first_name: string,
    last_name: string,
    username: string,
    email: string,
    password: string,
    role: number
  }): Promise<User> {
    return await firstValueFrom(this.http.post<User>(environment.apiUrl + "/users", user));
  }

  async updateUser(user: {
    id: number,
    first_name?: string,
    last_name?: string,
    username: string,
    email: string,
    password?: string,
    role: number,
    avatar_blob?: { blob: string | null }
  }): Promise<User> {
    if (user.password?.length === 0) {
      delete user.password;
    }
    return await firstValueFrom(this.http.put<User>(environment.apiUrl + `/users/${user.id}`, user));
  }

  async deleteUser(id: number) {
    return await firstValueFrom(this.http.delete<User>(environment.apiUrl + `/users/${id}`));
  }
}
