import {Component, Input} from '@angular/core';
import {LocationService} from "../../administration/location/location.service";
import {FormControl, FormGroup} from "@angular/forms";
import {Code} from "../../code/code.model";
import {WeekdayService} from "../../administration/weekday/weekday.service";
import {Weekday} from "../../administration/weekday/weekday.model";
import {CodeService} from "../../code/code.service";
import {ConfirmationService} from "primeng/api";

@Component({
  selector: 'app-customer-no-inputs',
  templateUrl: './customer-no-inputs.component.html',
  styleUrls: ['./customer-no-inputs.component.scss']
})
export class CustomerNoInputsComponent {

  @Input() codeForm!: FormGroup<{
    location: FormControl<number | undefined>;
    codeValue: FormControl<number | undefined>;
    rotation: FormControl<number | undefined>;
    weekday: FormControl<number | undefined>;
    time: FormControl<Date | undefined>;
    code: FormControl<number | undefined>;
  }>;

  //Indicating wether this code input determines an instant code change or for a planned upcoming change
  @Input() upcoming = false;

  selectedCode: Code | undefined;
  checkingCode = false;
  free = true;
  codeDirty = false;

  constructor(
    public locationService: LocationService,
    public weekdayService: WeekdayService,
    private codeService: CodeService,
    private confirmationService: ConfirmationService,
  ) {}

  async lookupCode(): Promise<void> {
    this.free = false;
    this.selectedCode = undefined;
    this.codeForm.get('code')?.setValue(undefined);
    this.codeForm.get('time')?.setValue(undefined);
    this.codeForm.get('weekday')?.setValue(undefined);

    const code = this.codeForm.get('codeValue')?.value;
    const location = this.codeForm.get('location')?.value;
    const rotation = this.codeForm.get('rotation')?.value;
    if (!location || !code) {
      return;
    }
    this.codeDirty = true;
    this.checkingCode = true;
    this.selectedCode = await this.codeService.getCode(location, code);
    if (this.selectedCode && !this.selectedCode.customer) { // code not bound to a weekly customer
      if (
        (rotation == 1 && !this.selectedCode.customer_rotation_1) ||
        (rotation == 2 && !this.selectedCode.customer_rotation_2) ||
        (rotation == 0 && !this.selectedCode.customer_rotation_1 && !this.selectedCode.customer_rotation_2)
      ) {
        this.codeForm.get('code')?.setValue(this.selectedCode.id);
        this.codeForm.get('time')?.setValue(this.selectedCode.time ? new Date(new Date().setHours(Number(this.selectedCode.time.split(":")[0]), Number(this.selectedCode.time.split(":")[1]))) : undefined);
        this.codeForm.get('weekday')?.setValue((this.selectedCode.weekday as Weekday).id);
        this.free = true;
      }
    }

    this.checkingCode = false;
  }

  removeCode() {
    this.selectedCode = undefined;
    this.codeForm.get('code')?.setValue(undefined);
    this.codeForm.get('time')?.setValue(undefined);
    this.codeForm.get('weekday')?.setValue(undefined);
    this.codeForm.get('location')?.setValue(undefined);
    this.codeForm.get('codeValue')?.setValue(undefined);
    this.codeForm.get('rotation')?.setValue(undefined);
    this.free = false;
  }

  forceCodeUsage(event: Event): void {
    this.confirmationService.confirm({
      target: event.target ? event.target : undefined,
      message: `Wenn du diesen Code trotzdem zuweist, wird er dem anderen Nutzer ${this.upcoming ? 'zur eingestellten Zeit' : 'sofort'} automatisch entzogen`,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: "OKAY",
      rejectLabel: "ABBRECHEN",
      accept: () => {
        if (!this.selectedCode) {
          return;
        }
        this.codeForm.get('code')?.setValue(this.selectedCode.id);
        this.codeForm.get('time')?.setValue(this.selectedCode.time ? new Date(new Date().setHours(Number(this.selectedCode.time.split(":")[0]), Number(this.selectedCode.time.split(":")[1]))) : undefined);
        this.codeForm.get('weekday')?.setValue((this.selectedCode.weekday as Weekday).id);
        this.free = true;
      }
    });
  }
}
