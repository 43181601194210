import { Component, Input, OnInit } from '@angular/core';
import { CustomerEventService } from "./customer-event.service";
import { CustomerChangeset, CustomerEvent } from "./customer-event.model";
import { PaginatorState } from 'primeng/paginator';
import {format, parse} from "date-fns";
import {AbsenceService} from "../../../absences-overview/absence.service";



@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {

  events: CustomerEvent[] = [];
  @Input() customerId!: number;
  loading = true;
  totalEvents: number | undefined;

  first = 0;
  rows = 10;

  changesets: { [key: string]: CustomerChangeset } = {};
  changeSetsGroupedByDate: { [key: string]: CustomerChangeset[] } = {};
  eventDates: string[] = [];

  constructor(
    public customerEventService: CustomerEventService,
    public absenceService: AbsenceService,
  ) {
  }

  ngOnInit() {
    this.onPageChange({ page: 0, rows: 10 });
  }

  async onPageChange(event: PaginatorState) {
    this.loading = true;

    const response = await this.customerEventService.fetchEventsForCustomer(this.customerId, {
      pagination: {
        page: (event.page ?? 0) + 1,
        pageSize: event.rows
      }
    });

    this.events = response.data;
    this.totalEvents = response.meta.pagination.total;

    // Collect changesets by grouping events per minute
    this.changesets = {};
    this.eventDates = [];
    this.events.forEach((event) => {
      const date = this.customerEventService.isLegacyEvent(event) ? parse(event.comment.split("(")[1].substring(0, 15), "yyyy-MM-dd HH:mm", new Date()) : new Date(event.createdAt);
      const dateTimeKey = format(date, "dd.MM.yyyy HH:mm");

      if (!this.changesets[dateTimeKey]) {
        this.changesets[dateTimeKey] = {
          date: format(date, "dd.MM.yyyy"),
          time: format(date, "HH:mm"),
          events: [],
          user: event.users_permissions_user
        };
      }

      this.changesets[dateTimeKey].events.push(event);
    });

    // Sort changeset events by time
    for (const changeset of Object.values(this.changesets)) {
      changeset.events.sort((a, b) => {
        return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
      });
    }

    // Find and substitue special events
    for (const specialEvent of this.customerEventService.specialEvents) {
      for (const changeset of Object.values(this.changesets)) {
        const requiredEvents = specialEvent.requiredEvents;
        const requiredEventsFound = changeset.events.filter((event) => {
          return requiredEvents.find((requiredEvent) => {
            return requiredEvent.field === event.field && requiredEvent.field_action === event.field_action;
          });
        });

        if (requiredEventsFound.length === requiredEvents.length) {
          const description = specialEvent.formatDescription(requiredEventsFound);
          const firstEvent = requiredEventsFound[0];

          // Add special event
          changeset.events.push({
            ...firstEvent,
            description,
            action: 'update',
            field: specialEvent.name, // HACK: we abuse 'field' here to store the special event name
            field_action: 'update',
            old_value: '',
            new_value: '',
            comment: ''
          });

          // Remove required events
          changeset.events = changeset.events.filter((event) => {
            return !requiredEvents.find((requiredEvent) => {
              return requiredEvent.field === event.field && requiredEvent.field_action === event.field_action;
            });
          });
        }
      }
    }

    this.changeSetsGroupedByDate = {};
    Object.keys(this.changesets).forEach((dateKey) => {
      const changeset = this.changesets[dateKey];
      if (!this.changeSetsGroupedByDate[changeset.date]) {
        this.changeSetsGroupedByDate[changeset.date] = [];
      }
      this.changeSetsGroupedByDate[changeset.date].push(changeset);
    });

    this.eventDates = Object.keys(this.changeSetsGroupedByDate);
    this.loading = false;
  }
}

