export enum Status {
  REQUESTED = 'requested',
  QUEUED = 'queued',
  ACTIVE = 'active',
  BLOCKED = 'blocked',
  DROPPED_OUT = 'dropped_out',
}

export interface StatusModel<T> {
  status: T;
  title: string;
}

export const statuses: StatusModel<Status>[] = [
  { status: Status.REQUESTED, title: "Anfrageliste" },
  { status: Status.QUEUED, title: "Warteliste" },
  { status: Status.ACTIVE, title: "Bezug" },
  { status: Status.BLOCKED, title: "Blockiert" },
  { status: Status.DROPPED_OUT, title: "Ehemalige" },
]

export const statusColors = {
  requested: '#6edce5',
  queued: '#ffd8b2',
  active: '#c8e6c9',
  blocked: '#ffcdd2',
  dropped_out: '#e3c8cb'
}
