import { Component, inject, signal, WritableSignal } from '@angular/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import { CustomerService } from "../customer-overview/customer.service";
import { Customer } from "../customer-overview/customer.model";
import { TableLazyLoadEvent } from "primeng/table";
import {
  Status,
  statusColors,
  statuses,
} from "../customer-overview/add-edit-customer/status-form/status.model";
import { Router } from "@angular/router";
import { FilterMetadata, MessageService } from "primeng/api";
import { CalendarOptions } from "@fullcalendar/core";
import { endOfMonth, startOfMonth } from "date-fns";
import { LocationModel } from "../administration/location/location.model";
import { LocationService } from "../administration/location/location.service";

interface CustomerConsultation extends Customer {
  consultationDate: Date;
}

@Component({
  selector: 'app-consultation',
  templateUrl: './consultation.component.html',
  styleUrls: ['./consultation.component.scss']
})
export class ConsultationComponent {
  lastTableLazyLoadEvent: WritableSignal<TableLazyLoadEvent | undefined> = signal(undefined);
  customers: WritableSignal<CustomerConsultation[]> = signal([]);
  totalCustomers: WritableSignal<number> = signal(0);
  selectedCustomers: WritableSignal<CustomerConsultation[]> = signal([]);
  midDate: WritableSignal<Date | undefined> = signal(undefined);
  loading: WritableSignal<boolean> = signal(true);
  calendarOptions: WritableSignal<CalendarOptions> = signal({
    plugins: [dayGridPlugin],
    initialView: 'dayGridMonth',
    weekends: false,
    events: [],
    locale: 'de',
    buttonText: {
      today: "Heute"
    },
    datesSet: (event) => {
      this.midDate.set(new Date((event.start.getTime() + event.end.getTime()) / 2));
      void this.fetchCalendar(startOfMonth(this.midDate()!), endOfMonth(this.midDate()!));
    }
  });
  xlsxLoading: WritableSignal<boolean> = signal(false);

  customerService = inject(CustomerService);
  router = inject(Router);
  messageService = inject(MessageService);
  locationService = inject(LocationService);

  async fetchCalendar(start: Date, end: Date) {
    const response = await this.customerService.fetchCustomers({
      fields: ['id', 'first_name', 'last_name', 'status', 'consultation_date'],
      filters: {
        $and: [
          { consultation_date: { $gte: start.toISOString() } },
          { consultation_date: { $lte: end.toISOString() } },
        ]
      },
      pagination: {
        page: 1,
        pageSize: 100,
      }
    });

    this.calendarOptions.update((options) => {
      options.events = response.data.map(c => ({
        title: `${c.first_name} ${c.last_name}`,
        start: new Date(c.consultation_date),
        color: statusColors[c.status],
      }));
      return options;
    });
  }

  async loadCustomers(event?: TableLazyLoadEvent) {
    this.lastTableLazyLoadEvent.set(event);
    this.loading.set(true);
    const result = await this.customerService.fetchCustomers({
      filters: {
        status: Status.REQUESTED,
        future_location: (event?.filters?.['future_location'] as FilterMetadata)?.value ? {
          id: { $in: (event!.filters!['future_location'] as FilterMetadata).value.map((value: LocationModel) => value.id) || [] }
        } : {},
      },
      populate: ['future_location', 'family_members', 'customer_events'],
      pagination: event?.rows != null && event?.first != null ? {
        page: Math.floor(event.first / event.rows) + 1,
        pageSize: event.rows
      } : {},
      sort: event?.sortField && event?.sortOrder ? [event.sortField + (event.sortOrder < 0 ? ":desc" : ":asc")] : ['createdAt:asc']
    });

    const consulCus: CustomerConsultation[] = result.data.map(customer => {
      const cus = customer as CustomerConsultation;
      if (cus.consultation_date) {
        cus.consultationDate = new Date(cus.consultation_date);
      }
      return cus;
    });

    this.customers.set(consulCus);
    this.totalCustomers.set(result.meta.pagination.total);
    this.loading.set(false);
  }

  getRegistrationDate(customer: Customer) {
    for (const event of customer.customer_events) {
      if (event.description === 'Erstanmeldung: ') {
        return new Date(event.comment.match(/(\d{4}-\d{2}-\d{2})/)![0]);
      }
    }

    return customer.createdAt;
  }

  getStatusLabel(status: string): string {
    const stts = statuses.find(s => s.status == status);
    return stts?.title ?? "Unbekannt"
  }

  async updateCustomerDate(customer: CustomerConsultation): Promise<void> {
    if (customer.consultationDate?.toISOString() === customer.consultation_date) {
      return;
    }
    customer.consultation_date = customer.consultationDate?.toISOString() ?? null;
    try {
      await this.customerService.updateCustomer({ id: customer.id, consultation_date: customer.consultation_date });
      this.messageService.add({
        severity: 'success',
        summary: 'Nutzer bearbeitet',
        detail: `Beratungstermin wurde geändert.`
      });
    } catch (error) {
      console.error(error);
      this.messageService.add({
        severity: 'error',
        summary: 'Fehler',
        detail: `Beratungstermin konnte nicht aktualisiert werden`
      });
    } finally {
      void this.fetchCalendar(startOfMonth(this.midDate()!), endOfMonth(this.midDate()!));
    }
  }

  async exportXLSX() {
    try {
      this.xlsxLoading.set(true);
      if (this.selectedCustomers().length > 0) {
        await this.customerService.exportXLSX({
          filters: {
            id: { $in: this.selectedCustomers().map(c => c.id) }
          },
          sort: this.lastTableLazyLoadEvent()?.sortField && this.lastTableLazyLoadEvent()?.sortOrder ? [this.lastTableLazyLoadEvent()!.sortField + (this.lastTableLazyLoadEvent()!.sortOrder! < 0 ? ":desc" : ":asc")] : []
        });
      } else {
        await this.customerService.exportXLSX({
          filters: {
            status: Status.REQUESTED
          },
          sort: this.lastTableLazyLoadEvent()?.sortField && this.lastTableLazyLoadEvent()?.sortOrder ? [this.lastTableLazyLoadEvent()!.sortField + (this.lastTableLazyLoadEvent()!.sortOrder! < 0 ? ":desc" : ":asc")] : []
        });
      }

      this.messageService.add({
        severity: 'success',
        summary: 'Export',
        detail: `Daten wurden erfolgreich exportiert.`
      });
    } catch (err) {
      console.error(err);
      this.messageService.add({
        severity: 'error',
        summary: 'Fehler',
        detail: `Daten konnten nicht exportiert werden.`
      });
    } finally {
      this.xlsxLoading.set(false);
    }
  }
}
