<div class="container mt-3">
  <p-confirmPopup></p-confirmPopup>
  <form [formGroup]="absenceForm" (ngSubmit)="save()">
    <h5>Neue Fehlzeit</h5>
    <div class="p-fluid grid mt-3">
      <div class="field col-12 md:col-6">
        <span class="p-float-label">
          <p-calendar inputId="date" styleClass="w-100" dateFormat="dd.mm.yy"
                      formControlName="date"></p-calendar>
          <label for="date">Datum</label>
        </span>
      </div>

      <div class="field col-12 md:col-6">
        <span class="p-float-label">
          <p-dropdown styleClass="w-100" [options]="absenceStatuses" formControlName="absence_status" optionLabel="title"
                      inputId="absence_status" optionValue="status" [autoDisplayFirst]="false" [showClear]="true"></p-dropdown>
          <label for="absence_status">Status</label>
        </span>
      </div>

      <div class="field col-12">
        <span class="p-float-label">
          <textarea pInputTextarea id="comment" formControlName="comment"></textarea>
          <label for="comment">Bemerkung</label>
        </span>
      </div>
    </div>
    <div class="w-100 d-flex justify-content-end mb-3">
      <button type="submit" pButton label="FEHLZEIT SPEICHERN" icon="pi pi-save" class="p-button-rounded"></button>
    </div>
  </form>

  <p-table [value]="absenceService.absences" [loading]="loading">
    <ng-template pTemplate="header">
      <tr>
        <th>Datum</th>
        <th>Status</th>
        <th>Bemerkung</th>
        <th></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-absence>
      <tr>
        <td>{{absence.date | date : "dd.MM.yyyy"}}</td>
        <td>
          <p-dropdown appendTo="body" styleClass="w-100" [options]="absenceStatuses" optionLabel="title" [ngModel]="absence.absence_status"
                      inputId="absence_status" optionValue="status" [autoDisplayFirst]="false" (onChange)="update(absence, $event)"></p-dropdown>
        </td>
        <td>{{absence.comment}}</td>
        <td>
          <button pButton pRipple type="button" icon="pi pi-times" (click)="delete(absence.id, $event)" class="p-button-rounded p-button-danger p-button-outlined"></button>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="5">Keine Fehlzeiten</td>
      </tr>
    </ng-template>
  </p-table>



</div>
