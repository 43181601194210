<nav class="navbar navbar-expand-lg">
  <div class="container-fluid">
    <a class="navbar-brand" href="#">{{getTitle()}}</a>
    <button type="button" class="navbar-toggler shadow-none" data-bs-toggle="collapse" (click)="sidebarToggle()"
      data-bs-target="#navbarSupportedContent">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
      <ul class="nav navbar-nav">
        <li>
          <a (click)="authService.logout()">
            Log out
          </a>
        </li>
        <li class="separator hidden-lg hidden-md"></li>
      </ul>
    </div>
  </div>
</nav>
