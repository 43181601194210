import { Component } from '@angular/core';
import {LocationService} from "../administration/location/location.service";

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss']
})
export class ScheduleComponent {

  constructor(public locationService: LocationService) {
  }
}
