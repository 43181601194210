import {Component, Input} from '@angular/core';
import {FormArray, FormGroup} from "@angular/forms";
import {CharacteristicService} from "../../../administration/characteristic/characteristic.service";
import {IncomeTypeService} from "../../../administration/income-type/income-type.service";
import {FamilyStatusService} from "../../../administration/family-status/family-status.service";
import {NationalityService} from "../../../administration/nationality/nationality.service";

@Component({
  selector: 'app-internal-form',
  templateUrl: './internal-form.component.html',
  styleUrls: ['./internal-form.component.scss']
})
export class InternalFormComponent {

  @Input() internalForm!: FormGroup;

  get characteristicsFormArray() {
    return this.internalForm.get('characteristics') as FormArray;
  }

  constructor(
    public characteristicService: CharacteristicService,
    public incomeTypeService: IncomeTypeService,
    public familyStatusService: FamilyStatusService,
    public nationalityService: NationalityService,
  ) { }

}
