import { Pipe, PipeTransform } from '@angular/core';
import {Customer} from "../../customer-overview/customer.model";

@Pipe({
  name: 'code',
  standalone: true
})
export class CodePipe implements PipeTransform {

  transform(customer: Customer, ...args: unknown[]): string {
    const code = customer.code ?? customer.code_rotation_1 ?? customer.code_rotation_2;
    const base = `${code.location.abbreviation} ${code.value}`;
    if (customer.code_rotation_1) {
      return `${base} - 1`;
    } else if (customer.code_rotation_2) {
      return `${base} - 2`;
    } else {
      return base;
    }
  }

}
