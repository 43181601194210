import { Injectable } from '@angular/core';
import {LoadingState} from "../../../shared/loading-state.enum";
import {HttpClient} from "@angular/common/http";
import {firstValueFrom} from "rxjs";
import {ResponseModel, SingleResponseModel} from "../../../shared/response.model";
import {environment} from "../../../../environments/environment";
import {ContactType} from "./comment.model";
import * as qs from "qs";
import {format} from "date-fns";
import {AuthService} from "../../../login/auth.service";
import {MessageService} from "primeng/api";

@Injectable({
  providedIn: 'root'
})
export class CommentService {
  loadingState = LoadingState.Loading;
  contactTypes: ContactType[] = [];
  comments: Comment[] = [];
  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private messageService: MessageService,
  ) {
    void this.fetchContactTypes();
  }

  async fetchContactTypes(): Promise<void> {
    try {
      const response = await firstValueFrom(this.http.get<ResponseModel<ContactType>>(environment.apiUrl + "/contact-types"));
      this.contactTypes = response.data;
      this.loadingState = LoadingState.Data;
    } catch (e) {
      console.error(e);
      this.loadingState = LoadingState.Error;
      this.contactTypes = [];
    }
  }

  async fetchComments(customerId: number): Promise<void> {
    const query = qs.stringify({
      filters: {
        customer: {
          id: {
            $eq: customerId
          }
        }
      },
      populate: {
        users_permissions_user: true,
        contact_type: true
      }
    }, {encodeValuesOnly: true});
    try {
      const response = await firstValueFrom(this.http.get<ResponseModel<Comment>>(environment.apiUrl + `/comments?${query}`));
      this.comments = response.data;
      this.loadingState = LoadingState.Data;
    } catch (e) {
      console.error(e);
      this.loadingState = LoadingState.Error;
      this.contactTypes = [];
    }
  }

  async createComment(contactType: ContactType, comment: string, customerId: number): Promise<Comment> {
    const response = await firstValueFrom(this.http.post<SingleResponseModel<Comment>>(environment.apiUrl + "/comments", {
      data: {
        contact_type: contactType.id,
        comment,
        customer: customerId,
        date: format(new Date(), "yyyy-MM-dd"),
        users_permissions_user: this.authService.user()?.id
      }
    }));
    return response?.data;
  }

  async deleteComment(commentId: string): Promise<Comment> {
    const response = await firstValueFrom(this.http.delete<SingleResponseModel<Comment>>(environment.apiUrl + `/comments/${commentId}`));
    return response?.data;
  }

  async deleteContactType(id: number): Promise<ContactType | void> {
    try {
      const response = await firstValueFrom(this.http.delete<SingleResponseModel<ContactType>>(environment.apiUrl + `/contact-types/${id}`));
      this.messageService.add({
        severity: 'success',
        summary: 'Kontaktart gelöscht',
        detail: `${response?.data.title} wurde gelöscht.`
      });
      return response?.data;
    } catch (e) {
      console.error(e);
      this.messageService.add({
        severity: 'error',
        summary: 'Fehler',
        detail: `Kontaktart konnte nicht gelöscht werden.`
      });
    } finally {
      await this.fetchContactTypes();
    }
  }

  async updateContactType(type: ContactType): Promise<ContactType | void> {
    try {
      const response = await firstValueFrom(this.http.put<SingleResponseModel<ContactType>>(environment.apiUrl + `/contact-types/${type.id}`, {data: type}));
      this.messageService.add({
        severity: 'success',
        summary: 'Kontaktart aktualisiert',
        detail: `${response?.data.title} wurde aktualisiert.`
      });
      return response?.data;
    } catch (e) {
      console.error(e);
      this.messageService.add({
        severity: 'error',
        summary: 'Fehler',
        detail: `Kontaktart konnte nicht geändert werden.`
      });
    } finally {
      await this.fetchContactTypes();
    }

  }

  async createContactType(title: string): Promise<ContactType | void> {
    try {
      const response = await firstValueFrom(this.http.post<SingleResponseModel<ContactType>>(environment.apiUrl + `/contact-types`, {data: {title}}));
      this.messageService.add({
        severity: 'success',
        summary: 'Kontaktart erstellt',
        detail: `${response?.data.title} wurde erstellt.`
      });
      return response?.data;
    } catch (e) {
      console.error(e);
      this.messageService.add({
        severity: 'error',
        summary: 'Fehler',
        detail: `Kontaktart konnte nicht erstellt werden.`
      });
    } finally {
      await this.fetchContactTypes();
    }
  }
}
