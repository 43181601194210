import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, throwError } from 'rxjs';
import { ResponseModel, SingleResponseModel } from 'src/app/shared/response.model';
import { environment } from 'src/environments/environment';
import { Attachment } from './attachment.model';
import * as qs from 'qs';
import { LoadingState } from 'src/app/shared/loading-state.enum';
import * as FileSaver from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class AttachmentsService {
  attachments: Attachment[] = [];
  loadingState = LoadingState.Loading;

  constructor(
    private http: HttpClient,
  ) { }

  async fetchAttachments(customerId: number): Promise<void> {
    const query = qs.stringify({
      filters: {
        customer: {
          id: {
            $eq: customerId
          }
        }
      },
      sort: 'createdAt:desc',
      populate: ['file']
    }, { encodeValuesOnly: true });
    try {
      const response = await firstValueFrom(this.http.get<ResponseModel<Attachment>>(`${environment.apiUrl}/attachments?${query}`));
      this.attachments = response.data;
      this.loadingState = LoadingState.Data;
    } catch (e) {
      this.loadingState = LoadingState.Error;
      console.error(e);
      this.attachments = [];
    }
  }

  async createAttachment(customerId: number, name: string, file: File): Promise<void> {
    const formData = new FormData();

    formData.append('files.file', file, file.name);
    formData.append('data', JSON.stringify({
      name,
      customer: customerId
    }));

    await firstValueFrom(this.http.post<SingleResponseModel<Attachment>>(`${environment.apiUrl}/attachments`, formData));
  }

  async deleteAttachment(id: number): Promise<void> {
    await firstValueFrom(this.http.delete<SingleResponseModel<Attachment>>(`${environment.apiUrl}/attachments/${id}`));
  }

  async downloadAttachment(attachment: Attachment) {
    this.http.get(`${environment.apiUrl}/attachments/${attachment.id}/download`, { responseType: 'blob' }).subscribe(
      (response) => {

        const blob = new Blob([response]);
        FileSaver.saveAs(blob, attachment.file.name);
      },
      e => { throwError(e); }
    );
  }
}
