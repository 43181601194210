import {Component, OnInit} from '@angular/core';
import {AuthService} from "../login/auth.service";
import {PaginatorState} from "primeng/paginator";
import {CustomerEventService} from "../customer-overview/add-edit-customer/history/customer-event.service";
import {CustomerEvent} from "../customer-overview/add-edit-customer/history/customer-event.model";
import {CustomerService} from "../customer-overview/customer.service";
import {Customer} from "../customer-overview/customer.model";
import {Router} from "@angular/router";
import {StatsService} from "../stats/stats.service";
import {DayStat} from "../stats/daily-statistic.model";
import {RoleEnum} from "../login/role.enum";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  myCustomerEvents: CustomerEvent[] = [];
  myTotalCustomerEvents = 0;

  customerEvents: CustomerEvent[] = [];
  totalCustomerEvents = 0;
  stat: DayStat | undefined;

  constructor(
    public authService: AuthService,
    public customerEventService: CustomerEventService,
    public customerService: CustomerService,
    private router: Router,
    public statsService: StatsService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.myCustomerEventsPageChange({page: 0, rows: 5});
    this.latestCustomerEventsPageChange({page: 0, rows: 5});
    this.stat = await this.statsService.fetchToday();
  }

  async myCustomerEventsPageChange(event: PaginatorState) {
    const eventsResponse = await this.customerEventService.fetchLatestCustomerEvents(true, {pagination: { page: (event.page ?? 0) + 1, pageSize: event.rows}});
    this.myCustomerEvents = eventsResponse.data;
    this.myTotalCustomerEvents = eventsResponse.meta.pagination.total;
  }

  async latestCustomerEventsPageChange(event: PaginatorState) {
    const eventsResponse = await this.customerEventService.fetchLatestCustomerEvents(false, {pagination: { page: (event.page ?? 0) + 1, pageSize: event.rows}});
    this.customerEvents = eventsResponse.data;
    this.totalCustomerEvents = eventsResponse.meta.pagination.total;
  }

  openCustomer(customer: Customer) {
    void this.router.navigate(['customers', 'edit', customer.id]);
  }

  protected readonly RoleEnum = RoleEnum;
}
