<p-table #dt [value]="usersService.users" dataKey="id" [rowHover]="true"
  [rows]="10" [showCurrentPageReport]="true" [loading]="usersService.loadingState === LoadingState.Loading"
  [paginator]="true" currentPageReportTemplate="Zeige {first} bis {last} von {totalRecords} Mitarbeiter:innen"
  [alwaysShowPaginator]="false">
  <ng-template pTemplate="caption">
    <div class="table-header">
      <span class="p-input-icon-left w-100">
        <i class="pi pi-search"></i>
        <input pInputText type="text" placeholder="Suche" class="w-100" />
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th></th>
      <th>Name</th>
      <th>Username</th>
      <th>Email</th>
      <th>Rolle</th>
      <th style="min-width:140px;"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-user>
    <tr class="p-selectable-row">
      <td>
        <app-user-avatar [user]="user"></app-user-avatar>
      </td>
      <td>{{user.first_name}} {{user.last_name}}</td>
      <td>{{user.username}}</td>
      <td>{{user.email}}</td>
      <td><span class="role role-{{user.role.id}}">{{user.role.name}}</span></td>
      <td>
        <button pButton pRipple type="button" icon="pi pi-pencil" class="p-button-rounded p-button-outlined" (click)="openEdit(user)"></button>
        <button pButton pRipple type="button" icon="pi pi-trash" class="ms-2 p-button-rounded p-button-danger p-button-outlined" (click)="delete($event, user.id)"></button>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="4">Keine Mitarbeiter gefunden</td>
    </tr>
  </ng-template>
</p-table>

<div class="d-flex justify-content-end mt-3">
  <button pButton icon="pi pi-plus" label="HINZUFÜGEN" (click)="addUserDisplayed = true; createForm.reset()"></button>
</div>


<p-dialog [header]="isEdit ? 'Mitarbeiter bearbeiten' : 'Neuer Mitarbeiter'" [(visible)]="addUserDisplayed" [modal]="true">
  <div class="container mt-3">
    <form [formGroup]="createForm" autocomplete="off">
      <div class="p-fluid grid">
        <input formControlName="id" type="hidden" pInputText/>
        <div class="field col-12 md:col-6">
          <span class="p-float-label">
            <input class="w-100" formControlName="first_name" id="first_name" type="text" pInputText/>
            <label for="first_name">Vorname</label>
          </span>
        </div>
        <div class="field col-12 md:col-6">
          <span class="p-float-label">
            <input id="last_name" formControlName="last_name" class="w-100" type="text" pInputText/>
            <label for="last_name">Nachname</label>
          </span>
        </div>

        <div class="field col-12 md:col-6">
          <span class="p-float-label">
            <input id="username" formControlName="username" class="w-100" type="text" pInputText/>
            <label for="username">Username *</label>
          </span>
        </div>
        <div class="field col-12 md:col-6">
          <span class="p-float-label">
            <input id="email" formControlName="email" class="w-100" type="text" pInputText/>
            <label for="email">E-Mail *</label>
          </span>
        </div>

        <div class="field col-12">
          <span class="p-float-label">
            <p-password id="password" appendTo="body" formControlName="password" [toggleMask]="true"></p-password>
            <label for="password">Passwort @if (!isEdit) {
              <span>*</span>
            }</label>
          </span>
        </div>

        <div class="field col-12">
          <span class="p-float-label">
            <p-dropdown appendTo="body" styleClass="w-100" [options]="usersService.roles" formControlName="role"
              optionLabel="name" inputId="role" optionValue="id"
            [showClear]="true"></p-dropdown>
            <label for="role">Rolle</label>
          </span>
        </div>
      </div>

    </form>
  </div>

  <ng-template pTemplate="footer">
    <button pButton icon="pi pi-times" class="p-button-secondary p-button-outlined" (click)="createForm.reset(); addUserDisplayed = false;" label="ABBRECHEN"></button>
    <button pButton icon="pi pi-save" (click)="save()" [label]="isEdit ? 'SPEICHERN' : 'HINZUFÜGEN'"></button>
  </ng-template>
</p-dialog>

<p-confirmPopup></p-confirmPopup>
