import { Component, OnInit, signal, WritableSignal } from '@angular/core';
import { AbsenceService } from "./absence.service";
import { CustomerService } from "../customer-overview/customer.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Customer } from "../customer-overview/customer.model";
import { LazyLoadEvent, MessageService } from "primeng/api";
import { format, subDays, subMonths } from "date-fns";
import { AbsenceStatus, absenceStatuses, AbsenceViolation } from "./absence.model";
import { LoadingState } from "../shared/loading-state.enum";
import { Router } from "@angular/router";

export enum DateOptions {
  Today, Week, Month, All
}

@Component({
  selector: 'app-absences-overview',
  templateUrl: './absences-overview.component.html',
  styleUrls: ['./absences-overview.component.scss']
})
export class AbsencesOverviewComponent implements OnInit {
  addAbsenceForm = new FormGroup({
    customers: new FormControl<Customer[]>([], { nonNullable: true, validators: [Validators.minLength(1)] }),
    date: new FormControl<Date>(new Date(), { nonNullable: true, validators: [Validators.required] }),
    comment: new FormControl('', { nonNullable: true }),
    absence_status: new FormControl<AbsenceStatus>(AbsenceStatus.UNEXCUSED, { nonNullable: true, validators: [Validators.required] }),
  });
  customerSuggestions: Customer[] = [];
  selectedStatuses: string[] = ["excused", "unexcused", "warned"];
  selectedDate: DateOptions = DateOptions.Month;
  dateOptions: { label: string, value: DateOptions }[] = [
    { label: "Heute", value: DateOptions.Today },
    { label: "Letzte 7 Tage", value: DateOptions.Week },
    { label: "Letzte 30 Tage", value: DateOptions.Month },
    { label: "Alle", value: DateOptions.All },
  ];

  // Violations
  violations: AbsenceViolation[] = [];
  loadingViolations = true;
  addLoading = false;
  lastTableLazyLoadEvent: LazyLoadEvent | undefined;
  xlsxLoading: WritableSignal<boolean> = signal(false);


  constructor(
    public absenceService: AbsenceService,
    public customerService: CustomerService,
    private messageService: MessageService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.fetchAbsences();
    this.loadViolations();
  }

  calculateAbsencesTotal(id: number): number {
    return this.absenceService.allAbsences.filter(abs => abs.customer.id === id).length;
  }

  async searchCustomers(event: { originalEvent: Event, query: string }) {
    const response = await this.customerService.fetchCustomers({
      filters: {
        $or: [
          { first_name: { $containsi: event.query } },
          { last_name: { $containsi: event.query } },
          { code: { value: { $containsi: event.query } } },
          { code_rotation_1: { value: { $containsi: event.query } } },
          { code_rotation_2: { value: { $containsi: event.query } } }
        ]
      },
      fields: ['first_name', 'last_name'],
      populate: {
        code: { populate: ['location'] },
        code_rotation_1: { populate: ['location'] },
        code_rotation_2: { populate: ['location'] },
        family_members: true
      }
    });
    this.customerSuggestions = response.data ?? [];
  }

  async createAbsence() {
    if (this.addAbsenceForm.valid) {
      this.addLoading = true;
      const { date, absence_status, comment, customers } = this.addAbsenceForm.getRawValue();
      if (date && absence_status && customers.length > 0) {
        for (const customer of customers) {
          try {
            await this.absenceService.createAbsence(date, absence_status, comment, customer.id!)
          } catch (e) {
            console.error(e);
            this.messageService.add({
              severity: 'error',
              summary: 'Fehler',
              detail: `Fehlzeit für ${customer.first_name} ${customer.last_name} konnte nicht gespeichert werden`
            });
          }
        }
        this.messageService.add({
          severity: 'success',
          summary: 'Fehlzeit(en) gespeichert'
        });
        this.addAbsenceForm.reset();
        this.fetchAbsences();
      } else {
        this.messageService.add({
          severity: 'error',
          summary: 'Fehler',
          detail: `Bitte alle Felder ausfüllen`
        });
      }
      this.addLoading = false;
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Fehler',
        detail: `Bitte alle Felder ausfüllen.`
      });
    }
  }

  fetchAbsences(event?: LazyLoadEvent) {
    this.lastTableLazyLoadEvent = event;
    switch (this.selectedDate) {
      case DateOptions.Today:
        void this.absenceService.fetchAbsences({
          filter: {
            statuses: this.selectedStatuses,
            minDate: format(new Date(), "yyyy-MM-dd")
          }
        });
        break;
      case DateOptions.Week:
        void this.absenceService.fetchAbsences({
          filter: {
            statuses: this.selectedStatuses,
            minDate: format(subDays(new Date(), 7), "yyyy-MM-dd")
          }
        });
        break;
      case DateOptions.Month:
        void this.absenceService.fetchAbsences({
          filter: {
            statuses: this.selectedStatuses,
            minDate: format(subMonths(new Date(), 1), "yyyy-MM-dd")
          }
        });
        break;
      case DateOptions.All:
        void this.absenceService.fetchAbsences({
          filter: {
            statuses: this.selectedStatuses,
          }
        });
        break;
      default:
        void this.absenceService.fetchAbsences({
          filter: {
            statuses: this.selectedStatuses,
          },
          pagination: event?.first != null && event.rows != null ? {
            page: Math.floor(event.first / event.rows) + 1,
            pageSize: event.rows
          } : {},
        });
    }
  }

  async loadViolations() {
    this.loadingViolations = true;
    const response = await this.absenceService.fetchViolations();
    this.violations = response.data;
    this.loadingViolations = false;
  }

  protected readonly LoadingState = LoadingState;

  async openCustomer(id: number): Promise<void> {
    await this.router.navigate(['customers', 'edit', id]);
  }

  protected readonly absenceStatuses = absenceStatuses;

  getStatusTitleString(status: string) {
    return absenceStatuses.find(sts => sts.status === status)?.title ?? "Unbekannt";
  }

  async exportXLSX() {
    this.xlsxLoading.set(true);
    await this.customerService.exportXLSX({
      filters: {
        id: {
          $in: this.violations.map(vio => vio.customer.id),
        }
      }
    });

    this.xlsxLoading.set(false);
  }
}
