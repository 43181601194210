import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {lastValueFrom} from "rxjs";
import {environment} from "../../environments/environment";
import {User} from "../administration/users/user.model";
import {AuthService} from "../login/auth.service";
import {AlertMessage} from "./alert-message.model";
import {ResponseModel} from "../shared/response.model";
import * as qs from "qs";
import {StrapiQueryOptions} from "../shared/strapi-query-options.model";

@Injectable({
  providedIn: 'root'
})
export class AlertMessagesService {
  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {}

  async createMultipleAlertMessages(recipients: User[], message: string): Promise<void> {
    await lastValueFrom(this.http.post(`${environment.apiUrl}/alert-messages/multiple`, {
      data: {
        recipients: recipients.map(recipient => recipient.id),
        message,
        author: this.authService.user()?.id
      }
    }));
  }

  async fetchMySentMessages(params: StrapiQueryOptions): Promise<ResponseModel<AlertMessage> | null> {
    if (this.authService.user() === null) {
      await this.authService.fetchMe()
    }
    try {
      const query = qs.stringify({
        filters: {
          author: {
            id: {
              $eq: this.authService.user()!.id
            }
          },
        },
        populate: {
          recipient: true
        },
        pagination: params.pagination,
        sort: ['seen:asc', 'createdAt:desc']
      }, {encodeValuesOnly: true});
      return await lastValueFrom(this.http.get<ResponseModel<AlertMessage>>(`${environment.apiUrl}/alert-messages?${query}`));
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async deleteMessage(id: number): Promise<void> {
    await lastValueFrom(this.http.delete(`${environment.apiUrl}/alert-messages/${id}`));
  }

  async fetchMyReceivedMessages(params: StrapiQueryOptions): Promise<ResponseModel<AlertMessage> | null> {
    if (this.authService.user() === null) {
      await this.authService.fetchMe()
    }
    try {
      const query = qs.stringify({
        filters: {
          recipient: {
            id: {
              $eq: this.authService.user()!.id
            }
          },
        },
        populate: ["author"],
        pagination: params.pagination,
        sort: params.sort,
      }, {encodeValuesOnly: true});
      return await lastValueFrom(this.http.get<ResponseModel<AlertMessage>>(`${environment.apiUrl}/alert-messages?${query}`));
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async markAsSeen(id: number) {
    await lastValueFrom(this.http.put(`${environment.apiUrl}/alert-messages/${id}`, { data: { seen: true }}));
  }
}
