import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { Customer } from "../customer.model";
import { CustomerService } from "../customer.service";
import { AgeType, FamilyMember } from "./member-form/family-member.model";
import { MessageService } from "primeng/api";
import { CharacteristicService } from "../../administration/characteristic/characteristic.service";
import { format } from "date-fns";
import { Weekday } from "../../administration/weekday/weekday.model";
import { ErrorResponseModel } from 'src/app/shared/response.model';
import { HttpErrorResponse } from '@angular/common/http';
import { Status } from "./status-form/status.model";
import { TabView } from 'primeng/tabview';
import {SettingsService} from "../../administration/settings.service";
import {PickUpService} from "../../pick-up/pick-up.service";

@Component({
  selector: 'app-add-edit-customer',
  templateUrl: './add-edit-customer.component.html',
  styleUrls: ['./add-edit-customer.component.scss']
})
export class AddEditCustomerComponent implements OnInit, AfterViewInit {

  isEdit = false;
  hasSaved = false;
  customer: Customer | undefined;
  loading = true;

  selectedTabIndex = 0;

  mainForm: FormGroup = new FormGroup({});
  statusForm: FormGroup = new FormGroup({});
  internalForm: FormGroup = new FormGroup({});
  memberForm: FormGroup = new FormGroup({});

  @ViewChild('tabView') tabView!: TabView;

  constructor(
    private activatedRoute: ActivatedRoute,
    public customerService: CustomerService,
    public router: Router,
    private fb: FormBuilder,
    private messageService: MessageService,
    private characteristicService: CharacteristicService,
    private cdr: ChangeDetectorRef,
    private settingsService: SettingsService,
    private pickupService: PickUpService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(async (params) => {
      const id = params.get('id')
      if (id != null) {
        this.isEdit = true;
        this.hasSaved = true;
        this.customer = await this.customerService.getCustomer(id) ?? undefined;
      }
      this.prepareForms();
      await this.characteristicService.fetchCharacteristics();
      this.characteristicService.characteristics.map(c => c.id).forEach(c => {
        (this.internalForm.get('characteristics') as FormArray).push(new FormControl(this.customer?.characteristics.map(ch => ch.id).includes(c)));
      });
      this.loading = false;
    });
  }

  ngAfterViewInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.selectedTabIndex = this.getTabIndex(params?.['tab']);

      this.cdr.detectChanges();
    });
  }

  onTabChange(event: { index: number; }) {
    this.selectedTabIndex = event.index;
    const tabName = this.getTabName(event.index);

    if (tabName) {
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: {
          tab: tabName
        },
        queryParamsHandling: 'merge'
      });
    }
  }

  getTabIndex(tabName: string): number {
    // Default tab: 0 when tabName is null or undefined
    if (!tabName) return 0;

    const selectedIndex = this.tabView?.tabs.findIndex(
      x => x.header.toLowerCase() === tabName.toLowerCase()
    );

    if (selectedIndex > -1) return selectedIndex;

    // Default tab: 0 when tabName is not exist
    return 0;
  }

  getTabName(tabIndex: number): string {
    return this.tabView.tabs[tabIndex].header;
  }

  async prepareForms() {
    const phoneRegex = '^\\+?(?:[0-9\\s\\-()]*)$';

    this.mainForm = this.fb.group({
      title: [this.customer?.family_members.find(member => member.isApplicant)?.gender?.id, Validators.required],
      first_name: [this.customer?.first_name ?? '', Validators.required],
      last_name: [this.customer?.last_name ?? '', Validators.required],
      street: [this.customer?.street ?? '', Validators.required],
      house_number: [this.customer?.house_number ?? '', [Validators.required]],
      address_addition: [this.customer?.address_addition ?? ''],
      zip: [this.customer?.zip ?? '', [Validators.required, Validators.pattern('[0-9]{5}')]],
      city: [this.customer?.city ?? '', Validators.required],
      future_location: [this.customer?.future_location?.id, this.isEdit ? undefined : Validators.required],
      phone: [this.customer?.phone ?? '', Validators.pattern(phoneRegex)],
      mobile: [this.customer?.mobile ?? '', Validators.pattern(phoneRegex)],
      mail: [this.customer?.mail ?? '', [Validators.email]],
      comment: [this.customer?.comment ?? ''],
      supervisors: this.fb.array((this.customer?.supervisors ?? []).map(supervisor => this.fb.group({
        id: [supervisor.id],
        name: [supervisor.name],
        contact: [supervisor.contact],
      })))
    });
    const code = this.customer?.code ?? this.customer?.code_rotation_1 ?? this.customer?.code_rotation_2;
    const futureCode = this.customer?.future_code ?? this.customer?.future_code_rotation_1 ?? this.customer?.future_code_rotation_2;
    this.statusForm = this.fb.group({
      codeForm: this.fb.group({
        location: [code?.location?.id],
        weekday: [{ value: (code?.weekday as Weekday)?.id, disabled: true }],
        time: [{ value: code?.time ? new Date(new Date().setHours(Number(code.time.split(":")[0]), Number(code.time.split(":")[1]))) : undefined, disabled: true }],
        codeValue: [code?.value],
        code: [this.customer?.code?.id ?? this.customer?.code_rotation_1?.id ?? this.customer?.code_rotation_2?.id],
        rotation: [this.customer?.code_rotation_1 ? 1 : this.customer?.code_rotation_2 ? 2 : 0],
      }),
      futureCodeForm: this.fb.group({
        location: [futureCode?.location?.id],
        weekday: [{ value: (futureCode?.weekday as Weekday)?.id, disabled: true }],
        time: [{ value: futureCode?.time ? new Date(new Date().setHours(Number(futureCode.time.split(":")[0]), Number(futureCode.time.split(":")[1]))) : undefined, disabled: true }],
        codeValue: [futureCode?.value],
        code: [this.customer?.future_code?.id ?? this.customer?.future_code_rotation_1?.id ?? this.customer?.future_code_rotation_2?.id],
        rotation: [this.customer?.future_code_rotation_1 ? 1 : this.customer?.future_code_rotation_2 ? 2 : 0],
      }),
      status: [this.customer?.status ?? Status.REQUESTED, [Validators.required]],
      collection_remark: [this.customer?.collection_remark ?? ''],
      shop_remark: [this.customer?.shop_remark ?? ''],
      shop_remark_valid_until: [this.customer?.shop_remark_valid_until ? new Date(this.customer.shop_remark_valid_until) : undefined],
      certificate_valid_until: [this.customer?.certificate_valid_until ? new Date(this.customer.certificate_valid_until) : undefined],
      status_change_date: [this.customer?.status_change_date ? new Date(this.customer?.status_change_date) : undefined],
      status_change_target: [this.customer?.status_change_target],
      status_change_reason: [this.customer?.status_change_reason?.id],
      status_change_comment: [this.customer?.status_change_comment],
      consultation_date: [this.customer?.consultation_date ? new Date(this.customer.consultation_date) : undefined],
      unpaid_balance: [this.customer?.pickups?.[0]?.unpaid_balance],
      pickup_id: [this.customer?.pickups?.[0]?.id],
    });
    if (this.settingsService.settings()?.expirationActivated) {
      this.statusForm.addControl('expiration_date', new FormControl(this.customer?.expiration_date ? new Date(this.customer.expiration_date) : undefined))
    }
    this.internalForm = this.fb.group({
      family_status: [this.customer?.family_status?.id],
      nationality: [this.customer?.nationality?.id],
      origin: [this.customer?.origin],
      income_type_a: [this.customer?.income_type_a?.id],
      income_type_b: [this.customer?.income_type_b?.id],
      income_type_c: [this.customer?.income_type_c?.id],
      characteristics: this.fb.array([]),
    });

    const applicant = this.customer?.family_members.find(member => member.isApplicant)
    this.memberForm = this.fb.group({
      applicant_birth_date: applicant && applicant.birthDate ? new Date(applicant.birthDate) : undefined,
      member_id: [applicant?.id],
      members: this.fb.array(this.customer?.family_members.filter(mem => !mem.isApplicant).map(mem => this.fb.group({
        id: [mem.id],
        gender: [mem.gender?.id],
        birthDate: [mem.birthDate ? new Date(mem.birthDate) : undefined],
        ageType: [mem.ageType],
      })) ?? []),
    });
  }

  async save(): Promise<void> {
    if (this.mainForm.invalid || this.statusForm.invalid || this.internalForm.invalid || this.memberForm.invalid) {
      this.messageService.add({ severity: 'error', summary: 'Fehler', detail: 'Bitte überprüfen Sie Ihre Eingaben.' });
      this.markFormGroupAsDirty(this.mainForm);
      this.markFormGroupAsDirty(this.statusForm);
      this.markFormGroupAsDirty(this.internalForm);
      this.markFormGroupAsDirty(this.memberForm);
      this.hasSaved = true;
      return;
    }
    if (this.statusForm.get('status')?.value === Status.ACTIVE && !this.statusForm.get('codeForm')?.get('code')?.value) {
      this.messageService.add({ severity: 'warn', summary: 'Keine Nutzernummer', detail: `Aktiver Bezug, aber keine Nutzernummer gespeichert`, life: 7000 })
      this.markFormGroupAsDirty(this.statusForm);
      return;
    }

    let familyMembers: FamilyMember[] = this.memberForm.get('members')?.value;
    familyMembers = familyMembers.map(mem => ({
      ...mem,
      birthDate: mem.ageType === AgeType.DATE && mem.birthDate ? format(new Date(mem.birthDate), "yyyy-MM-dd") : undefined,
      isApplicant: false,
    }));

    familyMembers.push({
      id: this.memberForm.get('member_id')?.value,
      birthDate: this.memberForm.get('applicant_birth_date')?.value ? format(this.memberForm.get('applicant_birth_date')?.value, "yyyy-MM-dd") : undefined,
      isApplicant: true,
      gender: this.mainForm.get('title')?.value,
      ageType: AgeType.DATE,
    });

    const unpaid_balance = Number(this.statusForm.get('unpaid_balance')?.value) ?? 0;
    const pickup_id = this.statusForm.get('pickup_id')?.value;

    const customer: Partial<Customer> = {
      id: this.customer?.id,
      first_name: this.mainForm.get('first_name')?.value ?? null,
      last_name: this.mainForm.get('last_name')?.value ?? null,
      street: this.mainForm.get('street')?.value ?? null,
      house_number: this.mainForm.get('house_number')?.value ?? null,
      address_addition: this.mainForm.get('address_addition')?.value ?? null,
      zip: this.mainForm.get('zip')?.value ?? null,
      city: this.mainForm.get('city')?.value ?? null,
      phone: this.mainForm.get('phone')?.value ?? null,
      mobile: this.mainForm.get('mobile')?.value ?? null,
      mail: this.mainForm.get('mail')?.value ?? null,
      comment: this.mainForm.get('comment')?.value ?? null,
      collection_remark: this.statusForm.get('collection_remark')?.value ?? null,
      shop_remark: this.statusForm.get('shop_remark')?.value ?? null,
      shop_remark_valid_until: this.statusForm.get('shop_remark_valid_until')?.value ? format(this.statusForm.get('shop_remark_valid_until')?.value, "yyyy-MM-dd") : null,
      certificate_valid_until: this.statusForm.get('certificate_valid_until')?.value ? format(this.statusForm.get('certificate_valid_until')?.value, "yyyy-MM-dd") : null,
      family_status: this.internalForm.get('family_status')?.value ?? null,
      nationality: this.internalForm.get('nationality')?.value ?? null,
      origin: this.internalForm.get('origin')?.value ?? null,
      income_type_a: this.internalForm.get('income_type_a')?.value ?? null,
      income_type_b: this.internalForm.get('income_type_b')?.value ?? null,
      income_type_c: this.internalForm.get('income_type_c')?.value ?? null,
      characteristics: this.internalForm.value.characteristics.map((checked: boolean, i: number) => checked ? this.characteristicService.characteristics[i].id : null).filter((v: string | null) => v !== null),
      status: this.statusForm.get('status')?.value ?? null,
      family_members: familyMembers,
      code: this.statusForm.get('codeForm')?.get('rotation')?.value === 0 ? this.statusForm.get('codeForm')?.get('code')?.value ?? null : null,
      code_rotation_1: this.statusForm.get('codeForm')?.get('rotation')?.value === 1 ? this.statusForm.get('codeForm')?.get('code')?.value : null,
      code_rotation_2: this.statusForm.get('codeForm')?.get('rotation')?.value === 2 ? this.statusForm.get('codeForm')?.get('code')?.value : null,
      future_code: this.statusForm.get('futureCodeForm')?.get('rotation')?.value === 0 ? this.statusForm.get('futureCodeForm')?.get('code')?.value ?? null : null,
      future_code_rotation_1: this.statusForm.get('futureCodeForm')?.get('rotation')?.value === 1 ? this.statusForm.get('futureCodeForm')?.get('code')?.value : null,
      future_code_rotation_2: this.statusForm.get('futureCodeForm')?.get('rotation')?.value === 2 ? this.statusForm.get('futureCodeForm')?.get('code')?.value : null,
      status_change_date: this.statusForm.get('status_change_date')?.value ? format(this.statusForm.get('status_change_date')?.value, "yyyy-MM-dd") : null,
      status_change_target: this.statusForm.get('status_change_target')?.value ?? null,
      status_change_reason: this.statusForm.get('status_change_reason')?.value ?? null,
      status_change_comment: this.statusForm.get('status_change_comment')?.value ?? null,
      consultation_date: this.statusForm.get('consultation_date')?.value ?? null,
      expiration_date: this.statusForm.get('expiration_date')?.value ? format(this.statusForm.get('expiration_date')?.value, "yyyy-MM-dd") : null,
      future_location: this.statusForm.get('status')?.value === Status.ACTIVE ? null : this.mainForm.get('future_location')?.value ?? null,
      supervisors: this.mainForm.get('supervisors')?.value,
    }
    try {
      if (this.isEdit) {
        await this.customerService.updateCustomer(customer);
        if (pickup_id) {
          await this.pickupService.updatePickup({
            id: pickup_id,
            unpaid_balance: unpaid_balance
          });
        }
        this.messageService.add({ severity: 'success', summary: 'Nutzer aktualisiert', detail: `${customer.first_name} ${customer.last_name} wurde gespeichert.` });
      } else {
        await this.customerService.createCustomer(customer);
        this.messageService.add({ severity: 'success', summary: 'Nutzer erstellt', detail: `${customer.first_name} ${customer.last_name} wurde erstellt.` });
      }

      await this.router.navigate(['customers']);

    } catch (e) {
      const errorResponse = e as HttpErrorResponse;
      const response = (errorResponse.error.error) as ErrorResponseModel;

      for (const error of response.details.errors) {
        /* TODO:
         * - how is path constructed?
         * - strapi fields may not map to form controls 1:1
         */
        const field = this.statusForm.get(error.path[0]);
        field?.setErrors({ serverError: error.message });
      }

      this.messageService.add({
        severity: 'error',
        summary: 'Fehler',
        detail: `Nutzer konnte nicht gespeichert werden.`
      });
    }
  }

  markFormGroupAsDirty(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.get(key);
      control?.markAsDirty();

      if (control instanceof FormGroup) {
        this.markFormGroupAsDirty(control);
      }
    });
  }
}
