import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-overview-tab-header',
  template: `
    <div>
      @if (form.invalid && hasSaved) {
        <i class="text-danger pi pi-info-circle mr-2"></i>
      }
      <span [class.text-danger]="form.invalid && hasSaved">{{ text }}</span>
    </div>
    `,
})
export class OverviewTabHeaderComponent {
  @Input()
  form!: FormGroup;
  @Input()
  hasSaved!: boolean;
  @Input()
  text!: string;
}
