<div class="container mt-3">
  <form [formGroup]="internalForm">
    <div class="p-fluid grid">
      <div class="field col-12">
        <span class="p-float-label">
          <p-dropdown styleClass="w-100" [options]="familyStatusService.familyStatuses"
            formControlName="family_status" optionValue="id"
            optionLabel="title" inputId="family_status" [autoDisplayFirst]="false"
          [showClear]="true"></p-dropdown>
          <label for="family_status">Familienstand</label>
        </span>
      </div>

      <div class="field col-12 md:col-6">
        <span class="p-float-label">
          <p-dropdown styleClass="w-100" [options]="nationalityService.nationalities"
            formControlName="nationality" optionValue="id"
            optionLabel="title" inputId="nationality" [autoDisplayFirst]="false"
          [showClear]="true"></p-dropdown>
          <label for="nationality">Nationalität</label>
        </span>
      </div>
      <div class="field col-12 md:col-6">
        <span class="p-float-label">
          <input id="origin" class="w-100" type="text" formControlName="origin" pInputText/>
          <label for="origin">Herkunft</label>
        </span>
      </div>

      <div class="field col-12 md:col-4">
        <span class="p-float-label">
          <p-dropdown styleClass="w-100" [options]="incomeTypeService.types" formControlName="income_type_a"
            optionLabel="title" inputId="income_type_a" [autoDisplayFirst]="false" optionValue="id"
          [showClear]="true"></p-dropdown>
          <label for="income_type_a">1. Einkommen</label>
        </span>
      </div>
      <div class="field col-12 md:col-4">
        @if (internalForm.get('income_type_a')?.value !== null) {
          <span class="p-float-label">
            <p-dropdown styleClass="w-100" [options]="incomeTypeService.types" formControlName="income_type_b"
              optionLabel="title" inputId="income_type_b" [autoDisplayFirst]="false" optionValue="id"
            [showClear]="true"></p-dropdown>
            <label for="income_type_b">2. Einkommen</label>
          </span>
        }
      </div>
      <div class="field col-12 md:col-4">
        @if (internalForm.get('income_type_b')?.value !== null) {
          <span class="p-float-label">
            <p-dropdown styleClass="w-100" [options]="incomeTypeService.types" formControlName="income_type_c"
              optionLabel="title" inputId="income_type_c" [autoDisplayFirst]="false" optionValue="id"
            [showClear]="true"></p-dropdown>
            <label for="income_type_c">3. Einkommen</label>
          </span>
        }
      </div>

    </div>
    <h5 class="d-block">Attribute</h5>
    <div class="grid">
      @for (_ of characteristicsFormArray.controls; track _; let i = $index) {
        <div class="col-12 md:col-6 field-checkbox">
          <p-checkbox [binary]="true" [formControl]="$any(internalForm).get('characteristics').controls[i]" [inputId]="characteristicService.characteristics[i].id.toString()"></p-checkbox>
          <label [for]="characteristicService.characteristics[i].id">{{characteristicService.characteristics[i].title}}</label>
        </div>
      }
    </div>
  </form>
</div>
