import { Component } from '@angular/core';
import {CommentService} from "../customer-overview/add-edit-customer/comment/comment.service";
import {NationalityService} from "./nationality/nationality.service";
import {FamilyStatusService} from "./family-status/family-status.service";
import {IncomeTypeService} from "./income-type/income-type.service";
import {CharacteristicService} from "./characteristic/characteristic.service";
import {StatusChangeReasonService} from "./status-change-reason/status-change-reason.service";

@Component({
  selector: 'app-administration',
  templateUrl: './administration.component.html',
  styleUrls: ['./administration.component.scss']
})
export class AdministrationComponent {

  constructor(
    public commentService: CommentService,
    public nationalityService: NationalityService,
    public familyStatusService: FamilyStatusService,
    public incomeTypeService: IncomeTypeService,
    public characteristicService: CharacteristicService,
    public statusChangeReasonService: StatusChangeReasonService
  ) { }
}
