import {Component, inject, Input, signal, WritableSignal} from '@angular/core';
import {CalendarModule} from "primeng/calendar";
import {FormsModule} from "@angular/forms";
import {Pickup, PickupStats} from "../../pick-up/pickup.model";
import {PickUpService} from "../../pick-up/pick-up.service";
import {isSameDay, setHours} from "date-fns";
import {DatePipe} from "@angular/common";
import {InputTextModule} from "primeng/inputtext";
import {TableLazyLoadEvent, TableModule} from "primeng/table";
import {Customer} from "../../customer-overview/customer.model";
import {Router} from "@angular/router";

@Component({
  selector: 'app-pickup-admin-location',
  standalone: true,
  imports: [
    CalendarModule,
    FormsModule,
    DatePipe,
    InputTextModule,
    TableModule
  ],
  templateUrl: './pickup-admin-location.component.html',
  styleUrl: './pickup-admin-location.component.scss'
})
export class PickupAdminLocationComponent {
  @Input() locationId: number | undefined;

  pickupService = inject(PickUpService);
  router = inject(Router);

  date: WritableSignal<Date> = signal(new Date())
  pickups: WritableSignal<Pickup[]> = signal([]);
  totalPickups: WritableSignal<number> = signal(0);
  pickupsLoading: WritableSignal<boolean> = signal(false);
  tableLoadEvent: WritableSignal<TableLazyLoadEvent | undefined> = signal(undefined);
  stats: WritableSignal<PickupStats | undefined> = signal(undefined);

  async loadPickups(event?: TableLazyLoadEvent) {
    this.tableLoadEvent.set(event);
    this.pickupsLoading.set(true);
    const [pickups, stats] = await Promise.all([
      this.pickupService.getPickups({
        filters: {
          $and: [
            this.locationId ? {
              $or: [
                {customer: { code: { location: this.locationId}}},
                {customer: { code_rotation_1: { location: this.locationId}}},
                {customer: { code_rotation_2: { location: this.locationId}}},
              ]
            }: {},
            { date: {$gte: setHours(this.date(), 0)}},
            { date: {$lte: setHours(this.date(), 23)}}
          ],
        },
        populate: {
          customer: {
            populate: {
              code: {populate: ['location']},
              code_rotation_1: {populate: ['location']},
              code_rotation_2: {populate: ['location']},
              comments: { populate: ['contact_type']},
            }
          }
        },
        pagination: event?.first != null && event.rows != null ? {
          page: Math.floor(event.first / event.rows) + 1,
          pageSize: event.rows
        } : {},
      }),
      this.pickupService.getPickupStats(this.date(), this.locationId)
    ]);
    this.stats.set(stats);
    this.pickups.set(pickups.data.sort((a: Pickup, b: Pickup) => {
      const codeA = a.customer.code ?? a.customer.code_rotation_1 ?? a.customer.code_rotation_2;
      const codeB = b.customer.code ?? b.customer.code_rotation_1 ?? b.customer.code_rotation_2;

      if (codeA.isFreeCode && !codeB.isFreeCode) {
        return -1;
      }
      if (!codeA.isFreeCode && codeB.isFreeCode) {
        return 1;
      }

      return codeA.value - codeB.value;
    }));
    this.totalPickups.set(pickups.meta.pagination.total);
    this.pickupsLoading.set(false);
  }

  getPickupNote(customer: Customer): string | undefined {
    return customer.comments?.find(c => isSameDay(this.date(), c.date) && c.contact_type.id === 4)?.comment;
  }
}
