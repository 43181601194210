<div class="d-flex justify-content-center vh-100 vw-100 align-items-center bg-primary-500 p-2">
  <p-card [style]="{'max-width': '25rem'}">
    <div class="p-5 pt-0 border-bottom-1 border-400">
      <img src="../../assets/img/logo.png" class="img-fluid" alt="Logo">
    </div>
    <form (submit)="login()">
      <div class="field mt-3">
        <input type="text" [(ngModel)]="mail" pInputText placeholder="Email" class="w-100" name="mail" />
      </div>
      <div class="field">
        <p-password class="w-100" inputStyleClass="w-100" styleClass="w-100" [(ngModel)]="password" name="password"
                    [feedback]="false" placeholder="Passwort" [toggleMask]="true"></p-password>
        @if (error) {
          <small id="pw-error" class="p-error block">{{ error }}</small>
        }
      </div>
      <div class="d-flex justify-content-center">
        <button pButton class="btn-primary w-100 d-flex justify-content-center" [disabled]="loading" type="submit">
          @if (!loading) {
            <span>LOGIN</span>
          }
          @if (loading) {
            <p-progressSpinner strokeWidth="7"></p-progressSpinner>
          }
        </button>
      </div>
    </form>
  </p-card>
</div>

