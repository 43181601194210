import {Component} from '@angular/core';
import {Location} from '@angular/common'
import {AuthService} from "../../login/auth.service";
import {SidebarService} from "../sidebar/sidebar.service";

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {

  private sidebarVisible = false;

  constructor(
    private location: Location,
    public authService: AuthService,
    public sidebarService: SidebarService,
  ) {}

  sidebarOpen() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('nav-open');
    this.sidebarVisible = true;
  }

  sidebarClose() {
    const body = document.getElementsByTagName('body')[0];
    this.sidebarVisible = false;
    body.classList.remove('nav-open');
  }

  sidebarToggle() {
    if (!this.sidebarVisible) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
  }

  getTitle() {
    let title: string | undefined = this.location.prepareExternalUrl(this.location.path());
    if(title.charAt(0) === '#'){
      title = title.slice( 1 );
    } else if (title.split('/').pop()) {
      title = title.split('/').pop();
    }
    for(let item = 0; item < this.sidebarService.sidebarItems().length; item++){
      if(this.sidebarService.sidebarItems()[item].path === title){
        return this.sidebarService.sidebarItems()[item].title;
      }
    }
    return 'Dashboard';
  }
}
