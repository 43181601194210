import { Injectable } from '@angular/core';
import {LoadingState} from "../../shared/loading-state.enum";
import {HttpClient} from "@angular/common/http";
import {firstValueFrom} from "rxjs";
import {ResponseModel} from "../../shared/response.model";
import {environment} from "../../../environments/environment";
import {Weekday} from "./weekday.model";

@Injectable({
  providedIn: 'root'
})
export class WeekdayService {
  loadingState = LoadingState.Loading;
  weekdays: Weekday[] = [];
  constructor(private http: HttpClient) {
    void this.fetchWeekdays();
  }

  async fetchWeekdays(): Promise<void> {
    try {
      const response = await firstValueFrom(this.http.get<ResponseModel<Weekday>>(environment.apiUrl + "/weekdays"));
      this.weekdays = response.data;
      this.loadingState = LoadingState.Data;
    } catch (e) {
      console.error(e);
      this.loadingState = LoadingState.Error;
      this.weekdays = [];
    }
  }
}
