<p-card>
  <div class="p-fluid grid mt-3">
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <p-calendar inputId="date" styleClass="w-100" dateFormat="dd.mm.yy" [(ngModel)]="minDate"
          (ngModelChange)="loadCustomers()"></p-calendar>
        <label [for]="'date'">Ablauf Von</label>
      </span>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <p-calendar inputId="date" styleClass="w-100" dateFormat="dd.mm.yy" [(ngModel)]="maxDate"
          (ngModelChange)="loadCustomers()"></p-calendar>
        <label [for]="'date'">Ablauf Bis</label>
      </span>
    </div>
  </div>

  <div class="d-flex justify-content-end align-items-center">
    <button [disabled]="xlsxLoading()" class="p-link text-primary mr-3 mb-3" (click)="exportXLSX()">
      @if (xlsxLoading()) {
      <i class="pi pi-spinner"></i>
      } @else {
      <i class="pi pi-download"></i>
      }
      Excel-Export
    </button>
  </div>

  <p-table [value]="customers" [lazy]="true" (onLazyLoad)="loadCustomers($event)" [paginator]="true" [rows]="10"
    [showCurrentPageReport]="true" [totalRecords]="totalCustomers" [alwaysShowPaginator]="false"
    [loading]="expirationsLoading" dataKey="id"
    currentPageReportTemplate="Zeige {first} bis {last} von {totalRecords} Nutzern">
    <ng-template pTemplate="caption">
      <div class="table-header d-flex justify-content-center">

        <span class="p-input-icon-left w-100">
          <i class="pi pi-search"></i>
          <input class="w-100" pInputText type="text"
            (input)="loadCustomers(lastTableLazyLoadEvent!, $any($event).target.value)" placeholder="Suche" />
        </span>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th>Nr.</th>
        <th pSortableColumn="first_name">Vorname <p-sortIcon field="first_name"></p-sortIcon></th>
        <th pSortableColumn="last_name">Nachname <p-sortIcon field="last_name"></p-sortIcon></th>
        <th>Befristungsdatum</th>
        <th style="min-width: 140px;"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-customer>
      <tr>
        <td>
          @if (customer.code) {
          <span>{{customer.code.location.abbreviation}} {{customer.code.value}}</span>
          }
          @if (customer.code_rotation_1) {
          <span>{{customer.code_rotation_1.location.abbreviation}} {{customer.code_rotation_1.value}}-1</span>
          }
          @if (customer.code_rotation_2) {
          <span>{{customer.code_rotation_2.location.abbreviation}} {{customer.code_rotation_2.value}}-2</span>
          }
        </td>
        <td>{{customer.first_name}}</td>
        <td>{{customer.last_name}}</td>
        <td>{{customer.expiration_date | date : 'dd.MM.yyyy'}}</td>
        <td><button pButton class="p-button-rounded p-button-outlined" icon="pi pi-external-link"
            (click)="router.navigate(['customers', 'edit', customer.id])"></button> </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="5">Keine Nutzer gefunden</td>
      </tr>
    </ng-template>
  </p-table>
</p-card>