import {Component, DestroyRef, effect, inject} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {SettingsService} from "../settings.service";
import {LoadingState} from "../../shared/loading-state.enum";

@Component({
  selector: 'app-global-settings',
  templateUrl: './global-settings.component.html',
  styleUrls: ['./global-settings.component.scss']
})
export class GlobalSettingsComponent {
  globalSettingsForm = new FormGroup({
    adultAge: new FormControl(0, [Validators.required, Validators.min(0)]),
    singleBoxPrice: new FormControl(0, [Validators.required, Validators.min(0)]),
    extraBoxPrice: new FormControl(0, [Validators.required, Validators.min(0)]),
    expirationActivated: new FormControl(false, [Validators.required]),
    expirationDuration: new FormControl(3, [Validators.required, Validators.min(0)]),
    blockedActivated: new FormControl(false, [Validators.required]),
    blockedDuration: new FormControl(1, [Validators.required, Validators.min(0)]),
  });
  LoadingState = LoadingState;
  destroyRef = inject(DestroyRef);



  constructor(
    public settingsService: SettingsService,
  ) {
    effect(() => {
      if (this.settingsService.settings()) {
        const {
          adultAge,
          singleBoxPrice,
          extraBoxPrice,
          blockedDuration,
          expirationDuration,
          expirationActivated,
          blockedActivated
        } = this.settingsService.settings()!;
        this.globalSettingsForm.setValue({
          adultAge, singleBoxPrice, extraBoxPrice, blockedDuration, expirationDuration, expirationActivated, blockedActivated
        });
      }
    });
    this.globalSettingsForm.get('expirationActivated')!.valueChanges.subscribe(value => {
      if (value) {
        this.globalSettingsForm.get('expirationDuration')?.enable();
      } else {
        this.globalSettingsForm.get('expirationDuration')?.disable();
      }
    });
    this.globalSettingsForm.get('blockedActivated')!.valueChanges.subscribe(value => {
      if (value) {
        this.globalSettingsForm.get('blockedDuration')?.enable();
      } else {
        this.globalSettingsForm.get('blockedDuration')?.disable();
      }
    });
  }

  save() {
    const {adultAge, singleBoxPrice, extraBoxPrice, blockedDuration, expirationDuration, expirationActivated, blockedActivated} = this.globalSettingsForm.getRawValue();
    if (
      adultAge != null &&
      singleBoxPrice != null &&
      extraBoxPrice != null &&
      expirationActivated != null &&
      expirationDuration != null &&
      blockedActivated != null &&
      blockedDuration != null
    ) {
      void this.settingsService.save({adultAge, singleBoxPrice, extraBoxPrice, blockedDuration, expirationDuration, expirationActivated, blockedActivated});
    }

  }
}
