<div class="wrapper">
  <!-- colors: blue, azure, green, orange, red, purple -->
  <div class="sidebar" data-color="orange" data-image="">
    <div class="wrap-sb">
      <app-sidebar (menuItemClicked)="navbar?.sidebarClose()"></app-sidebar>
    </div>

    <div class="sidebar-background" style="background-image: url(assets/img/sidebar.jpg)"></div>
  </div>

  <div class="main-panel">
    <app-navbar></app-navbar>
    <div class="content-container">
      <router-outlet></router-outlet>
    </div>
  </div>
  <p-toast></p-toast>
</div>
