import { Injectable } from '@angular/core';
import {LoadingState} from "../../shared/loading-state.enum";
import {HttpClient} from "@angular/common/http";
import {firstValueFrom} from "rxjs";
import {ResponseModel, SingleResponseModel} from "../../shared/response.model";
import {environment} from "../../../environments/environment";
import {Nationality} from "./nationality.model";
import {ContactType} from "../../customer-overview/add-edit-customer/comment/comment.model";
import {MessageService} from "primeng/api";

@Injectable({
  providedIn: 'root'
})
export class NationalityService {
  loadingState = LoadingState.Loading;
  nationalities: Nationality[] = [];
  constructor(
    private http: HttpClient,
    private messageService: MessageService
  ) {
    void this.fetchNationalities();
  }

  async fetchNationalities(): Promise<void> {
    try {
      const response = await firstValueFrom(this.http.get<ResponseModel<Nationality>>(environment.apiUrl + "/nationalities"));
      this.nationalities = response.data;
      this.loadingState = LoadingState.Data;
    } catch (e) {
      console.error(e);
      this.loadingState = LoadingState.Error;
      this.nationalities = [];
    }
  }

  async deleteNationality(id: number): Promise<Nationality | void> {
    try {
      const response = await firstValueFrom(this.http.delete<SingleResponseModel<Nationality>>(environment.apiUrl + `/nationalities/${id}`));
      this.messageService.add({
        severity: 'success',
        summary: 'Nationalität gelöscht',
        detail: `${response?.data.title} wurde gelöscht.`
      });
      return response?.data;
    } catch (e) {
      console.error(e);
      this.messageService.add({
        severity: 'error',
        summary: 'Fehler',
        detail: `Nationalität konnte nicht gelöscht werden.`
      });
    } finally {
      await this.fetchNationalities();
    }
  }

  async updateNationality(type: Nationality): Promise<Nationality | void> {
    try {
      const response = await firstValueFrom(this.http.put<SingleResponseModel<Nationality>>(environment.apiUrl + `/nationalities/${type.id}`, {data: type}));
      this.messageService.add({
        severity: 'success',
        summary: 'Nationalität aktualisiert',
        detail: `${response?.data.title} wurde aktualisiert.`
      });
      return response?.data;
    } catch (e) {
      console.error(e);
      this.messageService.add({
        severity: 'error',
        summary: 'Fehler',
        detail: `Nationalität konnte nicht geändert werden.`
      });
    } finally {
      await this.fetchNationalities();
    }

  }

  async createNationality(title: string): Promise<ContactType | void> {
    try {
      const response = await firstValueFrom(this.http.post<SingleResponseModel<Nationality>>(environment.apiUrl + `/nationalities`, {data: {title}}));
      this.messageService.add({
        severity: 'success',
        summary: 'Nationalität erstellt',
        detail: `${response?.data.title} wurde erstellt.`
      });
      return response?.data;
    } catch (e) {
      console.error(e);
      this.messageService.add({
        severity: 'error',
        summary: 'Fehler',
        detail: `Nationalität konnte nicht erstellt werden.`
      });
    } finally {
      await this.fetchNationalities();
    }
  }
}
