import {Component, computed, OnInit, signal, WritableSignal} from '@angular/core';
import {CardModule} from "primeng/card";
import {AsyncPipe, DatePipe, SlicePipe} from "@angular/common";
import {ListboxModule} from "primeng/listbox";
import {CodePipe} from "../shared/pipes/code.pipe";
import {Customer} from "../customer-overview/customer.model";
import {CustomerService} from "../customer-overview/customer.service";
import {HouseholdPipe} from "../shared/pipes/household.pipe";
import {isAfter, isSameDay, subDays, subYears} from "date-fns";
import {ActivatedRoute, RouterLink} from "@angular/router";
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {SidebarModule} from "primeng/sidebar";
import {AgeType, FamilyMember} from "../customer-overview/add-edit-customer/member-form/family-member.model";
import {SettingsService} from "../administration/settings.service";
import {FormsModule} from "@angular/forms";
import {InputTextModule} from "primeng/inputtext";
import {ButtonModule} from "primeng/button";
import {TagModule} from "primeng/tag";
import {PickUpService} from "./pick-up.service";
import {PanelModule} from "primeng/panel";
import {absenceStatuses} from "../absences-overview/absence.model";
import {ProgressBarModule} from "primeng/progressbar";
import {IconFieldModule} from "primeng/iconfield";
import {InputIconModule} from "primeng/inputicon";
import {FloatLabelModule} from "primeng/floatlabel";
import {StyleClassModule} from "primeng/styleclass";
import {InputTextareaModule} from "primeng/inputtextarea";
import {AuthService} from "../login/auth.service";
import {AgePipe} from "../shared/pipes/age.pipe";
import {AgesListPipe} from "../shared/pipes/ages-list.pipe";
import {RoleEnum} from "../login/role.enum";
import {MessageService} from "primeng/api";
import {LocationService} from "../administration/location/location.service";
import {LocationModel} from "../administration/location/location.model";

interface GroupedCustomers {
  time: string;
  customers: Customer[];
}

@Component({
  selector: 'app-pick-up',
  standalone: true,
  imports: [
    CardModule,
    DatePipe,
    ListboxModule,
    CodePipe,
    SlicePipe,
    AsyncPipe,
    HouseholdPipe,
    ProgressSpinnerModule,
    SidebarModule,
    FormsModule,
    InputTextModule,
    ButtonModule,
    TagModule,
    PanelModule,
    ProgressBarModule,
    IconFieldModule,
    InputIconModule,
    FloatLabelModule,
    StyleClassModule,
    InputTextareaModule,
    AgePipe,
    AgesListPipe,
    RouterLink
  ],
  templateUrl: './pick-up.component.html',
  styleUrl: './pick-up.component.scss'
})
export class PickUpComponent implements OnInit {
  today = subDays(new Date(), 0);
  customersToday: WritableSignal<GroupedCustomers[]> = signal([]);
  completedCustomers: WritableSignal<Customer[]> = signal([]);
  customersAtCooling: WritableSignal<Customer[]> = signal([]);
  allCustomers: Customer[] = [];
  newUnpaidBalance = 0;
  locationId: number | undefined;
  loading = true;
  comment = '';

  location: WritableSignal<LocationModel | undefined> = signal(undefined);

  selectedCustomer: WritableSignal<Customer | undefined> = signal(undefined);
  selectedAdultCount = computed(() => {
    return this.selectedCustomer() && this.settingsService.settings() ? this.getAdultCount(this.selectedCustomer()!) : undefined;
  });

  childrenCount = computed(() => {
    return this.selectedCustomer() ? this.selectedCustomer()!.family_members.length - this.selectedAdultCount()! : undefined;
  })

  boxCount = computed(() => {
    return this.selectedAdultCount() ? this.getBoxCount(this.selectedAdultCount()!) : 1;
  });

  completionPercentage = computed(() => {
    const completedCustomers = this.completedCustomers().filter(c => this.getTodayPickUp(c)).length;
    const openCustomers = this.customersToday().flatMap(group => group.customers).length;
    return Math.round(completedCustomers * 100 / (completedCustomers + openCustomers));
  });

  getRemainingBoxCount = computed(() => {
    return this.customersToday()
      .flatMap(group => group.customers)
      .map(customer => this.getAdultCount(customer))
      .map(adultCount => this.getBoxCount(adultCount))
      .reduce((a, b) => a + b, 0);
  });

  getCurrentIncome = computed(() => {
    return this.completedCustomers()
      .map(customer => this.getTodayPickUp(customer)?.paid ?? 0)
      .reduce((a , b) => a + b, 0)
  });

  getRemainingCustomersCount = computed(() => {
    return this.customersToday().flatMap(group => group.customers).length;
  });

  // 1 und 2 Personen >7 Jahre zahlen 3€ und bekommen eine Kiste
  // 3 und 4 Personen >7 Jahre zahlen 5€ und bekommen 2 Kisten
  // Ab 5. Person >7 Jahre zahlen 7€ und bekommen 3 Kisten
  calculatedPrice = computed(() => {
    if (this.isFreeCode()) {
      return 0;
    }
    if (this.settingsService.settings()) {
      return this.settingsService.settings()!.singleBoxPrice + (this.boxCount() - 1) * (this.settingsService.settings()!.extraBoxPrice);
    } else {
      return 0;
    }
  });

  isFreeCode = computed(() => {
    const code =  this.selectedCustomer()?.code ?? this.selectedCustomer()?.code_rotation_1 ?? this.selectedCustomer()?.code_rotation_2;
    return code?.isFreeCode
  });

  constructor(
    public customerService: CustomerService,
    private activatedRoute: ActivatedRoute,
    public settingsService: SettingsService,
    public pickupService: PickUpService,
    public authService: AuthService,
    private messageService: MessageService,
    private locationService: LocationService,
  ) {
  }

  async ngOnInit(): Promise<void> {
    void this.authService.fetchMe();
    this.activatedRoute.params.subscribe(async params => {
      try {
        this.locationId = params['locationId'];
        const [location] = await Promise.all([
          await this.locationService.getLocation(this.locationId!),
          await this.fetchCustomers()
        ]);
        this.location.set(location.data);

        this.loading = false;
      } catch (e) {
        console.error(e);
        this.messageService.add({
          severity: 'error',
          summary: 'Fehler beim Abrufen der Nutzer',
          detail: 'Bitte Seite neu laden.',
        });
      }
    });

    this.pickupService.socket().on('pickup', async (customerId) => {
      try {
        await this.fetchCustomers();
      } catch (error) {
        console.error(error);
        this.messageService.add({
          severity: 'error',
          summary: 'Fehler beim Abrufen der Nutzer',
          detail: 'Bitte Seite neu laden.',
        });
      }
    });

    this.pickupService.socket().on('absence', async (customerId) => {
      try {
        await this.fetchCustomers();
      } catch (error) {
        console.error(error);
        this.messageService.add({
          severity: 'error',
          summary: 'Fehler beim Abrufen der Nutzer',
          detail: 'Bitte Seite neu laden.',
        });
      }
    });
  }

  getTodayAbsence(customer: Customer) {
    return customer.absences?.find(abs => isSameDay(new Date(abs.date), this.today))
  }

  getTodayPickUp(customer: Customer) {
    return customer.pickups?.find(pickup => isSameDay(new Date(pickup.date), this.today));
  }

  handleCustomer(customer: Customer) {
    this.selectedCustomer.set(customer);
    this.newUnpaidBalance = 0;
    this.comment = '';
  }

  groupCustomersByTime(customers: Customer[]) {
    this.customersToday.set(customers.reduce((groups: GroupedCustomers[], customer: Customer) => {
      const time = customer.code?.time || customer.code_rotation_1?.time || customer.code_rotation_2?.time;

      const existingGroup = groups.find(group => group.time === time);
      if (existingGroup) {
        existingGroup.customers.push(customer);
        existingGroup.customers.sort((a, b) => {
          const aCodeValue = a.code?.value ?? a.code_rotation_1?.value ?? a.code_rotation_2?.value;
          const bCodeValue = b.code?.value ?? b.code_rotation_1?.value ?? b.code_rotation_2?.value;
          return aCodeValue - bCodeValue;
        });
      } else {
        groups.push({time, customers: [customer]});
        groups.sort((a, b) => a.time < b.time ? -1 : 1);
      }

      return groups;
    }, []));
  }

  async fetchCustomers() {
    if (!this.locationId) {
      return;
    }
    this.completedCustomers.set(await this.pickupService.getTodayCompletedCustomers(this.locationId));
    this.customersAtCooling.set(await this.pickupService.getCustomersAtCooling(this.locationId));
    this.allCustomers = await this.pickupService.getTodayCustomers(this.locationId);
    this.groupCustomersByTime(this.allCustomers);
    this.loading = false;
  }

  pickUpBox() {
    if (!this.selectedCustomer()) {
      console.error('Tried picking up non-existent selectedCustomer');
      return;
    }
    this.pickupService.socket().emit('pickup', this.selectedCustomer()?.id, this.newUnpaidBalance, this.calculatedPrice() + (this.selectedCustomer()!.pickups?.[0]?.unpaid_balance ?? 0) - this.newUnpaidBalance, this.boxCount(), this.comment);
    this.selectedCustomer.set(undefined);
    this.newUnpaidBalance = 0;
    this.comment = '';
  }

  receivedCooling(customerId: number) {
    this.pickupService.socket().emit('receivedCooling', customerId);
  }

  markAbsent() {
    this.pickupService.socket().emit('absence', this.selectedCustomer()?.id, this.comment);
    this.selectedCustomer.set(undefined);
    this.newUnpaidBalance = 0;
    this.comment = '';
  }

  getAbsenceStatusLabel(status: string) {
    return absenceStatuses.find(s => s.status === status)?.title;
  }

  getBoxCount(adultCount: number) {
    if (adultCount < 3) {
      return 1;
    } else if (adultCount < 5) {
      return 2;
    } else {
      return 3;
    }
  }

  getAdultCount(customer: Customer): number {
    const adultCountWithoutDate = customer.family_members.filter(member => member.ageType === AgeType.ADULT).length;
    return adultCountWithoutDate + customer.family_members.filter(member => member.ageType === AgeType.DATE && member.birthDate && isAfter(subYears(new Date(), this.settingsService.settings()!.adultAge), new Date(member.birthDate))).length;
  }

  filterCustomers(text: string) {
    const filteredCustomers = this.allCustomers.filter(customer =>
      customer.first_name.toLowerCase().includes(text.toLowerCase()) ||
      customer.last_name.toLowerCase().includes(text.toLowerCase()) ||
      customer.code?.value.toString().includes(text) ||
      customer.code_rotation_1?.value.toString().includes(text) ||
      customer.code_rotation_2?.value.toString().includes(text)
    );
    this.groupCustomersByTime(filteredCustomers);
  }

  protected readonly RoleEnum = RoleEnum;

  customerHasChildren(family_members: FamilyMember[]) {
    return family_members.some(mem => mem.ageType === AgeType.DATE &&
      mem.birthDate != null &&
      isAfter(new Date(mem.birthDate), subYears(new Date(), 18)))
  }
}
