<p-card [header]="isEdit ? 'Nutzer Bearbeiten' : 'Nutzer Hinzufügen'">
  @if (loading) {
    <div class="d-flex justify-content-center">
      <p-progressSpinner strokeWidth="5"></p-progressSpinner>
    </div>
  }
  @if (!loading) {
    <p-tabView #tabView [(activeIndex)]="selectedTabIndex" (onChange)="onTabChange($event)">
      <p-tabPanel header="main">
        <ng-template pTemplate="header">
          <app-overview-tab-header [form]='mainForm' [hasSaved]='hasSaved' text='Stammdaten' />
        </ng-template>
        <app-main-form [mainForm]="mainForm"></app-main-form>
      </p-tabPanel>
      <p-tabPanel header="status">
        <ng-template pTemplate="header">
          <app-overview-tab-header [form]='statusForm' [hasSaved]='hasSaved' text='Status' />
        </ng-template>
        <app-status-form [statusForm]="statusForm"></app-status-form>
      </p-tabPanel>
      <p-tabPanel header="internal">
        <ng-template pTemplate="header">
          <span>Intern</span>
        </ng-template>
        <app-internal-form [internalForm]="internalForm"></app-internal-form>
      </p-tabPanel>
      <p-tabPanel header="members">
        <ng-template pTemplate="header">
          <span>Angehörige</span>
        </ng-template>
        <app-member-form [memberForm]="memberForm"></app-member-form>
      </p-tabPanel>
      @if (isEdit) {
        <p-tabPanel header="absences">
          <ng-template pTemplate="header">
            <span>Fehlzeiten</span>
          </ng-template>
          <app-absences [customerId]="customer!.id!"></app-absences>
        </p-tabPanel>
      }
      @if (isEdit) {
        <p-tabPanel header="history">
          <ng-template pTemplate="header">
            <span>Historie</span>
          </ng-template>
          <app-history [customerId]="customer!.id!"></app-history>
        </p-tabPanel>
      }
      @if (isEdit && customer?.id) {
        <p-tabPanel header="notes">
          <ng-template pTemplate="header">
            <span>Notiz</span>
          </ng-template>
          <app-comment [customerId]="customer!.id!"></app-comment>
        </p-tabPanel>
      }
      @if (isEdit && customer?.id) {
        <p-tabPanel header="files">
          <ng-template pTemplate="header">
            <span>Dateien</span>
          </ng-template>
          <app-attachments [customerId]="customer!.id!"></app-attachments>
        </p-tabPanel>
      }
    </p-tabView>
  }
</p-card>
<p-card styleClass="mt-2">
  <div class="d-flex justify-content-end">
    <button (click)="router.navigate(['customers'])" pButton label="ABBRECHEN"
    class="p-button-outlined p-button-secondary me-2"></button>
    <button (click)="save()" pButton label="SPEICHERN" icon="pi pi-save"></button>
  </div>
</p-card>
