import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { firstValueFrom } from "rxjs";
import { ResponseModel } from "../../../shared/response.model";
import { environment } from "../../../../environments/environment";
import * as qs from "qs";
import { CustomerEvent } from "./customer-event.model";
import { StrapiQueryOptions } from "../../../shared/strapi-query-options.model";
import {AuthService} from "../../../login/auth.service";
import {Code} from "../../../code/code.model";
import {FamilyMember} from "../member-form/family-member.model";
import {Nationality} from "../../../administration/nationality/nationality.model";
import {IncomeType} from "../../../administration/income-type/income-type.model";
import {FamilyStatus} from "../../../administration/family-status/family-status.model";
import {Characteristic} from "../../../administration/characteristic/characteristic.model";
import {customerFieldNames} from "../../customer.model";
import {CustomerService} from "../../customer.service";
import {format} from "date-fns";
import {AbsenceService} from "../../../absences-overview/absence.service";
import {LocationModel} from "../../../administration/location/location.model";
import {Supervisor} from "../main-form/supervisor.model";

export type SpecialEvent = {
  name: string;
  requiredEvents: { field: string, field_action: string }[];
  formatDescription: (requiredEvents: CustomerEvent[]) => string;
};

@Injectable({
  providedIn: 'root'
})
export class CustomerEventService {

  readonly specialEvents: SpecialEvent[] = [
    {
      name: 'status_change_started',
      requiredEvents: [
        { field: 'status_change_target', field_action: 'create' },
        { field: 'status_change_date', field_action: 'create' },
      ],
      formatDescription: (requiredEvents) => {
        const target = requiredEvents.find((event) => event.field === 'status_change_target')?.new_value;
        const date = requiredEvents.find((event) => event.field === 'status_change_date')?.new_value;

        const readableDate = new Date(date ?? '').toLocaleDateString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit' });

        return `Es wurde ein Status-Wechsel eingerichtet.

            <strong>Ziel-Status:</strong> ${this.customerService.getHumanFriendlyStatus(target || '')}
            <strong>Datum des Wechsels:</strong> ${readableDate || ''}`;
      }
    }
  ];

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private customerService: CustomerService,
    private absenceService: AbsenceService,
  ) {
  }

  async fetchEventsForCustomer(customerId: number, params?: StrapiQueryOptions): Promise<ResponseModel<CustomerEvent>> {
    const query = qs.stringify({
      filters: {
        customer: {
          id: {
            $eq: customerId
          }
        }
      },
      populate: ['users_permissions_user', 'users_permissions_user.avatar'],
      pagination: params?.pagination,
      sort: 'createdAt:desc'
    }, { encodeValuesOnly: true });
    return await firstValueFrom(this.http.get<ResponseModel<CustomerEvent>>(environment.apiUrl + `/customer-events?${query}`));
  }

  async fetchLatestCustomerEvents(onlyMyEvents: boolean, params?: StrapiQueryOptions): Promise<ResponseModel<CustomerEvent>> {
    if (onlyMyEvents && this.authService.user() === null) {
      await this.authService.fetchMe();
    }

    const query = qs.stringify({
      filters: onlyMyEvents ? {
        users_permissions_user: {
          id: {
            $eq: this.authService.user()!.id
          }
        }
      } : {},
      populate: {
        users_permissions_user: true,
        customer: {
          populate: {
            code: {
              populate: ['location']
            },
            code_rotation_1: {
              populate: ['location']
            },
            code_rotation_2: {
              populate: ['location']
            },
          },
        }
      },
      pagination: params?.pagination,
      sort: 'createdAt:desc'
    });
    return await firstValueFrom(this.http.get<ResponseModel<CustomerEvent>>(environment.apiUrl + `/customer-events?${query}`));
  }

  getHumanFriendlyEventNameHTML(event: CustomerEvent) {
    if (this.isSpecialEvent(event)) {
      // Special events don't have extra verbs
      return `<strong>${customerFieldNames[event.field].name || 'Unbekanntes Feld'}</strong>`;
    } else {
      switch (event.entity) {
        case 'customer':
          if (event.action === 'create') {
            const customer = JSON.parse(event.new_value!);
            return `<i class="pi pi-user pr-2"></i><strong>Nutzer ${customer.first_name} ${customer.last_name} erstellt</strong>`;
          }
          return (customerFieldNames[event.field]?.icon ? `<i class="pi ${customerFieldNames[event.field]?.icon} pr-2"></i>` : '') + `<strong>${customerFieldNames[event.field]?.name || event.field} ${this.getChangeVerb(event.field_action)}</strong>`;
        case 'absence':
          return `<i class="pi pi-calendar pr-2"></i> <strong>Fehlzeit ${this.getChangeVerb(event.action)}</strong>`;
        case "attachment":
          return `<i class="pi pi-paperclip pr-2"></i> <strong>Anhang ${this.getChangeVerb(event.action)}</strong>`;
        case 'note':
          return `<i class="pi pi-comment pr-2"></i> <strong>Notiz ${this.getChangeVerb(event.action)}</strong>`;
        case 'pickup':
          return `<i class="pi pi-box pr-2"></i> <strong>Abholung ${this.getChangeVerb(event.action)}</strong>`;
        default:
          return `${event.entity} (${event.field})`;
      }
    }
  }

  getHumanFriendlyEventDescriptionHTML(event: CustomerEvent) {
    switch (event.entity) {
      case 'customer':
        if (event.field_action === 'update') {
          return `${this.formatEventValue(event, event.old_value ?? '')} <strong>&rarr;</strong> ${this.formatEventValue(event, event.new_value ?? '')}`;
        } else if (event.field_action === 'create') {
          return this.formatEventValue(event, event.new_value ?? '');
        } else if (event.field_action === 'delete') {
          return `Alter Wert: "${this.formatEventValue(event, event.old_value ?? '')}"`;
        }
        return ``;
      case 'absence':
        if (event.action === 'create') {
          return this.isValidJSON(event.new_value!) ? `${this.absenceService.getHumanFriendlyAbsenceStatus(JSON.parse(event.new_value!).absence_status)} am ${format(new Date(JSON.parse(event.new_value!).date), 'dd.MM.yyyy')}` : event.new_value;
        } else if (event.action === 'update') {
          if (this.isValidJSON(event.old_value!) && this.isValidJSON(event.new_value!)) {
            const oldAbsence = event.old_value ? JSON.parse(event.old_value!) : undefined;
            const newAbsence = event.new_value ? JSON.parse(event.new_value!) : undefined;
            return `${format(new Date(oldAbsence.date), 'dd.MM.yyyy')} (${this.absenceService.getHumanFriendlyAbsenceStatus(oldAbsence.absence_status)}) &rarr;
            ${format(new Date(newAbsence.date), 'dd.MM.yyyy')} (${this.absenceService.getHumanFriendlyAbsenceStatus(newAbsence.absence_status)})`;
          }
        } else if (event.action === 'delete') {
          return this.isValidJSON(event.old_value!) ? `Alter Wert: ${this.absenceService.getHumanFriendlyAbsenceStatus(JSON.parse(event.old_value!).absence_status)} am ${format(new Date(JSON.parse(event.old_value!).date), 'dd.MM.yyyy')}` : event.old_value;
        }
        return `${event.old_value} &rarr; ${event.new_value}`;
      case 'attachment':
        return event.action === 'create' ? JSON.parse(event.new_value!)?.file : JSON.parse(event.old_value!)?.file;
      case 'note':
        return event.action === 'create' ? JSON.parse(event.new_value!)?.comment : JSON.parse(event.old_value!)?.comment;
      case 'pickup':
        return event.action === 'create' ? format(new Date(JSON.parse(event.new_value!)?.date), 'dd.MM.yyyy') : format(new Date(JSON.parse(event.old_value!)?.date), 'dd.MM.yyyy');
    }

  }

  getChangeVerb(action: string) {
    switch (action) {
      case 'create':
        return 'hinzugefügt';
      case 'delete':
        return 'entfernt';
      case 'update':
        return 'geändert'
      default:
        return 'unbekannt';
    }
  }

  isSpecialEvent(event: CustomerEvent) {
    return this.specialEvents.find((specialEvent) => {
      return specialEvent.name === event.field;
    }) !== undefined;
  }

  isLegacyEvent(event: CustomerEvent) {
    if (event.comment == null || event.comment === '') return false;
    return event.comment.split(' ')[0] === 'Von:';
  }

  formatEventValue(event: CustomerEvent, value: string) {
    if (value === '') return value;

    switch (customerFieldNames[event.field]?.type) {
      case 'status':
        return this.customerService.getHumanFriendlyStatus(value);
      case 'date':
        return new Date(value).toLocaleDateString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit' });
      case 'customer_code': {
        const customerCode = JSON.parse(value) as Code;
        const time = customerCode.time?.split(':').splice(0, 2).join(':');
        const location = customerCode.location ?? '';
        const weekday = customerCode.weekday ?? '';
        let codeStr = `${location} ${customerCode.value}`;
        if (event.field.endsWith('_rotation_1')) {
          codeStr += '-1';
        }
        if (event.field.endsWith('_rotation_2')) {
          codeStr += '-2';
        }
        return time ? `${codeStr} (${weekday}, ${time} Uhr)` : `${codeStr} (${weekday})`;
      }
      case 'family_members': {
        const members = JSON.parse(value) as Record<string, FamilyMember>;
        const membersArray = Object.values(members);

        const childCount = membersArray.filter((member) => member.ageType === 'child').length;
        const adultCount = membersArray.filter((member) => member.ageType === 'adult').length;
        const birthDateCount = membersArray.filter((member) => member.ageType === 'date').length;

        return `${childCount} Kind(er), ${adultCount} Erwachsene, ${birthDateCount} Geburtsdatum`;
      }
      case 'nationality': {
        const nationality = JSON.parse(value) as Nationality;
        return nationality.title;
      }
      case 'income_type': {
        const incomeType = JSON.parse(value) as IncomeType;
        return incomeType.title;
      }
      case 'family_status': {
        const familyStatus = JSON.parse(value) as FamilyStatus;
        return familyStatus.title;
      }
      case 'future_location': {
        const location = JSON.parse(value) as LocationModel;
        return location.city;
      }
      case 'supervisors': {
        const supervisors = JSON.parse(value) as Record<string, Supervisor>;
        if (Object.values(supervisors).length === 0) {
          return 'Kein Betreuer';
        }
        return Object.values(supervisors).map(s => s.name).join(', ');
      }
      case 'characteristics': {
        const characteristics = JSON.parse(value) as Record<string, Characteristic>;
        const characteristicsArr = Object.values(characteristics);
        return characteristicsArr.map((characteristic) => characteristic.title).join(', ');
      }
      default:
        return value;
    }
  }

  isValidJSON(str: string): boolean {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  }
}
