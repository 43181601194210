import { Component, signal, WritableSignal } from '@angular/core';
import { ButtonModule } from "primeng/button";
import { CalendarModule } from "primeng/calendar";
import { CardModule } from "primeng/card";
import { DatePipe } from "@angular/common";
import { InputTextModule } from "primeng/inputtext";
import { MessageService, SharedModule } from "primeng/api";
import { TableLazyLoadEvent, TableModule } from "primeng/table";
import { addMonths, format } from "date-fns";
import { Customer } from "../customer-overview/customer.model";
import { CustomerService } from "../customer-overview/customer.service";
import { Router } from "@angular/router";
import { FormsModule } from "@angular/forms";
import { Status } from "../customer-overview/add-edit-customer/status-form/status.model";

@Component({
  selector: 'app-expiration',
  standalone: true,
  imports: [
    ButtonModule,
    CalendarModule,
    CardModule,
    DatePipe,
    InputTextModule,
    SharedModule,
    TableModule,
    FormsModule
  ],
  templateUrl: './expiration.component.html',
  styleUrl: './expiration.component.scss'
})
export class ExpirationComponent {
  lastTableLazyLoadEvent: TableLazyLoadEvent | undefined;
  minDate: Date | null = null;
  maxDate = addMonths(new Date(), 3);
  customers: Customer[] = [];
  totalCustomers = 0;
  expirationsLoading = false;
  xlsxLoading: WritableSignal<boolean> = signal(false);

  constructor(
    public customerService: CustomerService,
    public router: Router,
    private messageService: MessageService,
  ) { }

  async loadCustomers(event?: TableLazyLoadEvent, search?: string) {
    this.lastTableLazyLoadEvent = event;
    this.expirationsLoading = true;
    const result = await this.customerService.fetchCustomers({
      filters: {
        $and: [
          { expiration_date: this.minDate ? { $gte: format(this.minDate, 'yyyy-MM-dd') } : {} },
          { expiration_date: { $lte: format(this.maxDate, 'yyyy-MM-dd') } }
        ],
        $or: search ? [
          { first_name: { $containsi: search } },
          { last_name: { $containsi: search } },
          { code: { value: { $containsi: search } } },
          { code_rotation_1: { value: { $containsi: search } } },
          { code_rotation_2: { value: { $containsi: search } } }
        ] : [],
        status: { $eq: Status.ACTIVE }
      },
      fields: ['first_name', 'last_name', 'expiration_date'],
      populate: {
        code: {
          populate: 'location'
        },
        code_rotation_1: {
          populate: 'location'
        },
        code_rotation_2: {
          populate: 'location'
        }
      },
      pagination: event?.rows != null && event?.first != null ? {
        page: Math.floor(event.first / event.rows) + 1,
        pageSize: event.rows
      } : {},
      sort: event?.sortField && event?.sortOrder ? [event.sortField + (event.sortOrder < 0 ? ":desc" : ":asc")] : [],
    });
    this.customers = result.data;
    this.totalCustomers = result.meta.pagination.total;
    this.expirationsLoading = false;
  }

  async exportXLSX() {
    try {
      this.xlsxLoading.set(true);
      await this.customerService.exportXLSX({
        filters: {
          $and: [
            { expiration_date: this.minDate ? { $gte: format(this.minDate, 'yyyy-MM-dd') } : {} },
            { expiration_date: { $lte: format(this.maxDate, 'yyyy-MM-dd') } }
          ],
        },
        sort: this.lastTableLazyLoadEvent?.sortField && this.lastTableLazyLoadEvent?.sortOrder ? [this.lastTableLazyLoadEvent.sortField + (this.lastTableLazyLoadEvent.sortOrder < 0 ? ":desc" : ":asc")] : []
      });
      this.messageService.add({
        severity: 'success',
        summary: 'Export',
        detail: `Daten wurden erfolgreich exportiert.`
      });
    } catch (err) {
      console.error(err);
      this.messageService.add({
        severity: 'error',
        summary: 'Fehler',
        detail: `Daten konnten nicht exportiert werden.`
      });
    } finally {
      this.xlsxLoading.set(false);
    }
  }

}
