import { Injectable } from '@angular/core';
import {LoadingState} from "../../../shared/loading-state.enum";
import {HttpClient} from "@angular/common/http";
import {firstValueFrom} from "rxjs";
import {ResponseModel} from "../../../shared/response.model";
import {environment} from "../../../../environments/environment";
import {Gender} from "./gender.model";

@Injectable({
  providedIn: 'root'
})
export class GenderService {
  loadingState = LoadingState.Loading;
  genders: Gender[] = [];
  constructor(private http: HttpClient) {
    void this.fetchGenders();
  }

  async fetchGenders(): Promise<void> {
    try {
      const response = await firstValueFrom(this.http.get<ResponseModel<Gender>>(environment.apiUrl + "/genders"));
      this.genders = response.data;
      this.loadingState = LoadingState.Data;
    } catch (e) {
      console.error(e);
      this.loadingState = LoadingState.Error;
      this.genders = [];
    }
  }
}
