import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Item} from "./item.model";
import {ConfirmationService} from "primeng/api";

@Component({
  selector: 'app-crud-table',
  templateUrl: './crud-table.component.html',
  styleUrls: ['./crud-table.component.scss'],
  providers: [ConfirmationService]
})
export class CrudTableComponent {
  @Input() data!: Item[]
  @Output() update: EventEmitter<Item> = new EventEmitter<Item>();
  @Output() delete: EventEmitter<Item> = new EventEmitter<Item>();
  @Output() create: EventEmitter<string> = new EventEmitter<string>();
  clonedItems: { [s: number]: Item; } = {};

  constructor(
    private confirmationService: ConfirmationService
  ) {
  }

  onRowEditInit(item: Item) {
    this.clonedItems[item.id] = {...item};
  }

  onRowEditSave(item: Item) {
    delete this.clonedItems[item.id];
    this.update.emit(item);
  }

  onRowEditCancel(item: Item, index: number) {
    this.data[index] = this.clonedItems[item.id];
    delete this.clonedItems[item.id];
  }

  deleteItem(event: Event, item: Item) {
    this.confirmationService.confirm({
      target: event.target ? event.target : undefined,
      message: 'Möchtest du diesen Eintrag wirklich löschen?',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: "JA",
      rejectLabel: "NEIN",
      accept: async () => {
        this.delete.emit(item);
      }
    });
  }
}
