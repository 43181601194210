import {Component, signal, WritableSignal} from '@angular/core';
import {CodeService} from "./code.service";
import {WeekdayService} from "../administration/weekday/weekday.service";
import {LocationService} from "../administration/location/location.service";
import {LoadingState} from "../shared/loading-state.enum";
import {ConfirmationService, MessageService} from "primeng/api";
import {Code} from "./code.model";
import {Weekday} from "../administration/weekday/weekday.model";
import {addMinutes, format, isAfter} from "date-fns";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {TableLazyLoadEvent} from "primeng/table";

@Component({
  selector: 'app-code',
  templateUrl: './code.component.html',
  styleUrls: ['./code.component.scss']
})
export class CodeComponent {
  selectedLocations: number[] = [];
  selectedWeekdays: number[] = [];
  availableOnly = false;
  showOnlyFreeCodes = false;
  codes: WritableSignal<Code[]> = signal([]);
  totalCodes: WritableSignal<number> = signal(0);
  loading: WritableSignal<boolean> = signal(false);
  lastTableLazyLoadEvent: TableLazyLoadEvent | undefined;
  clonedCodes: { [s: number]: Code; } = {};
  protected readonly LoadingState = LoadingState;
  generateCodesOpen = false;
  generateForm = new FormGroup({
    time_from: new FormControl(new Date(new Date().setHours(14, 0)), {
      nonNullable: true,
      validators: Validators.required
    }),
    time_until: new FormControl(new Date(new Date().setHours(16, 0)), {
      nonNullable: true,
      validators: Validators.required
    }),
    interval: new FormControl(15, {nonNullable: true, validators: Validators.required}),
    amount: new FormControl(10, {nonNullable: true, validators: Validators.required}),
    num_start: new FormControl(1000, {nonNullable: true, validators: Validators.required}),
    weekday: new FormControl(1, {nonNullable: true, validators: Validators.required}),
    location: new FormControl(1, {nonNullable: true, validators: Validators.required}),
    isFreeCode: new FormControl<boolean>(false),
  });
  createForm = new FormGroup({
    location: new FormControl<number | undefined>(undefined, {nonNullable: true, validators: Validators.required}),
    weekday: new FormControl<number | undefined>(undefined, {nonNullable: true, validators: Validators.required}),
    time: new FormControl<Date | undefined>(undefined, {nonNullable: true}),
    value: new FormControl<number | undefined>(undefined, {nonNullable: true, validators: Validators.required}),
    isFreeCode: new FormControl<boolean>(false),
  });

  constructor(
    public codeService: CodeService,
    public weekdayService: WeekdayService,
    public locationService: LocationService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService
  ) {
  }

  async loadCodes(event?: TableLazyLoadEvent, search?: string): Promise<void> {
    this.loading.set(true);
    this.lastTableLazyLoadEvent = event;
    const response = await this.codeService.fetchCodes({
      filters: {
        $and: [
          {
            location: { id: this.selectedLocations ? {$in: this.selectedLocations} : {} },
            weekday: { id: this.selectedWeekdays ? {$in: this.selectedWeekdays} : {} },
            isFreeCode: this.showOnlyFreeCodes ? true : {},
            $or: [
              {customer: {first_name: {$containsi: search}}},
              {customer_rotation_1: {first_name: {$containsi: search}}},
              {customer_rotation_2: {first_name: {$containsi: search}}},
              {customer: {last_name: {$containsi: search}}},
              {customer_rotation_1: {last_name: {$containsi: search}}},
              {customer_rotation_2: {last_name: {$containsi: search}}},
              {value: {$containsi: search}},
            ],
          },
          this.availableOnly ? {
            customer: {id: {$null: true}},
            $or: [
              {customer_rotation_1: {id: {$null: true}}},
              {customer_rotation_2: {id: {$null: true}}},
            ],
          } : {},
        ],
      },
      pagination: event?.first != null && event.rows != null ? {
        page: Math.floor(event.first / event.rows) + 1,
        pageSize: event.rows
      } : {},
      sort: event?.sortField && event.sortOrder ? [event.sortField + (event.sortOrder < 0 ? ":desc" : ":asc")] : [],
      populate: {
        customer: { fields: ['first_name', 'last_name'] },
        customer_rotation_1: { fields: ['first_name', 'last_name'] },
        customer_rotation_2: { fields: ['first_name', 'last_name'] },
        future_customer: { fields: ['first_name', 'last_name'] },
        future_customer_rotation_1: { fields: ['first_name', 'last_name'] },
        future_customer_rotation_2: { fields: ['first_name', 'last_name'] },
        weekday: true,
        location: true
      }
    });
    this.codes.set(response.data.map(code => ({ ...code, time: code.time?.substr(0,5)})));
    this.totalCodes.set(response.meta.pagination.total);
    this.loading.set(false);
  }

  async deleteCode(id: string, event: Event): Promise<void> {
    this.confirmationService.confirm({
      target: event.target ? event.target : undefined,
      message: 'Möchtest du diese Nutzernummer wirklich löschen?',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: "JA",
      rejectLabel: "NEIN",
      accept: async () => {
        try {
          await this.codeService.deleteCode(id);
          await this.loadCodes(this.lastTableLazyLoadEvent!);
          this.messageService.add({
            severity: 'success',
            summary: 'Nutzernummer gelöscht',
          });
        } catch (e) {
          console.error(e);
          this.messageService.add({
            severity: 'error',
            summary: 'Fehler',
            detail: `Nutzernummer konnte nicht gelöscht werden.`
          });
        }
      }
    });
  }

  onRowEditInit(code: Code) {
    this.clonedCodes[code.id] = {...code};
  }

  async onRowEditSave(code: Code) {
    delete this.clonedCodes[code.id];
    try {
      let time = code.time;
      if (typeof time === "string" && time.length === 5) { // Unchanged HH:mm format
        time = format(new Date().setHours(Number(code.time.split(":")[0]), Number(time.split(":")[1])), "HH:mm:00.000");
      } else {
        time = format(new Date(time), 'HH:mm:00.000');
      }
      code.time = time;
      await this.codeService.updateCode(code.id, code.value, time, (code.weekday as Weekday).id, code.isFreeCode);
      await this.loadCodes();
      this.messageService.add({
        severity: 'success',
        summary: 'Nutzernummer aktualisiert',
      });
    } catch (e) {
      console.error(e);
      this.messageService.add({
        severity: 'error',
        summary: 'Fehler',
        detail: `Nutzernummer konnte nicht aktualisiert werden.`
      });
    }
  }

  onRowEditCancel(code: Code, index: number) {
    this.codeService.codes[index] = this.clonedCodes[code.id];
    delete this.clonedCodes[code.id];
  }

  async save() {
    const {
      time_from,
      time_until,
      weekday,
      amount,
      interval,
      location,
      num_start,
      isFreeCode
    } = this.generateForm.getRawValue();
    let value = num_start;
    let codesCreated = 0;
    for (let currentTime = time_from; !isAfter(currentTime, time_until); currentTime = addMinutes(currentTime, interval)) {
      for (let i = 0; i < amount; i++) {
        try {
          await this.codeService.createCode(weekday, location, value++, format(currentTime, "HH:mm:00.000"), isFreeCode ?? false);
          codesCreated += 1;
        } catch (e) {
          this.messageService.add({
            severity: 'error',
            summary: 'Fehler',
            detail: `Nutzernummer konnte nicht erstellt werden.`
          });
          console.error(e);
        }
      }
    }
    this.messageService.add({
      severity: 'success',
      summary: `${codesCreated} Nutzernummer(n) erstellt`,
    });
    this.generateForm.reset();
    this.generateCodesOpen = false;
  }

  async saveSingle() {
    const {location, weekday, value, time, isFreeCode} = this.createForm.getRawValue();
    if (!location || !weekday || !value) {
      this.messageService.add({
        severity: 'error',
        summary: 'Fehler',
        detail: `Bitte Ort, Wochentag und Nummer angeben.`
      });
      return;
    }
    try {
      await this.codeService.createCode(weekday, location, value, time ? format(time, "HH:mm:00.000") : undefined, isFreeCode ?? false);
      this.messageService.add({
        severity: 'success',
        summary: `Nutzernummer ${value} erstellt`,
      });
    } catch (e) {
      this.messageService.add({
        severity: 'error',
        summary: 'Fehler',
        detail: `Nutzernummer konnte nicht erstellt werden.`
      });
      console.error(e);
    } finally {
      this.createForm.reset();
      //this.generateCodesOpen = false;
    }
  }
}
