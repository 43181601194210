import { Component } from '@angular/core';
import { UsersService } from "./users.service";
import { LoadingState } from "../../shared/loading-state.enum";
import { environment } from "../../../environments/environment";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ConfirmationService, MessageService } from "primeng/api";
import { User } from "./user.model";

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  providers: [ConfirmationService]
})
export class UsersComponent {

  LoadingState = LoadingState
  environment = environment;
  addUserDisplayed = false;

  createForm = new FormGroup({
    id: new FormControl(),
    first_name: new FormControl('', { nonNullable: true }),
    last_name: new FormControl('', { nonNullable: true }),
    username: new FormControl('', { nonNullable: true }),
    email: new FormControl('', { nonNullable: true, validators: [Validators.required, Validators.email] }),
    password: new FormControl('', { nonNullable: true, validators: [Validators.min(6)] }),
    role: new FormControl(1, { nonNullable: true, validators: [Validators.required] })
  });
  public isEdit = false;
  constructor(
    public usersService: UsersService,
    public messageService: MessageService,
    public confirmationService: ConfirmationService
  ) { }


  async save() {
    if (!this.isEdit) {
      try {
        const user = await this.usersService.createUser(this.createForm.getRawValue());
        this.addUserDisplayed = false;
        this.messageService.add({
          severity: 'success',
          summary: 'Mitarbeiter erstellt',
          detail: `${user.first_name} ${user.last_name} wurde erstellt.`
        });
        this.createForm.reset();
      } catch (e) {
        this.messageService.add({
          severity: 'error',
          summary: 'Fehler',
          detail: `Mitarbeiter konnte nicht erstellt werden.`
        });
      } finally {
        void this.usersService.fetchUsers();
      }
    } else {
      try {
        const user = await this.usersService.updateUser(this.createForm.getRawValue());
        this.addUserDisplayed = false;
        this.messageService.add({
          severity: 'success',
          summary: 'Mitarbeiter aktualisiert',
          detail: `${user.first_name} ${user.last_name} wurde aktualisiert.`
        });
        this.createForm.reset();
      } catch (e) {
        this.messageService.add({
          severity: 'error',
          summary: 'Fehler',
          detail: `Mitarbeiter konnte nicht aktualisiert werden.`
        });
      } finally {
        void this.usersService.fetchUsers();
      }
    }
  }

  openEdit(user: User) {
    this.isEdit = true;
    const { id, username, first_name, last_name, email, role } = user;
    this.createForm.setValue({
      id, username, first_name: first_name ?? '', last_name: last_name ?? '', email, role: typeof role === "number" ? role : role.id, password: ''
    });
    this.addUserDisplayed = true;
  }

  delete(event: Event, id: number) {
    this.confirmationService.confirm({
      target: event.target ? event.target : undefined,
      message: 'Möchtest du diesen Mitarbeiter wirklich löschen?',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: "JA",
      rejectLabel: "NEIN",
      accept: async () => {
        try {
          const user = await this.usersService.deleteUser(id);
          void this.usersService.fetchUsers();
          this.messageService.add({
            severity: 'success',
            summary: 'Mitarbeiter gelöscht',
            detail: `${user.first_name} ${user.last_name} wurde gelöscht.`
          });
        } catch (e) {
          console.error(e);
          this.messageService.add({
            severity: 'error',
            summary: 'Fehler',
            detail: `Mitarbeiter konnte nicht gelöscht werden.`
          });
        }
      }
    });
  }
}
