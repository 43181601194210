@for (date of eventDates; track date) {
  <div>
    <strong>{{date}}</strong>
    <p-timeline [value]="changeSetsGroupedByDate[date]" align="left" class="w-full md:w-20rem">
      <ng-template pTemplate="opposite" let-changeset>
        <app-user-avatar [user]="changeset.user"></app-user-avatar>
      </ng-template>
      <ng-template pTemplate="content" let-changeset>
        <span>um {{changeset.time + ' Uhr'}}</span>
        <div class='event-card'>
          @for (event of changeset.events; track event) {
            <div class='event'>
              @if (customerEventService.isLegacyEvent(event)) {
                <div class="legacy-event">
                  <span class="legacy-event-icon">
                    <strong style="padding-right: 0.5em;">Altes Ereignis</strong>
                  </span>
                  <span class="legacy-event-text">
                    <div>"{{event.description}}"</div>
                    <div>{{event.comment | slice:0:event.comment.indexOf("(") }}</div>
                  </span>
                </div>
              }
              @if (!customerEventService.isLegacyEvent(event)) {
                <div>
                  <div [innerHTML]="customerEventService.getHumanFriendlyEventNameHTML(event)"></div>
                  <!-- Special events have their own description -->
                  @if (this.customerEventService.isSpecialEvent(event) && event.description) {
                    <div style="white-space: pre-line;"
                    [innerHTML]="event.description"></div>
                  }
                  @if (!this.customerEventService.isSpecialEvent(event)) {
                    <div [innerHTML]="customerEventService.getHumanFriendlyEventDescriptionHTML(event)"></div>
                  }
                </div>
              }
            </div>
          }
        </div>
      </ng-template>
    </p-timeline>
  </div>
}

<p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [rowsPerPageOptions]="[10, 20, 30]"
  [totalRecords]="totalEvents ?? 0">
</p-paginator>
