import {inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ResponseModel} from "../shared/response.model";
import {Automation} from "./automation.model";
import {environment} from "../../environments/environment";
import {firstValueFrom} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AutomationService {
  http = inject(HttpClient);

  async fetchAutomations(): Promise<ResponseModel<Automation>> {
    return await firstValueFrom(this.http.get<ResponseModel<Automation>>(`${environment.apiUrl}/automations`));
  }

  async execute(key: string): Promise<void> {
    await firstValueFrom(this.http.post(`${environment.apiUrl}/automations/execute/${key}`, {}));
  }
}
