import {Component} from '@angular/core';
import {ConfirmationService} from "primeng/api";
import {LocationService} from "./location.service";
import {LocationModel} from "./location.model";

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss']
})
export class LocationComponent {
  newCity = '';
  newAbbreviation = '';
  clonedItems: { [s: number]: LocationModel; } = {};

  constructor(
    private confirmationService: ConfirmationService,
    public locationService: LocationService
  ) {}

  onRowEditInit(item: LocationModel) {
    this.clonedItems[item.id] = {...item};
  }

  onRowEditSave(item: LocationModel) {
    delete this.clonedItems[item.id];
    this.locationService.update(item);
  }

  onRowEditCancel(item: LocationModel, index: number) {
    this.locationService.locations[index] = this.clonedItems[item.id];
    delete this.clonedItems[item.id];
  }

  deleteLocation(event: Event, loc: LocationModel) {
    this.confirmationService.confirm({
      target: event.target ? event.target : undefined,
      message: 'Möchtest du diesen Standort wirklich löschen?',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: "JA",
      rejectLabel: "NEIN",
      accept: async () => {
        await this.locationService.delete(loc.id);
      }
    });
  }

  async create(): Promise<void> {
    await this.locationService.create(this.newCity, this.newAbbreviation);
    this.newCity = '';
    this.newAbbreviation = '';
  }
}
