import { Component, Input } from '@angular/core';
import {FormArray, FormControl, FormGroup} from "@angular/forms";
import { AddressCompletion } from '../../customer.model';
import { CustomerService } from '../../customer.service';
import { GenderService } from "../gender/gender.service";
import {LocationService} from "../../../administration/location/location.service";

@Component({
  selector: 'app-main-form',
  templateUrl: './main-form.component.html',
  styleUrls: ['./main-form.component.scss']
})
export class MainFormComponent {

  streetSuggestions: string[] = [];
  addressCompletions: AddressCompletion[] = [];

  @Input() mainForm!: FormGroup;
  constructor(
    public genderService: GenderService,
    public customerService: CustomerService,
    public locationService: LocationService
  ) { }


  async searchStreets(event: { originalEvent: Event, query: string }) {
    this.addressCompletions = await this.customerService.getAddressSuggestions(event.query);
    this.streetSuggestions = this.addressCompletions.map(address => `${address.street} (${address.city})`);
  }

  onStreetSelected(street: string) {
    const idx = this.streetSuggestions.indexOf(street);
    if (idx === -1) return;

    const address = this.addressCompletions[idx];

    this.mainForm.patchValue({
      street: address.street,
      zip: address.zip,
      city: address.city,
    })
  }

  addSupervisor() {
    (this.mainForm.get('supervisors') as FormArray).push(new FormGroup({
      id: new FormControl(null),
      name: new FormControl(''),
      contact: new FormControl(''),
    }))
  }

  get supervisors() {
    return this.mainForm.controls['supervisors'] as FormArray;
  }

  removeSupervisor(i: number) {
    this.supervisors.removeAt(i);
  }
}
