import {Component, OnInit} from '@angular/core';
import {StatsService} from "./stats.service";

export enum StatDateOption {
  ONE_MONTH, THREE_MONTHS, ONE_YEAR, ALL_YEARS
}

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.scss']
})
export class StatsComponent implements OnInit {

  selectedDate: StatDateOption = StatDateOption.ONE_MONTH;
  dateOptions: { label: string, value: StatDateOption }[] = [
    {label: "1 Monat", value: StatDateOption.ONE_MONTH},
    {label: "3 Monate", value: StatDateOption.THREE_MONTHS},
    {label: "1 Jahr", value: StatDateOption.ONE_YEAR},
    {label: "Alle", value: StatDateOption.ALL_YEARS},
  ];

  constructor(
    public statsService: StatsService
  ) {
  }

  async ngOnInit(): Promise<void> {
    await this.statsService.fetchHistoricData();
    this.statsService.configureStatInterval(this.selectedDate);
  }
}
