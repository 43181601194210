import {Component, ViewChild} from '@angular/core';
import {NavbarComponent} from "../shared/navbar/navbar.component";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent {
  @ViewChild(NavbarComponent) navbar?: NavbarComponent;
}
