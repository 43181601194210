<div class="sidebar-wrapper">
  <div class="logo">
    <img src="assets/img/logo-white.png" alt="logo" />
  </div>
  <ul class="sidebar-nav responsive-nav">
    @if (isMobile) {
      <li>
        <a (click)="authService.logout()">
          <p>Log out</p>
        </a>
      </li>
    }
    @if (isMobile) {
      <li class="separator hidden-lg hidden-md"></li>
    }
    @for (menuItem of sidebarService.sidebarItems(); track menuItem.path) {
      @if (menuItem.roles.includes(roleId)) {
        <li [class.active]="router.url.includes('/' + menuItem.path)">
          <a (click)="navigate(menuItem.path)" class="d-flex align-items-center">
            <div class="d-flex justify-content-center icon-container mr-2"><i class="{{menuItem.icon}}"></i></div>
            <p class="flex-grow-1">{{menuItem.title}}</p>
            @if (menuItem.badge > 0) {
              <p-badge styleClass="ml-1 bg-primary-600" severity="danger" [value]="menuItem.badge.toString()"></p-badge>
            }
          </a>
        </li>
      }
    }
  </ul>
</div>
