import {Component, Input} from '@angular/core';
import {ScheduleService} from "../schedule.service";
import {MessageService} from "primeng/api";

@Component({
  selector: 'app-location-schedule',
  templateUrl: './location-schedule.component.html',
  styleUrls: ['./location-schedule.component.scss']
})
export class LocationScheduleComponent {

  @Input() locationId!: number;
  loading = false;

  dates: Date[] = [];

  constructor(
    public scheduleService: ScheduleService,
    private messageService: MessageService
  ) {
    const today: Date = new Date();

    for (let i = 0; this.dates.length < 10; i++) {
      const date: Date = new Date(today.getTime() + i * 24 * 60 * 60 * 1000);
      if (date.getDay() !== 0 && date.getDay() !== 6) {
        this.dates.push(date);
      }
    }
  }

  async getPickupSchedule(date: Date) {
    try {
      this.loading = true;
      await this.scheduleService.getPickup(date, this.locationId);
      this.messageService.add({
        severity: 'success',
        summary: 'Plan erfolgreich erstellt',
        detail: `Plan wurde heruntergeladen.`
      });
    } catch (e) {
      this.messageService.add({
        severity: 'error',
        summary: 'Fehler',
        detail: `Plan konnte nicht erstellt werden.`
      })
    } finally {
      this.loading = false;
    }
  }
}
