import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { ButtonModule } from 'primeng/button';
import { CardModule } from "primeng/card";
import { TableModule } from "primeng/table";
import { MultiSelectModule } from "primeng/multiselect";
import { ChartModule } from "primeng/chart";
import { InputTextModule } from "primeng/inputtext";
import { LoginComponent } from './login/login.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CustomerOverviewComponent } from './customer-overview/customer-overview.component';
import { JwtInterceptor } from "./login/jwt.interceptor";
import { CheckboxModule } from "primeng/checkbox";
import { HouseholdPipe } from './shared/pipes/household.pipe';
import { AddEditCustomerComponent } from './customer-overview/add-edit-customer/add-edit-customer.component';
import { TabViewModule } from "primeng/tabview";
import { DropdownModule } from "primeng/dropdown";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { InputTextareaModule } from "primeng/inputtextarea";
import { CalendarModule } from "primeng/calendar";
import { MainFormComponent } from './customer-overview/add-edit-customer/main-form/main-form.component';
import { StatusFormComponent } from './customer-overview/add-edit-customer/status-form/status-form.component';
import { InternalFormComponent } from './customer-overview/add-edit-customer/internal-form/internal-form.component';
import { MemberFormComponent } from './customer-overview/add-edit-customer/member-form/member-form.component';
import { AbsencesComponent } from './customer-overview/add-edit-customer/absences/absences.component';
import { CommentComponent } from './customer-overview/add-edit-customer/comment/comment.component';
import { HistoryComponent } from './customer-overview/add-edit-customer/history/history.component';
import { RippleModule } from "primeng/ripple";
import { ToastModule } from "primeng/toast";
import { MessageModule } from "primeng/message";
import { ConfirmPopupModule } from "primeng/confirmpopup";
import { StatsComponent } from './stats/stats.component';
import { AbsencesOverviewComponent } from './absences-overview/absences-overview.component';
import { AdministrationComponent } from './administration/administration.component';
import { CrudTableComponent } from './shared/crud-table/crud-table.component';
import { ConfirmationService, MessageService } from "primeng/api";
import { UsersComponent } from './administration/users/users.component';
import { AvatarModule } from "primeng/avatar";
import { DialogModule } from "primeng/dialog";
import { PasswordModule } from "primeng/password";
import { AutoCompleteModule } from "primeng/autocomplete";
import { SelectButtonModule } from "primeng/selectbutton";
import { CertificateComponent } from './certificate/certificate.component';
import { GlobalSettingsComponent } from './administration/global-settings/global-settings.component';
import { ProgressBarModule } from "primeng/progressbar";
import { LocationComponent } from './administration/location/location.component';
import { ProfileComponent } from './profile/profile.component';
import { FileUploadModule } from "primeng/fileupload";
import { InputSwitchModule } from "primeng/inputswitch";
import { CodeComponent } from './code/code.component';
import { ScheduleComponent } from './schedule/schedule.component';
import { ConsultationComponent } from './consultation/consultation.component';
import { FullCalendarModule } from "@fullcalendar/angular";
import { LocationScheduleComponent } from './schedule/location-schedule/location-schedule.component';
import { AccordionModule } from "primeng/accordion";
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { TooltipModule } from "primeng/tooltip";
import { BadgeModule } from "primeng/badge";
import { PaginatorModule } from "primeng/paginator";
import { TimelineModule } from "primeng/timeline";
import { OverviewTabHeaderComponent } from './customer-overview/add-edit-customer/overview-tab-header/overview-tab-header.component';
import { AttachmentsComponent } from './customer-overview/add-edit-customer/attachments/attachments.component';
import { CustomerNoInputsComponent } from './shared/customer-no-inputs/customer-no-inputs.component';
import { UserAvatarComponent } from './shared/user-avatar/user-avatar.component';
import { AlertMessagesComponent } from './alert-messages/alert-messages.component';
import {OverlayPanelModule} from "primeng/overlaypanel";
import {ListboxModule} from "primeng/listbox";
import {CodePipe} from "./shared/pipes/code.pipe";
import {AvatarLabelPipe} from "./shared/pipes/avatar-label.pipe";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {AgePipe} from "./shared/pipes/age.pipe";

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    DashboardComponent,
    NavbarComponent,
    SidebarComponent,
    LoginComponent,
    CustomerOverviewComponent,
    AddEditCustomerComponent,
    MainFormComponent,
    StatusFormComponent,
    InternalFormComponent,
    MemberFormComponent,
    AbsencesComponent,
    CommentComponent,
    HistoryComponent,
    StatsComponent,
    AbsencesOverviewComponent,
    AdministrationComponent,
    CrudTableComponent,
    UsersComponent,
    CertificateComponent,
    GlobalSettingsComponent,
    LocationComponent,
    ProfileComponent,
    CodeComponent,
    ScheduleComponent,
    ConsultationComponent,
    LocationScheduleComponent,
    OverviewTabHeaderComponent,
    AttachmentsComponent,
    CustomerNoInputsComponent,
    UserAvatarComponent,
    AlertMessagesComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ButtonModule,
    CardModule,
    TableModule,
    MultiSelectModule,
    ChartModule,
    InputTextModule,
    HttpClientModule,
    ProgressSpinnerModule,
    FormsModule,
    CheckboxModule,
    TabViewModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    DropdownModule,
    InputTextareaModule,
    CalendarModule,
    RippleModule,
    ToastModule,
    MessageModule,
    ConfirmPopupModule,
    AvatarModule,
    DialogModule,
    PasswordModule,
    AutoCompleteModule,
    SelectButtonModule,
    ProgressBarModule,
    FileUploadModule,
    InputSwitchModule,
    FullCalendarModule,
    AccordionModule,
    TooltipModule,
    BadgeModule,
    PaginatorModule,
    TimelineModule,
    FileUploadModule,
    OverlayPanelModule,
    ListboxModule,
    CodePipe,
    AvatarLabelPipe,
    HouseholdPipe,
    ConfirmDialogModule,
    AgePipe
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'de' },
    MessageService,
    ConfirmationService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    registerLocaleData(localeDe);
  }
}
