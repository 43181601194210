import {Pipe, PipeTransform} from '@angular/core';
import {AgeType, FamilyMember} from "../../customer-overview/add-edit-customer/member-form/family-member.model";
import {differenceInYears, isAfter, subYears} from "date-fns";

@Pipe({
  name: 'agesList',
  standalone: true
})
export class AgesListPipe implements PipeTransform {

  transform(family_members: FamilyMember[]): string {
    return family_members
      .filter(mem =>
        mem.ageType === AgeType.DATE &&
        mem.birthDate != null &&
        isAfter(new Date(mem.birthDate), subYears(new Date(), 18)))
      .map(mem => new Date(mem.birthDate!))
      .sort((a, b) => a.getTime() - b.getTime())
      .map(d => `${differenceInYears(new Date(), d)} J.`)
      .join(', ');
  }

}
