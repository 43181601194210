import {Component, DestroyRef, effect, inject, OnInit} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from "@angular/forms";
import {AuthService} from "../login/auth.service";
import {User} from "../administration/users/user.model";
import {UsersService} from "../administration/users/users.service";
import {MessageService} from "primeng/api";
import {HttpErrorResponse} from '@angular/common/http';
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  MIN_PASSWORD_LENGTH = 12;
  newPasswordRequirements: { [key: string]: { regex: RegExp | null, value: boolean, text: string } } = {
    digit: {text: 'Mindestens eine Ziffer', regex: /\d/, value: false,},
    lowercase: {text: 'Mindestens einen Kleinbuchstaben', regex: /[a-z]+/, value: false,},
    uppercase: {text: 'Mindestens einen Großbuchstaben', regex: /[A-Z]+/, value: false,},
    special: {text: 'Mindestens ein Sonderzeichen', regex: /[\^°<>#*~!"§$%?®©¶]+/, value: false,},
    length: {text: `Mindestens 12 Zeichen lang`, regex: null, value: false}
  }

  checkPasswords: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
    const pass = group.get('new_password')!.value;
    const confirmPass = group.get('confirm_password')!.value
    if (pass !== confirmPass) return {notSame: true};

    const allRequirementsMet = Object.keys(this.newPasswordRequirements).every((key: string) => {
      return this.newPasswordRequirements[key].value;
    });

    if (!allRequirementsMet) return {requirementsNotMet: true};

    return null;
  }

  profileForm = new FormGroup({
    first_name: new FormControl(''),
    last_name: new FormControl(''),
    username: new FormControl('', Validators.required),
    email: new FormControl('', Validators.required),
  });

  changePasswordForm = new FormGroup({
    current_password: new FormControl('', Validators.required),
    new_password: new FormControl('', Validators.required),
    confirm_password: new FormControl('', Validators.required),
  }, this.checkPasswords);
  destroyRef = inject(DestroyRef)

  constructor(
    public auth: AuthService,
    public userService: UsersService,
    private messageService: MessageService,
  ) {
    effect(() => {
      if (this.auth.user()) {
        this.fillForms(this.auth.user()!);
      }
    });
  }

  ngOnInit() {
    this.changePasswordForm.get('new_password')?.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((password) => {
      if (password != null) {
        Object.keys(this.newPasswordRequirements).forEach((key: string) => {
          if (this.newPasswordRequirements[key].regex) {
            this.newPasswordRequirements[key].value = this.newPasswordRequirements[key].regex?.test(password) ?? false;
          }
        });

        this.newPasswordRequirements['length'].value = password.length >= this.MIN_PASSWORD_LENGTH;
      }
    })
  }


  private fillForms(user: User) {
    this.profileForm.setValue({
      first_name: user.first_name ?? null,
      last_name: user.last_name ?? null,
      username: user.username,
      email: user.email,
    })
  }

  async saveGeneral(): Promise<void> {
    const {first_name, last_name, username, email} = this.profileForm.getRawValue();
    if (username && email) {
      try {
        await this.userService.updateUser({
          id: this.auth.user()!.id!,
          first_name: first_name ?? undefined,
          last_name: last_name ?? undefined,
          role: this.auth.user()!.role.id,
          username,
          email
        });
        await this.auth.fetchMe();
        this.messageService.add({
          severity: 'success',
          summary: 'Profil gespeichert',
          detail: `Profil wurde erfolgreich aktualisiert.`
        });
      } catch (e) {
        this.messageService.add({
          severity: 'error',
          summary: 'Fehler',
          detail: `Profil konnte nicht aktualisiert werden.`
        });
      }

    }
  }


  async changePW(): Promise<void> {
    const {current_password, new_password, confirm_password} = this.changePasswordForm.getRawValue();
    if (current_password && new_password && confirm_password) {
      try {
        await this.auth.changePassword(current_password, new_password, confirm_password);
        await this.auth.fetchMe();
        this.messageService.add({
          severity: 'success',
          summary: 'Passwort geändert',
          detail: `Passwort wurde erfolgreich aktualisiert.`
        });
      } catch (e) {
        let errorStr = 'Password konnte nicht aktualisiert werden.';
        if (e instanceof HttpErrorResponse && e.status === 400) {
          errorStr = e?.error?.error?.message ?? errorStr;
        }
        this.messageService.add({
          severity: 'error',
          summary: 'Fehler',
          detail: errorStr,
        });
      }

    }
  }

  async uploadAvatar(event: Event) {
    const MAX_FILE_SIZE = 1024 * 1024; // 1MB

    if ((event.target as HTMLInputElement)?.files) {
      try {
        const blob = (event.target as HTMLInputElement)?.files?.[0];
        if (blob) {
          if (blob.size > MAX_FILE_SIZE) {
            this.messageService.add({
              severity: 'error',
              summary: 'Fehler',
              detail: `Datei ist zu groß.`
            });
            return;
          }

          if (blob.type !== 'image/png' && blob.type !== 'image/jpeg') {
            this.messageService.add({
              severity: 'error',
              summary: 'Fehler',
              detail: `Datei ist kein Bild.`
            });
            return;
          }

          const blobBase64 = await new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => resolve(reader.result as string);
            reader.onerror = error => reject(error);
          });
          await this.userService.updateUser({
            id: this.auth.user()!.id!,
            first_name: this.auth.user()!.first_name,
            last_name: this.auth.user()!.last_name,
            email: this.auth.user()!.email,
            username: this.auth.user()!.username,
            role: this.auth.user()!.role.id,
            avatar_blob: {blob: blobBase64},
          });
          await this.auth.fetchMe();
          this.messageService.add({
            severity: 'success',
            summary: 'Avatar geändert',
            detail: `Avatar wurde erfolgreich hochgeladen`
          });
        }
      } catch (e) {
        this.messageService.add({
          severity: 'error',
          summary: 'Fehler',
          detail: `Avatar konnte nicht hochgeladen werden.`
        });
      }
    }
  }

  async deleteAvatar() {
    if (this.auth.user() === null) {
      await this.auth.fetchMe();
    }
    try {
      await this.userService.updateUser({
        id: this.auth.user()!.id!,
        email: this.auth.user()!.email,
        username: this.auth.user()!.username,
        role: this.auth.user()!.role.id,
        avatar_blob: {blob: null}
      });
      await this.auth.fetchMe();
      this.messageService.add({
        severity: 'success',
        summary: 'Avatar gelöscht',
        detail: `Avatar wurde erfolgreich gelöscht`
      });
    } catch (e) {
      this.messageService.add({
        severity: 'error',
        summary: 'Fehler',
        detail: `Avatar konnte nicht gelöscht werden.`
      });
    }
  }
}
