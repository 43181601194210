import {Gender} from "../gender/gender.model";

export interface FamilyMember {
  id?: string;
  isApplicant: boolean;
  birthDate?: Date | string;
  gender: Gender;
  ageType: AgeType;
}

export enum AgeType {
  CHILD = 'child',
  ADULT = 'adult',
  DATE = 'date'
}
