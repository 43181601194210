<div class="p-card w-100 mb-1 p-1 pl-3 text-4xl">
  Hallo {{ authService.user()?.first_name }} {{ authService.user()?.last_name }}
</div>

<div class="grid mt-2">
  <div class="col">
    <p-card class="h-100 w-100">
      <div class="flex flex-column align-items-center">
        <span class="text-4xl">{{ stat?.customersActive }} Nutzer</span><br>
        Gesamt
      </div>
    </p-card>
  </div>
  <div class="col">
    <p-card class="h-100 w-100">
      <div class="flex flex-column align-items-center">
        <span class="text-4xl">{{ stat?.customersToday }} Nutzer</span><br>
        Heute
      </div>
    </p-card>
  </div>
  <div class="col">
    <p-card class="h-100 w-100">
      <div class="flex flex-column align-items-center">
        <span class="text-4xl">{{ stat?.boxAmount }} Kisten</span><br>
        Heute
      </div>
    </p-card>
  </div>
  @if (authService.user()?.role?.id === RoleEnum.Admin) {
    <div class="col">
      <p-card class="h-100 w-100">
        <div class="flex flex-column align-items-center">
          <span class="text-4xl">{{ stat?.income }} €</span><br>
          Heutige Einnahmen
        </div>
      </p-card>
    </div>
  }
</div>

<div class="grid mt-1">
  <div class="col">
    <p-card class="h-100 w-100 flex" header="Meine Aktivitäten">
      @if (myCustomerEvents.length === 0) {
        <div class="w-100 text-black-alpha-60 text-sm flex justify-content-center">
          Keine Einträge gefunden
        </div>
      }
      @if (myCustomerEvents.length > 0) {
        <p-listbox scrollHeight="auto" [options]="myCustomerEvents" [optionLabel]="''" [style]="{'width': '100%'}">
          <ng-template let-item pTemplate="item">
            <div class="flex align-items-center p-1 w-100" (dblclick)="openCustomer(item.customer)">
              <div class="list-item-details flex-grow-1">
                <div class="flex justify-content-between">
                  <h5 class="mb-3 mr-1">
                    {{ item.customer.first_name | slice : 0 : 1 }}. {{ item.customer.last_name }}
                    @if (item.customer.status === 'active') {
                      <span>({{ item.customer | code }})</span>
                    }
                  </h5>
                  <span class="text-black-alpha-70">{{ item.createdAt | date : 'dd.MM.yyyy HH:mm' }} Uhr</span>
                </div>
                <div class="flex justify-content-between align-items-center">
                  <div class="text-sm mr-1">
                    <span [innerHTML]="customerEventService.getHumanFriendlyEventNameHTML(item)"></span>
                    (<span [innerHTML]="customerEventService.getHumanFriendlyEventDescriptionHTML(item)"></span>)
                  </div>
                  <span
                    class="status-badge status-{{item.customer.status}}">{{ this.customerService.getHumanFriendlyStatus(item.customer.status) }}</span>
                </div>
              </div>
            </div>
          </ng-template>
        </p-listbox>
      }
      @if (myCustomerEvents.length > 0) {
        <p-paginator (onPageChange)="myCustomerEventsPageChange($event)" [first]="0" [rows]="5"
                     [totalRecords]="myTotalCustomerEvents"></p-paginator>
      }
    </p-card>
  </div>


  <div class="col">
    <p-card class="h-100 w-100 flex" header="Letzte Aktivitäten">
      @if (customerEvents.length === 0) {
        <div class="w-100 text-black-alpha-60 text-sm flex justify-content-center">
          Keine Einträge gefunden
        </div>
      }
      @if (customerEvents.length > 0) {
        <p-listbox scrollHeight="auto" [options]="customerEvents" [optionLabel]="''" [style]="{'width': '100%'}">
          <ng-template let-item pTemplate="item">
            <div class="flex align-items-center p-1 list-item w-100" (dblclick)="openCustomer(item.customer)">
              <app-user-avatar class="mr-3" [user]="item.users_permissions_user"></app-user-avatar>
              <div class="list-item-details flex-grow-1">
                <div class="flex justify-content-between">
                  <h5 class="mb-3 mr-1">
                    {{ item.customer.first_name | slice : 0 : 1 }}. {{ item.customer.last_name }}
                    @if (item.customer.status === 'active') {
                      <span>({{ item.customer | code }})</span>
                    }
                  </h5>
                  <span class="text-black-alpha-70">{{ item.createdAt | date : 'dd.MM.yyyy HH:mm' }} Uhr</span>
                </div>
                <div class="flex justify-content-between align-items-center">
                  <div class="text-sm mr-1">
                    <span [innerHTML]="customerEventService.getHumanFriendlyEventNameHTML(item)"></span>
                    (<span [innerHTML]="customerEventService.getHumanFriendlyEventDescriptionHTML(item)"></span>)
                  </div>
                  <span
                    class="status-badge status-{{item.customer.status}}">{{ this.customerService.getHumanFriendlyStatus(item.customer.status) }}</span>
                </div>
              </div>
            </div>
          </ng-template>
        </p-listbox>
      }
      @if (customerEvents.length > 0) {
        <p-paginator (onPageChange)="latestCustomerEventsPageChange($event)" [first]="0" [rows]="5"
                     [totalRecords]="totalCustomerEvents"></p-paginator>
      }
    </p-card>
  </div>
</div>





