import {Component, EventEmitter, HostListener, OnInit, Output} from '@angular/core';
import {Router} from "@angular/router";
import {AuthService} from "../../login/auth.service";
import {RoleEnum} from "../../login/role.enum";
import {SidebarService} from "./sidebar.service";

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  isMobile = window.innerWidth <= 991;
  @Output() menuItemClicked: EventEmitter<void> = new EventEmitter<void>();
  roleId = RoleEnum.Public;

  constructor(
    public router: Router,
    public authService: AuthService,
    public sidebarService: SidebarService,
  ) {}

  ngOnInit() {
    this.authService.fetchMe().then(() => {
      this.roleId = this.authService.user()!.role.id;
    });
    this.sidebarService.updateSidebarStats();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.isMobile = ((event.target as Window | null)?.innerWidth ?? 0) <= 991;
  }

  navigate(path: string): void {
    void this.router.navigate([path]);
    if (this.isMobile) {
      this.menuItemClicked.emit();
    }
  }
}
