import {Injectable} from '@angular/core';
import {LocationModel} from "./location.model";
import {LoadingState} from "../../shared/loading-state.enum";
import {HttpClient} from "@angular/common/http";
import {firstValueFrom} from "rxjs";
import {environment} from "../../../environments/environment";
import {ResponseModel, SingleResponseModel} from "../../shared/response.model";
import {MessageService} from "primeng/api";

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  loadingState = LoadingState.Loading;
  locations: LocationModel[] = [];
  constructor(
    private http: HttpClient,
    private messageService: MessageService
  ) {
    void this.fetchLocations();
  }

  async fetchLocations(): Promise<void> {
    try {
      const response = await firstValueFrom(this.http.get<ResponseModel<LocationModel>>(environment.apiUrl + "/locations"));
      this.locations = response.data;
      this.loadingState = LoadingState.Data;
    } catch (e) {
      console.error(e);
      this.loadingState = LoadingState.Error;
      this.locations = [];
    }
  }

  async getLocation(id: number): Promise<SingleResponseModel<LocationModel>> {
    return firstValueFrom(this.http.get<SingleResponseModel<LocationModel>>(environment.apiUrl + `/locations/${id}`));
  }

  async delete(id: number): Promise<LocationModel | void> {
    try {
      const response = await firstValueFrom(this.http.delete<SingleResponseModel<LocationModel>>(environment.apiUrl + `/locations/${id}`));
      this.messageService.add({
        severity: 'success',
        summary: 'Standort gelöscht',
        detail: `${response?.data.city} wurde gelöscht.`
      });
      return response?.data;
    } catch (e) {
      console.error(e);
      this.messageService.add({
        severity: 'error',
        summary: 'Fehler',
        detail: `Standort konnte nicht gelöscht werden.`
      });
    } finally {
      await this.fetchLocations();
    }
  }

  async update(location: LocationModel): Promise<LocationModel | void> {
    try {
      const response = await firstValueFrom(this.http.put<SingleResponseModel<LocationModel>>(environment.apiUrl + `/locations/${location.id}`, {data: location}));
      this.messageService.add({
        severity: 'success',
        summary: 'Standort aktualisiert',
        detail: `${response?.data.city} wurde aktualisiert.`
      });
      return response?.data;
    } catch (e) {
      console.error(e);
      this.messageService.add({
        severity: 'error',
        summary: 'Fehler',
        detail: `Standort konnte nicht geändert werden.`
      });
    } finally {
      await this.fetchLocations();
    }

  }

  async create(city: string, abbreviation: string): Promise<LocationModel | void> {
    try {
      const response = await firstValueFrom(this.http.post<SingleResponseModel<LocationModel>>(environment.apiUrl + `/locations`, {data: {city, abbreviation}}));
      this.messageService.add({
        severity: 'success',
        summary: 'Standort erstellt',
        detail: `${response?.data.city} wurde erstellt.`
      });
      return response?.data;
    } catch (e) {
      console.error(e);
      this.messageService.add({
        severity: 'error',
        summary: 'Fehler',
        detail: `Standort konnte nicht erstellt werden.`
      });
    } finally {
      await this.fetchLocations();
    }
  }
}
