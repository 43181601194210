import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from "./home/home.component";
import {DashboardComponent} from "./dashboard/dashboard.component";
import {LoginComponent} from "./login/login.component";
import {AuthGuard} from "./login/auth.guard";
import {CustomerOverviewComponent} from "./customer-overview/customer-overview.component";
import {AddEditCustomerComponent} from "./customer-overview/add-edit-customer/add-edit-customer.component";
import {StatsComponent} from "./stats/stats.component";
import {AbsencesOverviewComponent} from "./absences-overview/absences-overview.component";
import {AdministrationComponent} from "./administration/administration.component";
import {CertificateComponent} from "./certificate/certificate.component";
import {ProfileComponent} from "./profile/profile.component";
import {CodeComponent} from "./code/code.component";
import {ScheduleComponent} from "./schedule/schedule.component";
import {ConsultationComponent} from "./consultation/consultation.component";
import {RoleGuard} from "./login/role.guard";
import {RoleEnum} from "./login/role.enum";
import {ExpirationComponent} from "./expiration/expiration.component";
import {PickUpComponent} from "./pick-up/pick-up.component";
import {PickupLocationsComponent} from "./pickup-locations/pickup-locations.component";
import {AlertMessagesComponent} from "./alert-messages/alert-messages.component";
import {AutomationComponent} from "./automation/automation.component";
import {PickupAdminComponent} from "./pickup-admin/pickup-admin.component";

const routes: Routes = [
  { path: "login", component: LoginComponent },
  {
    path: "", component: DashboardComponent, canActivate: [AuthGuard], children: [
      {path: "", redirectTo: "home", pathMatch: "full"},
      {path: 'home', component: HomeComponent},
      {path: 'customers', component: CustomerOverviewComponent, canActivate: [RoleGuard], data: { roles: [RoleEnum.Admin, RoleEnum.Member]}},
      {path: 'add-customer', component: AddEditCustomerComponent, canActivate: [RoleGuard], data: { roles: [RoleEnum.Admin, RoleEnum.Member]}},
      {path: 'customers/edit/:id', component: AddEditCustomerComponent, canActivate: [RoleGuard], data: { roles: [RoleEnum.Admin, RoleEnum.Member]}},
      {path: 'messages', component: AlertMessagesComponent},
      {path: 'stats', component: StatsComponent, canActivate: [RoleGuard], data: { roles: [RoleEnum.Admin]}},
      {path: 'absences', component: AbsencesOverviewComponent, canActivate: [RoleGuard], data: { roles: [RoleEnum.Admin, RoleEnum.Member]}},
      {path: 'certificates', component: CertificateComponent, canActivate: [RoleGuard], data: { roles: [RoleEnum.Admin, RoleEnum.Member]}},
      {path: 'expirations', component: ExpirationComponent, canActivate: [RoleGuard], data: { roles: [RoleEnum.Admin, RoleEnum.Member]}},
      {path: 'administration', component: AdministrationComponent, canActivate: [RoleGuard], data: { roles: [RoleEnum.Admin]}},
      {path: 'profile', component: ProfileComponent},
      {path: 'codes', component: CodeComponent, canActivate: [RoleGuard], data: { roles: [RoleEnum.Admin]}},
      {path: 'automation', component: AutomationComponent, canActivate: [RoleGuard], data: { roles: [RoleEnum.Admin]}},
      {path: 'schedule', component: ScheduleComponent},
      {path: 'consultation', component: ConsultationComponent, canActivate: [RoleGuard], data: { roles: [RoleEnum.Admin, RoleEnum.Member]}},
      {path: 'pickup-admin', component: PickupAdminComponent, canActivate: [RoleGuard], data: { roles: [RoleEnum.Admin, RoleEnum.Member]}},
    ]
  },
  { path: "pickup", component: PickupLocationsComponent, canActivate: [AuthGuard] },
  { path: "pickup/:locationId", component: PickUpComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
